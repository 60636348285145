@font-face {
    font-family: 'Montserrat';
    src: url('../font/font-montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../font/font-montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../font/font-montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../font/font-montserrat/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../font/font-montserrat/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
:root {
    --mainColor: #EF4545;
    --secondaryColor: #e34444;
    --whiteColor: #fff;
}
.cms-index-index2 h3 {
    text-transform: uppercase
}
a:focus {
  outline: none!important;
  outline-offset: 0px!important;
}
.cms-index-index2 #sns_left .menu-categories {
    padding: 14px 20px 23px
}
.cms-index-index2 #sns_left #sns_testimonial {
    padding: 13px 20px 6px
}
.cms-index-index2 #sns_left #sns_testimonial .block-title {
    padding-bottom: 10px;
    margin-bottom: 20px
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .owl-controls {
    margin: 0;
    top: -7px;
    background: #fff;
    right: 0;
    display: inline-block;
    z-index: 1
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .owl-controls .owl-nav .owl-prev {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea;
    margin-right: 10px
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .owl-controls .owl-nav .owl-prev:before {
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .owl-controls .owl-nav .owl-next {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .owl-controls .owl-nav .owl-next:before {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .owl-controls {
    margin-top: 15px;
    padding: 0;
    position: relative!important
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .owl-controls .owl-prev {
    margin: 0!important;
    border-right: 0!important;
    display: inline-block!important
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .owl-controls .owl-next {
    display: inline-block!important
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .content .info p {
    padding-bottom: 18px;
    border-bottom: 1px solid #eaeaea;
    color: #666
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .content .tes-info {
    margin: 17px 0 10px
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .content .tes-info .user {
    color: var(--secondaryColor)
}
.cms-index-index2 #sns_left #sns_testimonial .testimonials_slider_in .content .tes-info .position {
    color: #666
}
.cms-index-index2 #sns_left .sns-latestblog {
    padding: 13px 20px 6px
}
.cms-index-index2 #sns_left .sns-latestblog .block-title {
    padding-bottom: 8px;
    margin-bottom: 20px
}
.cms-index-index2 #sns_left .sns-latestblog .block-title:before {
    display: none
}
.cms-index-index2 #sns_left .sns-latestblog .content .item {
    padding: 0
}
.cms-index-index2 #sns_left .sns-latestblog .content .item .blog-page {
    padding-left: 62px
}
.cms-index-index2 #sns_left .sns-latestblog .content .item .blog-page .blog-left .text1,
.cms-index-index2 #sns_left .sns-latestblog .content .item .blog-page .blog-left .text2 {
    color: #666
}
.cms-index-index2 #sns_left .sns-latestblog .content .owl-controls {
    margin-top: 15px;
    padding: 0;
    position: relative!important
}
.cms-index-index2 #sns_left .sns-latestblog .content .owl-controls .owl-prev {
    margin: 0!important;
    border-right: 0!important
}
.cms-index-index2 #sns_left .bestsale .title h3 {
    padding-top: 5px
}
.cms-index-index2 #sns_left .block {
    margin-bottom: 55px
}
.cms-index-index2 #sns_left .block .block-title {
    background: 0 0;
    border-bottom: 1px solid #eaeaea
}
.cms-index-index2 #sns_left .sns-slider.sns-deals .deals_content:hover .owl-controls {
    opacity: 1
}
.cms-index-index2 #sns_left .sns-slider.sns-deals .deals_content .product-image {
    border: 0
}
.cms-index-index2 #sns_left .sns-slider.sns-deals .deals_content .owl-controls {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    z-index: 99;
    opacity: 0
}
.cms-index-index2 #sns_left .sns-slider.sns-deals .deals_content .owl-controls .owl-prev {
    overflow: hidden;
    margin: 0;
    left: 0;
    position: absolute;
    top: 32%
}
.cms-index-index2 #sns_left .sns-slider.sns-deals .deals_content .owl-controls .owl-prev:before {
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 28px
}
.cms-index-index2 #sns_left .sns-slider.sns-deals .deals_content .owl-controls .owl-next {
    overflow: hidden;
    margin: 0;
    right: 0;
    position: absolute;
    top: 32%
}
.cms-index-index2 #sns_left .sns-slider.sns-deals .deals_content .owl-controls .owl-next:before {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 28px
}
.block-custom {
    background: #ededed;
    padding: 20px
}
.block-custom h3 {
    font-size: 200%;
    font-weight: 700;
    margin: 10px 0
}
#sns_left .block.block-banner,
#sns_right .block.block-banner,
.product_slider .block.block-banner {
    border: 0;
    padding: 0;
    margin-top: 30px
}
#sns_left .bestsale,
#sns_right .bestsale,
.product_slider .bestsale {
    position: relative;
    padding: 0 20px;
    border: 1px solid #eaeaea
}
#sns_left .bestsale .title,
#sns_right .bestsale .title,
.product_slider .bestsale .title {
    border-bottom: 1px solid #eaeaea
}
#sns_left .bestsale .title h3,
#sns_right .bestsale .title h3,
.product_slider .bestsale .title h3 {
    margin: 0;
    font-size: 20px;
    color: #333;
    font-weight: 700;
    padding: 16.5px 0
}
#sns_left .bestsale .content,
#sns_right .bestsale .content,
.product_slider .bestsale .content {
    margin-top: 20px
}
#sns_left .bestsale .content .products-slider12,
#sns_right .bestsale .content .products-slider12,
.product_slider .bestsale .content .products-slider12 {
    position: static
}
#sns_left .bestsale .content .products-slider12 .owl-controls,
#sns_right .bestsale .content .products-slider12 .owl-controls,
.product_slider .bestsale .content .products-slider12 .owl-controls {
    margin: 0;
    position: absolute;
    top: 13px;
    background: #fff;
    right: 20px;
    display: inline-block;
    z-index: 1;
    padding-left: 0
}
#sns_left .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-prev,
#sns_right .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-prev,
.product_slider .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-prev {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea;
    margin-right: 10px
}
#sns_left .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-prev:before,
#sns_right .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-prev:before,
.product_slider .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-prev:before {
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 28px
}
#sns_left .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-next,
#sns_right .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-next,
.product_slider .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-next {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea
}
#sns_left .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-next:before,
#sns_right .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-next:before,
.product_slider .bestsale .content .products-slider12 .owl-controls .owl-nav .owl-next:before {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 28px
}
#sns_left .bestsale .content .products-slider12 .item,
#sns_right .bestsale .content .products-slider12 .item,
.product_slider .bestsale .content .products-slider12 .item {
    clear: both;
    margin-bottom: 11px;
    display: inline-block
}
#sns_left .bestsale .content .products-slider12 .item:last-child,
#sns_right .bestsale .content .products-slider12 .item:last-child,
.product_slider .bestsale .content .products-slider12 .item:last-child {
    margin-bottom: 4px
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd {
    width: 100%;
    display: inline-block
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-img,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-img,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-img {
    float: left;
    margin-right: 20px
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image {
    display: inline-block
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image .img-main,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image .img-main,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image .img-main {
    border: 1px solid #eaeaea;
    display: inline-block;
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image .img-main:hover,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image .img-main:hover,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image .img-main:hover {
    border: 1px solid var(--secondaryColor)
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image .img-main img,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image .img-main img,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-img .product-image .img-main img {
    width: 90px!important;
    height: 108px!important
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info {
    display: inline-block
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-title,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-title,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-title {
    margin-top: 0
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-title a,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-title a,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-title a {
    font-size: 13px;
    color: #666;
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-title a:hover,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-title a:hover,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-title a:hover {
    color: var(--secondaryColor)
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-price,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-price,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-price {
    margin-top: 3px
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-price .price,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-price .price,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .info-inner .item-price .price {
    font-size: 16px;
    color: #333;
    font-weight: 700
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot {
    margin-top: 10px
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    padding: 0;
    font-size: 12px;
    color: #666
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart i,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart i,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart i {
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s;
    display: inline-block;
    color: #666;
    font-size: 12px;
    position: relative;
    top: -1px;
    margin-right: 2px
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart span,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart span,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart span {
    text-transform: uppercase;
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s;
    display: inline-block
}
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart:hover i,
#sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart:hover span,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart:hover i,
#sns_right .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart:hover span,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart:hover i,
.product_slider .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart:hover span {
    color: var(--secondaryColor)
}
#sns_left .block,
#sns_right .block,
.product_slider .block {
    margin-bottom: 30px;
    border: 1px solid #eaeaea;
    padding: 14px 15px 13px 19px;
    margin-top: 0
}
#sns_left .block .block-title,
#sns_right .block .block-title,
.product_slider .block .block-title {
    color: #333;
    text-transform: uppercase;
    position: relative;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0;
    background-position: left bottom;
    padding-bottom: 14px
}
#sns_left .block .block-title .navslider,
#sns_right .block .block-title .navslider,
.product_slider .block .block-title .navslider {
    top: -1px;
    margin-top: 0
}
#sns_left .block .block-title .navslider a i,
#sns_right .block .block-title .navslider a i,
.product_slider .block .block-title .navslider a i {
    position: relative;
    top: 0!important
}
#sns_left .block .block-title span,
#sns_right .block .block-title span,
.product_slider .block .block-title span {
    display: inline-block;
    background: 0 0;
    padding: 0
}
#sns_left .block .block-title strong,
#sns_right .block .block-title strong,
.product_slider .block .block-title strong {
    background: 0 0;
    padding: 0
}
#sns_left .block .block-title h1,
#sns_left .block .block-title h2,
#sns_left .block .block-title h3,
#sns_left .block .block-title h4,
#sns_right .block .block-title h1,
#sns_right .block .block-title h2,
#sns_right .block .block-title h3,
#sns_right .block .block-title h4,
.product_slider .block .block-title h1,
.product_slider .block .block-title h2,
.product_slider .block .block-title h3,
.product_slider .block .block-title h4 {
    font-size: 100%;
    margin: 0
}
#sns_left .block .block-content,
#sns_right .block .block-content,
.product_slider .block .block-content {
    padding: 0
}
#sns_left .block.block-layered-nav,
#sns_right .block.block-layered-nav,
.product_slider .block.block-layered-nav {
    padding-bottom: 0
}
#sns_left .block.block-layered-nav .block-content,
#sns_right .block.block-layered-nav .block-content,
.product_slider .block.block-layered-nav .block-content {
    padding: 0
}
.block {
    margin-bottom: 30px
}
#sns_sticky_left .block,
#sns_sticky_right .block {
    margin: 0
}
.block .btn-remove {
    position: absolute;
    top: 0;
    right: 0
}
.block .block-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    color: #333;
    margin-bottom: 25px;
    background: url(../images/border.png);
    background-position: center;
    background-repeat: repeat-x
}
.block .block-title span,
.block .block-title strong {
    padding-right: 20px;
    background: #fff
}
.block .block-title h1,
.block .block-title h2,
.block .block-title h3,
.block .block-title h4 {
    font-size: 100%;
    margin: 0;
    font-weight: 400
}
.block .block-title .navslider {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -16px;
    height: 30px;
    background: #fff;
    z-index: 3;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block .block-title .navslider:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 29px;
    background: #eaeaea;
    z-index: 2;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block .block-title .navslider:after {
    position: absolute;
    width: 20px;
    height: 100%;
    background: #fff;
    content: "";
    left: -21px;
    z-index: 1
}
.block .block-title .navslider .prev {
    margin-right: 10px
}
.block .block-title .navslider a {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    float: left;
    text-align: center;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border: 1px solid #eaeaea
}
.block .block-title .navslider a i {
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    color: #cacaca;
    background: 0 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block .block-title .navslider a i:hover {
    color: #fff
}
.block .block-title .navslider a:hover {
    background: var(--secondaryColor);
    color: #fff;
    border-color: var(--secondaryColor)
}
.block .block-title .navslider a:hover i {
    color: #fff
}
.block .block-content {
    padding: 10px 0 0
}
.block.block_cat {
    padding: 0!important;
    border: 0!important
}
.block.sns-slider .block-title-slider {
    position: relative
}
.block.sns-slider .block-title-slider h3 {
    margin: 0
}
.block.sns-slider .block-title-slider p {
    padding: 3px 0 0
}
.block.sns-slider .block-title-slider .navslider {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -20px
}
.block.sns-slider .block-title-slider .navslider a {
    display: inline-block;
    vertical-align: middle;
    color: #666
}
.block.sns-slider .block-title-slider .navslider a+a {
    margin-left: 5px
}
.block.sns-slider .block-title-slider .navslider a i {
    width: 40px;
    height: 40px;
    border: 3px solid #ddd;
    text-align: center;
    line-height: 34px;
    font-size: 20px;
    color: #ccc;
    background: #fff;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block.sns-slider .block-title-slider .navslider a i:hover {
    background: var(--secondaryColor);
    color: #fff;
    border-color: var(--secondaryColor)
}
.block.sns-slider .pretext {
    margin: 0
}
.block.sns-slider .container-slider {
    margin: 0 -15px
}
.block.sns-slider .container-slider .owl-item {
    position: relative;
    z-index: 0
}
.block.sns-slider .container-slider .owl-item.active:first-child .item .item-inner .gallery,
.block.sns-slider .container-slider .owl-item:not(.active)+.active .item .item-inner .gallery {
    right: auto;
    left: 100%
}
.block.sns-slider .container-slider .owl-item:hover {
    z-index: 1
}
.block.sns-slider.hot-deals .block-title {
    min-height: 55px;
    margin: 0 15px 10px 0
}
.block.sns-slider.hot-deals .block-title strong {
    display: block;
    background: var(--secondaryColor);
    top: 0;
    left: -7px;
    padding: 0 20px;
    position: absolute;
    color: #fff;
    height: 54px;
    line-height: 54px;
    white-space: nowrap
}
.block.sns-slider.hot-deals .block-title strong:before {
    width: 0;
    height: 0;
    border-style: solid;
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    border-color: transparent #a81919 transparent transparent;
    border-width: 0 7px 10px 0;
    left: 0
}
.block.sns-slider.hot-deals .block-title strong:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 99.99%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 27px 0 27px 27px;
    border-color: transparent transparent transparent var(--secondaryColor)
}
.block.sns-slider.hot-deals .container-slider {
    padding: 0 0 15px
}
.block.sns-slider.hot-deals .container-slider .item:hover {
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block.sns-slider.hot-deals .container-slider .item:hover .item-img .product-image {
    -webkit-transform: translate(0, -30px);
    -ms-transform: translate(0, -30px);
    transform: translate(0, -30px)
}
.block.sns-slider.hot-deals .container-slider .item:hover .item-img .gallery {
    opacity: .9;
    filter: alpha(opacity=90);
    bottom: 0
}
.block.sns-slider.hot-deals .container-slider .item:hover .item-img .actions .link-compare,
.block.sns-slider.hot-deals .container-slider .item:hover .item-img .actions .link-wishlist,
.block.sns-slider.hot-deals .container-slider .item:hover .item-img .actions .sns-btn-quickview {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    top: -30px;
    -webkit-transition-timing-function: cubic-bezier(0.175, .885, .32, 1.275);
    transition-timing-function: cubic-bezier(0.175, .885, .32, 1.275)
}
.block.sns-slider.hot-deals .container-slider .item .item-img {
    position: relative;
    margin-bottom: 10px;
    overflow: hidden
}
.block.sns-slider.hot-deals .container-slider .item .item-img .product-image {
    display: block;
    text-align: center
}
.block.sns-slider.hot-deals .container-slider .item .item-img .gallery {
    padding: 3px 25px;
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
    background: #fff;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block.sns-slider.hot-deals .container-slider .item .item-img .gallery .img {
    margin: 0 2px;
    text-align: center
}
.block.sns-slider.hot-deals .container-slider .item .item-img .gallery .img img {
    cursor: pointer
}
.block.sns-slider.hot-deals .container-slider .item .item-img .gallery .owl-controls .owl-buttons {
    color: #ccc
}
.block.sns-slider.hot-deals .container-slider .item .item-img .gallery .owl-controls .owl-buttons>div {
    position: absolute;
    top: 50%;
    margin-top: -10px
}
.block.sns-slider.hot-deals .container-slider .item .item-img .gallery .owl-controls .owl-prev {
    left: -15px
}
.block.sns-slider.hot-deals .container-slider .item .item-img .gallery .owl-controls .owl-next {
    right: -15px
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions {
    position: absolute;
    top: 50%;
    left: 50%
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .add-to-links {
    margin: 0;
    padding: 0;
    list-style: none
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-compare,
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-wishlist,
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .sns-btn-quickview {
    width: 40px;
    height: 40px;
    display: block;
    background: #333;
    position: absolute;
    top: -120px;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    text-align: center;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transition-timing-function: cubic-bezier(0.6, -.28, .735, .045);
    transition-timing-function: cubic-bezier(0.6, -.28, .735, .045)
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-compare:before,
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-wishlist:before,
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .sns-btn-quickview:before {
    color: #fff;
    font-size: 20px
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-compare:hover,
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-wishlist:hover,
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .sns-btn-quickview:hover {
    background: var(--secondaryColor)
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-wishlist {
    left: -62px;
    -webkit-transition-delay: .25s;
    transition-delay: .25s
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-wishlist:before {
    font-family: ElegantIcons;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e030';
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .sns-btn-quickview {
    -webkit-transition-delay: .15s;
    transition-delay: .15s;
    right: -20px
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .sns-btn-quickview span {
    display: none
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .sns-btn-quickview:before {
    font-family: ElegantIcons;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\55';
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-compare {
    right: -62px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}
.block.sns-slider.hot-deals .container-slider .item .item-img .actions .link-compare:before {
    font-family: ElegantIcons;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\2f';
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px
}
.block.sns-slider.hot-deals .container-slider .item .item-title a {
    font-size: 130%;
    font-weight: 700;
    text-transform: uppercase
}
.block.sns-slider.hot-deals .container-slider .item .discount {
    margin-bottom: 20px
}
.block.sns-slider.hot-deals .container-slider .item .countdown {
    margin-bottom: 20px;
    display: table;
    width: 100%;
    text-align: center
}
.block.sns-slider.hot-deals .container-slider .item .countdown:after,
.block.sns-slider.hot-deals .container-slider .item .countdown:before {
    content: " ";
    display: table
}
.block.sns-slider.hot-deals .container-slider .item .countdown:after {
    clear: both
}
.block.sns-slider.hot-deals .container-slider .item .countdown .countdown-row {
    display: table-row
}
.block.sns-slider.hot-deals .container-slider .item .countdown .countdown-row:after,
.block.sns-slider.hot-deals .container-slider .item .countdown .countdown-row:before {
    content: " ";
    display: table
}
.block.sns-slider.hot-deals .container-slider .item .countdown .countdown-row:after {
    clear: both
}
.block.sns-slider.hot-deals .container-slider .item .countdown .countdown-row .countdown-section {
    display: table-cell;
    border: 1px solid #eee;
    padding: 5px 0;
    text-align: center
}
.block.sns-slider.hot-deals .container-slider .item .countdown .countdown-row .countdown-section+.countdown-section {
    border-left: 0
}
.block.sns-slider.hot-deals .container-slider .item .countdown .countdown-row .countdown-section .countdown-amount {
    color: #666;
    display: block;
    font-weight: 700;
    font-size: 120%
}
.block.sns-slider.hot-deals .container-slider .item .countdown .countdown-row .countdown-section .countdown-period {
    color: #aaa
}
.block.sns-slider.hot-deals .container-slider .item .btn-cart {
    padding: 8px 15px
}
.block.sns-slider.stylev2 .container-slider .owl-item {
    padding-left: 15px;
    padding-right: 15px
}
.block.sns-slider.stylev2 .container-slider .owl-item .row-item .item:last-child {
    margin-bottom: 0
}
.block.sns-slider.stylev2 .container-slider .owl-item .item {
    margin-top: 0;
    margin-bottom: 15px
}
.block.sns-slider.stylev2 .container-slider .owl-item .item-inner .prd {
    border: 0
}
.block.sns-slider.stylev2 .container-slider .owl-item .item-inner .prd .item-img {
    float: left;
    width: 120px;
    height: 120px;
    border: 1px solid #eaeaea;
    margin-right: 18px;
    margin-top: 5px;
    margin-bottom: 0
}
.block.sns-slider.stylev2 .container-slider .owl-item .item-inner .prd .item-info {
    position: relative;
    overflow: hidden;
    padding: 0
}
.block.sns-slider.stylev2 .container-slider .owl-item .item-inner .prd .item-info .action-button button {
    margin-top: 15px
}
.block.sns-slider.stylev2 .container-slider .owl-item .item-inner .prd .item-info .action-button button .fa {
    float: left;
    line-height: 33px;
    margin-right: 6px
}
.block.sns-slider.stylev3 {
    padding: 14px 20px 13px 19px!important
}
.block.sns-slider.stylev3 .block-title .navslider {
    display: block!important
}
.block.sns-slider.stylev3 .item {
    margin-top: 18px
}
.block.sns-slider.stylev3 .products-grid {
    padding-bottom: 3px
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd {
    border: 0
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-img {
    border: 1px solid #eaeaea;
    float: left;
    margin-right: 10px;
    margin-top: 3px;
    margin-bottom: 0
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info {
    text-align: left;
    padding: 0;
    position: relative;
    overflow: hidden
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info .item-title {
    margin-bottom: 5px
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info .old-price .price {
    font-size: 100%
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info .btn-cart {
    border: 0;
    background: 0 0;
    padding: 0;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    color: #666;
    padding-top: 10px
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info .btn-cart:hover {
    border: 0;
    background: 0 0;
    color: var(--secondaryColor)
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info .btn-cart:hover i,
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info .btn-cart:hover span {
    color: var(--secondaryColor)
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info .btn-cart i {
    font-size: 12px;
    color: #666;
    position: relative;
    top: -1px;
    padding-right: 3px
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info .item-price {
    text-align: left
}
.block.sns-slider.stylev3 .products-grid .item .item-inner .prd .item-info .item-price .price {
    font-size: 16px;
    color: #333;
    font-weight: 700
}
.block.sns-slider.stylev3 .container-slider {
    margin: 0
}
.block.sns-deals {
    padding: 0!important;
    margin-bottom: 0
}
.block.sns-deals .module-title {
    padding-left: 15px;
    padding-right: 15px
}
.block.sns-deals .owl-controls {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 3
}
.block.sns-deals .owl-controls .owl-nav>div {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 40px;
    display: block!important;
    left: -1px;
    text-align: center;
    border: 1px solid #eaeaea;
    background: #fff;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block.sns-deals .owl-controls .owl-nav>div.owl-next {
    left: initial;
    right: -1px
}
.block.sns-deals .owl-controls .owl-nav>div i {
    text-align: center;
    line-height: 28px;
    font-size: 16px;
    color: #cacaca;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block.sns-deals .owl-controls .owl-nav>div i:hover {
    color: #fff
}
.block.sns-deals .owl-controls .owl-nav>div:hover {
    background: var(--secondaryColor);
    border-color: var(--secondaryColor);
    color: #fff
}
.block.sns-deals .block-title {
    padding-top: 15px;
    margin-bottom: 0
}
.block.sns-deals .title-custom {
    padding-left: 15px;
    padding-right: 15px;
    position: relative
}
.block.sns-deals .item-title {
    margin-bottom: 0
}
.block.sns-deals .item-title a {
    font-size: 13px;
    color: #666
}
.block.sns-deals .item-title a:hove {
    color: var(--secondaryColor)
}
.block.sns-deals .action-bot {
    display: none!important
}
.block.sns-deals .item-info {
    position: relative
}
.block.sns-deals .item-info .info-inner {
    position: inherit
}
.block.sns-deals .products-grid .item {
    margin-top: 0
}
.block.sns-deals .products-grid .item .item-inner .prd {
    border: 0
}
.block.sns-deals .products-grid .item .item-inner .item-img {
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px
}
.block.sns-deals .products-grid .item .item-inner .item-info {
    padding: 0;
    margin-top: 8px
}
.block.sns-deals .products-grid .item .item-inner .custom-info {
    padding: 0
}
.block.sns-deals .products-grid .item .item-inner .custom-info .item-title {
    margin-bottom: 0;
    padding-bottom: 0;
    overflow: inherit
}
.block.sns-deals .products-grid .item .item-inner .custom-info .item-price .old-price {
    margin-bottom: 0
}
.block.sns-deals .products-grid .item .item-inner .custom-info .item-price .price {
    color: #333;
    font-size: 16px;
    font-weight: 700
}
.block.sns-deals .desc {
    margin-bottom: 15px
}
.block.sns-deals .item-price>* {
    display: inline-block;
    vertical-align: middle
}
.block.sns-deals .click_count {
    position: relative;
    height: auto;
    width: 100%;
    display: block;
    text-align: left;
    overflow: hidden;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea
}
.block.sns-deals .click_count .deals-time {
    padding: 12px 10px;
    background: var(--secondaryColor);
    display: inline-block;
    color: #fff;
    position: relative
}
.block.sns-deals .click_count .deals-time:before {
    background: var(--secondaryColor);
    content: "";
    display: block;
    height: 134%;
    position: absolute;
    right: -10px;
    top: 0;
    -webkit-transform: rotate(158deg)!important;
    -ms-transform: rotate(158deg)!important;
    transform: rotate(158deg)!important;
    width: 16px
}
.block.sns-deals .click_count a {
    display: inline-block;
    padding: 12px 20px;
    text-transform: uppercase;
    color: #666;
    font-weight: 700
}
.block.sns-deals .click_count a:hover {
    color: var(--secondaryColor)
}
.block.sns-deals .countdown {
    height: 40px;
    display: block;
    margin-left: -1px;
    margin-right: -1px;
    background: var(--secondaryColor);
    text-align: left;
    padding-left: 16px;
    padding-top: 10px
}
.block.sns-deals .countdown:after,
.block.sns-deals .countdown:before {
    content: " ";
    display: table
}
.block.sns-deals .countdown:after {
    clear: both
}
.block.sns-deals .countdown .countdown-row {
    display: block;
    width: 100%;
    height: 100%
}
.block.sns-deals .countdown .countdown-row:after,
.block.sns-deals .countdown .countdown-row:before {
    content: " ";
    display: table
}
.block.sns-deals .countdown .countdown-row:after {
    clear: both
}
.block.sns-deals .countdown .countdown-row .countdown-section {
    text-align: left;
    position: relative;
    display: inline-block;
    color: #fff;
    padding-right: 8px
}
.block.sns-deals .countdown .countdown-row .countdown-section:before {
    position: absolute;
    top: 8px;
    right: 4px;
    content: ":";
    width: 2px;
    height: 10px;
    font-weight: 700;
    line-height: 1px
}
.block.sns-deals .countdown .countdown-row .countdown-section:last-child:before {
    display: none
}
.block.sns-deals .countdown .countdown-row .countdown-section+.countdown-section {
    border-left: 0
}
.block.sns-deals .countdown .countdown-row .countdown-section .countdown-amount {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 0;
    line-height: 16px
}
.block.sns-deals .countdown .countdown-row .countdown-section .countdown-period {
    display: none;
    font-size: 12px;
    color: #f7f5f1;
    line-height: 1px;
    font-weight: 400;
    text-transform: uppercase
}
.block.sns-deals .countdown .countdown-row .countdown-section:first-child .countdown-period {
    display: inline-block!important;
    margin-left: 2px
}
.block.sns-deals .countdown .countdown-row .countdown-section:first-child:before {
    position: absolute;
    top: 10px;
    right: 4px;
    content: ",";
    width: 2px;
    height: 10px;
    font-weight: 700;
    line-height: 1px
}
.block.sns-deals .owl-wrapper-outer {
    background: #fff
}
.block.sns-deals .owl-wrapper-outer .item {
    margin: 0;
    padding: 0;
    padding-right: 1px
}
.block.sns-deals .owl-wrapper-outer .item button.btn-cart:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent var(--secondaryColor) transparent transparent;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: -5px;
    margin-top: -6px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}
.block.sns-deals .owl-wrapper-outer .item button.btn-cart:hover:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent #5492db transparent transparent
}
@media (min-width: 992px) and (max-width: 1199px) {
    .block.sns-deals .owl-wrapper-outer .item {
        padding: 9px
    }
}
.block.block-facebook .block-content {
    padding: 5px 0 0
}
.block .box-up-sell-content {
    background: #fff;
    border: 1px solid #ddd!important;
    border-top: 0!important;
    padding: 8px;
    margin-bottom: 25px
}
.block .box-up-sell-content .item {
    padding-bottom: 15px;
    margin-top: 5px!important;
    padding-top: 10px!important
}
.block .box-up-sell-content .item:hover .item-img {
    border: 1px solid #ddd!important
}
.block.box-up-sell .block-title {
    margin: 0
}
.block.box-up-sell label.related {
    position: absolute;
    left: 5px;
    top: 15px;
    z-index: 1
}
.block.box-up-sell label.related input.checkbox {
    height: 20px;
    margin: 0
}
.block.box-up-sell .item-img {
    padding: 8px 0;
    border: 1px solid transparent!important
}
.block.box-up-sell .block-subtitle {
    margin-bottom: 10px
}
.block.box-up-sell .block-subtitle a {
    color: var(--secondaryColor)
}
.block.block-stickynote.on-top .block-title {
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 8px 75px;
    border: 1px solid #CCC;
    float: left;
    margin: 0 30px 0 0;
    line-height: 40px;
    color: inherit
}
.block.block-stickynote.on-top .block-content {
    padding: 0;
    color: inherit;
    line-height: 18px
}
.block.blank .block-content {
    padding: 0 0!important
}
.block.block-custommenu {
    text-align: center!important;
    padding: 20px 0 10px;
    border: 1px solid transparent;
    margin-top: 10px;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in
}
.block.block-custommenu>* {
    margin: 0 auto
}
.block.block-custommenu .block-title {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #FFF
}
.block.block-custommenu .block-content {
    padding: 0 10px!important;
    color: #888
}
.block.block-custommenu:hover {
    border-color: #888
}
.block.block-wishlist .actions {
    padding: 15px 0 0
}
.block.block-compare ol li {
    position: relative
}
.block.block-compare .block-title small {
    display: none
}
.block.block-compare p.empty {
    margin: 0
}
.block.block-compare ol {
    padding: 0 0 0 20px;
    margin: 0
}
.block.block-compare ol li {
    margin: 3px 0
}
.block.block-compare ol li .product-name {
    padding-right: 20px
}
.block.block-compare .actions {
    padding: 15px 0 0;
    text-align: right
}
.block.block-compare .actions .button {
    margin: 0 0 0 5px
}
.upsell-products .box-up-sell .block-title {
    margin: 0 0 15px
}
.block-latestblog {
    overflow: hidden;
    margin: 0;
    padding-top: 10px
}
.block-latestblog .block-title {
    color: #333;
    margin-bottom: 30px
}
.block-latestblog .block-content {
    margin: 0 -15px;
    padding: 0
}
.block-latestblog .block-content strong {
    color: #222;
    font-weight: 400
}
.block-latestblog .block-content .post-img {
    margin-bottom: 16px
}
.block-latestblog .block-content .post-title {
    min-height: 56px;
    margin-bottom: 20px
}
.block-latestblog .block-content .post-title .date {
    padding-top: 8px;
    margin-top: 3px;
    float: left;
    width: 50px;
    height: 56px;
    border: 1px solid #eaeaea;
    margin-right: 15px
}
.block-latestblog .block-content .post-title .date span {
    float: left;
    width: 100%;
    text-align: center
}
.block-latestblog .block-content .post-title .date span.d-day {
    margin-bottom: 2px;
    color: var(--secondaryColor);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px
}
.block-latestblog .block-content .post-title .date span.d-month {
    color: #666;
    text-transform: uppercase
}
.block-latestblog .block-content .post-title a {
    color: #666;
    font-family: Titillium Web;
    font-size: 14px
}
.block-latestblog .block-content .post-title a:hover {
    color: var(--secondaryColor)
}
.block-latestblog .block-content .read_more {
    background: url(../images/border.png);
    background-repeat: repeat-x;
    background-position: left top;
    padding-top: 15px;
    color: #ccc
}
.block-latestblog .block-content .read_more .poster {
    margin-right: 10px
}
.block-latestblog .block-content .read_more .fa {
    font-size: 13px
}
.block-latestblog .block-content .read_more .comment_total .fa {
    margin-right: 3px
}
.block-latestblog .block-content .read_more .link-readmore {
    float: right
}
.block-latestblog .block-content .read_more .link-readmore a {
    color: #ccc
}
.block-latestblog .block-content .read_more .link-readmore a:hover {
    color: #666;
    text-decoration: underline
}
.block-latestblog .block-content .item-post {
    padding: 0 15px
}
.block-latestblog .read-more {
    margin: 15px 0
}
.block-latestblog .read-more:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px;
    border-color: transparent transparent var(--secondaryColor);
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 40%;
    margin-top: -6px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}
.block-latestblog .read-more:hover {
    color: #fff
}
.block-latestblog .read-more:hover:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px;
    border-color: transparent transparent #5492db
}
.block-latestblog.styleblog_v2 .block-title {
    margin-bottom: 20px!important
}
.block-latestblog.styleblog_v2 .read_more {
    padding-top: 20px
}
.block-latestblog.styleblog_v2 .block-content {
    position: relative
}
.block-latestblog.styleblog_v2 .navslider {
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 3
}
.block-latestblog.styleblog_v2 .navslider a {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: -6px;
    text-align: center;
    border: 1px solid #eaeaea;
    background: #fff;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block-latestblog.styleblog_v2 .navslider a.next {
    left: initial;
    right: -6px
}
.block-latestblog.styleblog_v2 .navslider a i {
    text-align: center;
    line-height: 28px;
    font-size: 16px;
    color: #cacaca;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.block-latestblog.styleblog_v2 .navslider a i:hover {
    color: #fff
}
.block-latestblog.styleblog_v2 .navslider a:hover {
    background: var(--secondaryColor);
    color: #fff
}
.mini-products-list.products-grid .item .product-image {
    border: 1px solid #ddd;
    display: block;
    cursor: default;
    overflow: hidden;
    position: relative
}
.mini-products-list.products-grid .item .product-image:after,
.mini-products-list.products-grid .item .product-image:before {
    height: 127px;
    width: 127px;
    opacity: .5!important;
    background-color: var(--secondaryColor);
    -ms-filter: "alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -webkit-transition: all .3s ease-in-out .1s;
    transition: all .3s ease-in-out .1s;
    content: "";
    display: block;
    position: absolute
}
.mini-products-list.products-grid .item .product-image:before {
    left: auto;
    right: 0;
    -webkit-transform: rotate(45deg) translateX(-180px);
    -ms-transform: rotate(45deg) translateX(-180px);
    transform: rotate(45deg) translateX(-180px);
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0
}
.mini-products-list.products-grid .item .product-image:after {
    top: auto;
    bottom: 0;
    -webkit-transform: rotate(45deg) translateX(180px);
    -ms-transform: rotate(45deg) translateX(180px);
    transform: rotate(45deg) translateX(180px);
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%
}
.mini-products-list.products-grid .item:hover .product-image {
    border: 1px solid var(--secondaryColor);
    cursor: default;
    overflow: hidden;
    position: relative
}
.mini-products-list.products-grid .item:hover .product-image:after,
.mini-products-list.products-grid .item:hover .product-image:before {
    height: 127px;
    width: 127px;
    opacity: .5!important;
    background-color: var(--secondaryColor);
    -ms-filter: "alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -webkit-transition: all .3s ease-out .3s;
    transition: all .3s ease-out .3s;
    content: "";
    display: block;
    position: absolute
}
.mini-products-list.products-grid .item:hover .product-image:before {
    left: auto;
    right: 0;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0
}
.mini-products-list.products-grid .item:hover .product-image:after {
    top: auto;
    bottom: 0;
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%
}
.mini-products-list.products-grid .item:hover .product-image:hover:after,
.mini-products-list.products-grid .item:hover .product-image:hover:before {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}
.mini-products-list.products-grid .item:hover .product-image:hover:after,
.mini-products-list.products-grid .item:hover .product-image:hover:before {
    -webkit-transform: rotate(45deg) translateX(0px);
    -ms-transform: rotate(45deg) translateX(0px);
    transform: rotate(45deg) translateX(0px)
}
.mini-products-list.products-grid .item:hover .product-image:after,
.mini-products-list.products-grid .item:hover .product-image:before {
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}
.mini-products-list.products-grid .item:hover .product-image:after,
.mini-products-list.products-grid .item:hover .product-image:before {
    -webkit-transform: rotate(45deg) translateX(0px);
    -ms-transform: rotate(45deg) translateX(0px);
    transform: rotate(45deg) translateX(0px)
}
.mini-products-list .item {
    padding-top: 15px;
    position: relative
}
.mini-products-list .item+.item {
    margin-top: 15px;
    border-top: 1px solid #ddd
}
.mini-products-list .item:after,
.mini-products-list .item:before {
    content: " ";
    display: table
}
.mini-products-list .item:after {
    clear: both
}
.mini-products-list .item .item-img {
    float: left;
    margin-right: 10px
}
.mini-products-list .item .item-price .price-box {
    display: inline-block
}
.mini-products-list .item .item-price .ico-sale {
    display: inline-block;
    vertical-align: text-bottom;
    background: #ff1616;
    color: #fff;
    font-size: 10px;
    border-radius: 3px;
    padding: 3px 5px 4px;
    margin-left: 10px;
    line-height: 100%;
    position: relative;
    margin-bottom: 2px
}
.mini-products-list .item .item-price .ico-sale:before {
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent #ff1616 transparent transparent;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px
}
.mini-products-list .item .old-price {
    display: block
}
.mini-products-list .item .old-price .price {
    line-height: 1
}
.mini-products-list .item .item-info {
    overflow: hidden
}
.mini-products-list .item .item-info .item-title a {
    color: #333
}
.mini-products-list .item .item-info .item-title a:hover {
    color: var(--secondaryColor)
}
.mini-products-list .item .item-info>.rating .no-rating *,
.mini-products-list .item .item-info>.rating .rating-links * {
    display: none
}
.mini-products-list .item .item-info .no-rating {
    margin: 0
}
.mini-products-list .item .item-info .no-rating a {
    display: none
}
.mini-products-list .item .item-info .no-rating:before {
    content: '\f005\f005\f005\f005\f005';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    letter-spacing: 2px;
    color: #dfdfdf
}
.mini-products-list#wishlist-sidebar .btn-remove {
    top: 15px
}
.mini-products-list.products-grid .item {
    margin: 0;
    padding-bottom: 10px;
    border: 0
}
.slider-wrap {
    position: relative
}
.slider-wrap:hover .navslider {
    opacity: 1;
    filter: alpha(opacity=100)
}
.slider-wrap .navslider {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .25s linear;
    transition: all .25s linear
}
.slider-wrap .navslider a {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    font-size: 40px;
    color: #ccc;
    z-index: 1
}
.slider-wrap .navslider a:hover {
    color: var(--secondaryColor)
}
.slider-wrap .navslider a.prev {
    left: 10px
}
.slider-wrap .navslider a.next {
    right: 10px
}
.block-subscribe .block_content {
    text-align: center
}
.block-subscribe .block_content>p {
    padding: 1px 0
}
.block-subscribe .block_content .actions {
    margin-bottom: 24px
}
.block-subscribe .block_content .actions button,
.block-subscribe .block_content .actions input {
    display: inline-block;
    vertical-align: middle;
    height: 40px;
    border-radius: 8px;
    border-color: #ddd
}
.block-subscribe .block_content .actions button:active,
.block-subscribe .block_content .actions button:focus,
.block-subscribe .block_content .actions button:hover,
.block-subscribe .block_content .actions input:active,
.block-subscribe .block_content .actions input:focus,
.block-subscribe .block_content .actions input:hover {
    border-color: var(--secondaryColor)
}
.block-subscribe .block_content .actions input {
    width: 300px;
    margin-right: 10px
}
.block-subscribe-content {
    background: var(--secondaryColor);
    padding: 33px 30px
}
.block-subscribe-content form {
    display: table;
    width: 100%
}
.block-subscribe-content .subscribe-title {
    display: table-cell;
    vertical-align: middle;
    vertical-align: top;
    width: 20px;
    padding-right: 30px
}
.block-subscribe-content .subscribe-title h3 {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff
}
.block-subscribe-content .subscribe-title P {
    color: #fff;
    margin: 0;
    white-space: nowrap
}
.block-subscribe-content .subscribe_form {
    display: table-cell;
    vertical-align: middle
}
.block-subscribe-content .subscribe_form .inner {
    position: relative
}
.block-subscribe-content .subscribe_form .inner input {
    width: 100%;
    background: #fff;
    border: 0;
    padding: 8px 10px;
    height: 40px
}
.block-subscribe-content .subscribe_form .inner button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    bottom: 0;
    font-size: 20px;
    line-height: 1;
    color: #ccc
}
.block-subscribe-content .subscribe_form .inner button:hover {
    background: 0 0;
    color: var(--secondaryColor)
}
@media (max-width: 767px) {
    .block-subscribe-content form {
        display: block
    }
    .block-subscribe-content h3 {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0 0 15px
    }
    .block-subscribe-content .subscribe_form {
        display: block;
        width: 100%
    }
}
@media (max-width: 479px) {
    .block-subscribe-content h3 {
        font-size: 33px
    }
}
.block_topsearch .form-search:after,
.block_topsearch .form-search:before {
    content: " ";
    display: table
}
.block_topsearch .form-search:after {
    clear: both
}
.block_topsearch .form-search input {
    border: 0;
    height: 50px;
    padding-right: 90px;
    background: #fff;
    width: 100%
}
.block_topsearch .form-search button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 20px;
    height: 50px;
    background: none!important;
    border: 0;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #666
}
.block_topsearch .form-search button:before {
    position: absolute;
    content: "";
    background: #eaeaea;
    width: 1px;
    height: 33px;
    top: 9px;
    left: 0
}
.block_topsearch .form-search button:hover {
    background: 0 0;
    color: var(--secondaryColor)
}
.block-categories-nav {
    position: relative;
    background: #222;
    color: #fff
}
.block-categories-nav a {
    color: #fff
}
.block-categories-nav>h4 {
    color: #fff;
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    margin: 0;
    cursor: pointer
}
.block-categories-nav>h4:after,
.block-categories-nav>h4:before {
    content: " ";
    display: table
}
.block-categories-nav>h4:after {
    clear: both
}
.block-categories-nav>h4>* {
    display: inline-block;
    vertical-align: middle
}
.block-categories-nav>h4 .fa {
    line-height: 60px
}
.block-categories-nav>h4 .fa-bars {
    font-size: 25px;
    margin-right: 10px;
    float: left
}
.block-categories-nav>h4 span {
    float: left
}
.block-categories-nav>h4 [class*=fa-caret-] {
    font-size: 16px;
    float: right
}
.block-categories-nav>.content {
    background: #222;
    position: absolute;
    width: 100%;
    min-width: 290px;
    top: 100%;
    left: 0;
    display: none
}
.block-categories-nav ul.nav_accordion {
    margin: 0;
    padding: 0;
    list-style: none
}
.block-categories-nav ul.nav_accordion a {
    color: #fff
}
.block-categories-nav ul.nav_accordion a:hover {
    color: #5492db
}
.block-categories-nav ul.nav_accordion li.level0 {
    border-top: 1px solid #393939
}
.block-categories-nav ul.nav_accordion li.level0>.accr_header {
    position: relative;
    padding: 15px 0 15px 15px;
    -webkit-transition: all .25s ease;
    transition: all .25s ease
}
.block-categories-nav ul.nav_accordion li.level0>.accr_header .btn_accor i:before {
    color: #fff;
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    cursor: pointer;
    line-height: 11px;
    text-align: center;
    right: 15px;
    font-size: 120%
}
.block-categories-nav ul.nav_accordion li.level0>.accr_header.open,
.block-categories-nav ul.nav_accordion li.level0>.accr_header:hover {
    background: #2f2f2f
}
.block-categories-nav ul.nav_accordion li.level0>.accr_header.open>a,
.block-categories-nav ul.nav_accordion li.level0>.accr_header.open>span i:before {
    color: #5492db
}
.block-categories-nav ul.nav_accordion li.level0>.accr_header a i,
.block-categories-nav ul.nav_accordion li.level0>.accr_header a span {
    display: inline-block;
    vertical-align: middle
}
.block-categories-nav ul.nav_accordion li.level0>.accr_header a i {
    font-size: 20px;
    margin-right: 10px;
    width: 15px;
    text-align: center
}
.block-categories-nav ul.nav_accordion li.level0 ul {
    margin: 0;
    padding: 0;
    list-style: none
}
.block-categories-nav ul.nav_accordion li.level0 ul li {
    border-top: 1px solid #393939
}
.block-categories-nav ul.nav_accordion li.level0 ul li .accr_header {
    line-height: 20px;
    padding: 15px 0 15px 44px;
    position: relative
}
.block-categories-nav ul.nav_accordion li.level0 ul li .accr_header.open>a,
.block-categories-nav ul.nav_accordion li.level0 ul li .accr_header.open>span i:before {
    color: #5492db
}
.block-categories-nav ul.nav_accordion li.level0 ul li .accr_header .btn_accor {
    display: inline-block;
    vertical-align: middle;
    float: right;
    color: #fff;
    cursor: pointer;
    text-align: center
}
.block-categories-nav ul.nav_accordion li.level0 ul li .accr_header .btn_accor i {
    display: block;
    text-align: center
}
.block-categories-nav ul.nav_accordion li.level0 ul li .accr_header .btn_accor i:before {
    color: #fff;
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    cursor: pointer;
    line-height: 11px;
    text-align: center;
    right: 15px;
    font-size: 120%
}
.block-categories-nav ul.nav_accordion li.level0 ul li a i {
    margin-right: 5px
}
.block-categories-nav ul.nav_accordion li.level0 ul li ul li .accr_header {
    padding: 15px 0 15px 70px
}
.block-categories-nav ul.nav_accordion li.level0 ul li ul li ul li .accr_header {
    padding: 15px 0 15px 100px
}
.sns_banner {
    margin-bottom: 20px
}
.sns_banner .banners_slider {
    position: relative
}
.sns_banner .banners_slider+.post-text {
    margin-top: 20px
}
.sns_banner .banners_slider .wrap {
    overflow: hidden
}
.sns_banner .banners_slider .owl-controls {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    text-align: center
}
.sns_banner .banners_slider .owl-controls .owl-page {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    margin: 0 2px
}
.sns_banner .banners_slider .owl-controls .owl-page.active,
.sns_banner .banners_slider .owl-controls .owl-page:hover {
    background: var(--secondaryColor)
}
.sns_banner .post-text {
    margin-top: 20px
}
.sns_banner .post-text ul.policy {
    margin: 0;
    padding: 0;
    list-style: none
}
.sns_banner .post-text ul.policy li {
    padding: 3px 0
}
.sns_banner .post-text ul.policy li:before {
    content: '\e080';
    font-family: Simple-Line-Icons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    line-height: 1;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    color: #ccc
}
.page-sitemap .pager {
    float: right;
    margin: 0
}
.page-sitemap ul li,
.page-sitemap ul li a {
    color: #333
}
.page-sitemap ul li a:hover {
    color: var(--secondaryColor)
}
#wishlist-table input,
#wishlist-table textarea {
    margin: 10px 0
}
#wishlist-sidebar .item .item-info {
    padding-right: 18px
}
#newsletter-popup {
    padding: 20px;
    text-align: center
}
#newsletter-popup .subscribe-title .title {
    color: var(--secondaryColor);
    font-size: 25px
}
#newsletter-popup .subscribe-title .description {
    color: #fff;
    font-size: 15px
}
#newsletter-popup .subscribe-content {
    margin-top: 20px
}
#newsletter-popup .subscribe-content .subscribe_form #newsletter {
    min-width: 275px;
    border-radius: 4px!important;
    margin-right: 2px
}
#newsletter-popup .subscribe-bottom {
    color: #fff;
    margin-top: 10px;
    margin-bottom: 0
}
#newsletter-popup .subscribe-bottom>input {
    margin-top: -3px;
    margin-right: 8px
}
#newsletter-fixed {
    z-index: 998;
    position: fixed;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-border-radius: 0 0 0 8px;
    -moz-border-radius: 0 0 0 8px;
    background: var(--secondaryColor);
    padding: 20px;
    text-align: center;
    padding-bottom: 40px
}
#newsletter-fixed .subscribe-title .title {
    color: #fff;
    font-size: 25px
}
#newsletter-fixed .subscribe-title .description {
    color: #fff;
    font-size: 15px
}
#newsletter-fixed .subscribe-content {
    margin-top: 20px
}
#newsletter-fixed .subscribe-content .subscribe_form #newsletter {
    min-width: 275px;
    margin-right: 2px;
    border-color: #fff
}
#newsletter-fixed .subscribe-content .subscribe_form #subcribe-btn {
    border-color: #fff;
    height: 34px
}
#newsletter-fixed .subscribe-content .subscribe_form #subcribe-btn i {
    color: var(--secondaryColor)
}
#newsletter-fixed .subscribe-content .subscribe_form #subcribe-btn:hover {
    background: #fff
}
#newsletter-fixed .subscribe-content .subscribe_form #subcribe-btn:hover i {
    color: var(--secondaryColor);
    animation: 1.3s ease-out 75ms normal none 1 running wobble;
    -webkit-animation: 1.3s ease-out 75ms normal none 1 running wobble
}
#newsletter-fixed .subscribe-bottom {
    color: #fff;
    margin-top: 10px;
    margin-bottom: 0
}
#newsletter-fixed .subscribe-bottom>input {
    margin-top: -3px;
    margin-right: 8px
}
#newsletter-fixed #newsletter-fixed-btn {
    position: absolute;
    top: 0;
    right: 100%;
    border: 1px solid var(--secondaryColor);
    border-width: 1px 0 1px 1px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: #fff
}
#newsletter-fixed #newsletter-fixed-btn i {
    font-size: 20px;
    color: var(--secondaryColor)
}
#newsletter-fixed #newsletter-fixed-close {
    display: block;
    position: absolute;
    cursor: pointer;
    background-color: #666;
    color: #fff;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 20px;
    height: 20px
}
#newsletter-fixed #newsletter-fixed-close i {
    font-size: 15px
}
#sns_testlist {
    position: fixed;
    z-index: 10000;
    width: 300px;
    top: 0;
    height: 100%;
    right: -300px;
    border-left: 1px solid #ccc;
    background: #fff;
    transition: all .3s ease;
    -webkit-transition: all .3s ease
}
#sns_testlist:hover {
    right: 0
}
#sns_testlist_btn {
    position: absolute;
    top: 0;
    right: 100%;
    border: 1px solid #ccc;
    border-width: 1px 0 1px 1px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #fff
}
#sns_testlist .inner {
    overflow: auto
}
.sns-social ul {
    list-style-type: none;
    padding-left: 0
}
.sns-social ul li {
    display: inline-block
}
.sns-social ul li a {
    font-size: 18px;
    margin-right: 10px;
    color: #ccc;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.sns-social ul li a:hover {
    color: var(--secondaryColor);
    -webkit-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg)
}
.testimonial_content {
    min-height: 270px;
    padding-top: 50px;
    padding-bottom: 45px
}
.testimonial_content h3 {
    margin: 0
}
.testimonial_content .navslider {
    position: relative;
    z-index: 99
}
.testimonial_content .navslider a {
    position: absolute;
    top: 0;
    width: 120px;
    height: 120px;
    z-index: 99;
    border-radius: 100%;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.testimonial_content .navslider a .fa {
    display: none
}
.testimonial_content .navslider a.prev {
    left: 67px
}
.testimonial_content .navslider a.next {
    right: 67px
}
.testimonial_content .navslider a:hover {
    background: #fff;
    opacity: .4
}
.testimonial_content .testimonials_slider .owl-carousel {
    padding-top: 0
}
.testimonial_content .testimonials_slider .wrap .avatar-info {
    text-align: center;
    position: relative
}
.testimonial_content .testimonials_slider .wrap .avatar {
    border: 3px solid var(--secondaryColor);
    width: 120px;
    height: 120px;
    border-radius: 100%;
    display: inline-block;
    background: var(--secondaryColor)
}
.testimonial_content .testimonials_slider .owl-item {
    min-height: 230px
}
.testimonial_content .testimonials_slider .owl-item .content {
    position: absolute;
    display: none;
    width: 300%;
    margin-left: -100%;
    top: 135px;
    text-align: center;
    //background: url(./images/bg_testimonial.png);
    padding-bottom: 30px;
    background-position: center bottom;
    background-repeat: no-repeat
}
.testimonial_content .testimonials_slider .owl-item .content .user {
    font-weight: 700;
    color: var(--secondaryColor);
    font-size: 13px
}
.testimonial_content .testimonials_slider .owl-item .content .position {
    text-transform: uppercase
}
.testimonial_content .testimonials_slider .owl-item.active {
    position: relative;
    z-index: 3
}
.testimonial_content .testimonials_slider .owl-item.active .content {
    display: none
}
.testimonial_content .testimonials_slider .owl-item.active.center {
    z-index: 1
}
.testimonial_content .testimonials_slider .owl-item.active.center .avatar-info {
    text-align: center;
    position: relative
}
.testimonial_content .testimonials_slider .owl-item.active.center .avatar-info:before {
    position: absolute;
    width: 200px;
    height: 3px;
    background: var(--secondaryColor);
    top: 50%;
    margin-top: -2px;
    left: -100px;
    content: "";
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transition-delay: 2s;
    transition-delay: 2s
}
.testimonial_content .testimonials_slider .owl-item.active.center .avatar-info:after {
    position: absolute;
    width: 200px;
    height: 3px;
    background: var(--secondaryColor);
    top: 50%;
    margin-top: -2px;
    right: -100px;
    content: "";
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transition-delay: 2s;
    transition-delay: 2s
}
.testimonial_content .testimonials_slider .owl-item.active.center .avatar {
    position: relative;
    z-index: 999
}
.testimonial_content .testimonials_slider .owl-item.active.center .avatar img {
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.testimonial_content .testimonials_slider .owl-item.active.center .avatar:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05)
}
.testimonial_content .testimonials_slider .owl-item.active.center .content {
    display: block!important;
    position: absolute;
    width: 300%;
    margin-left: -100%;
    top: 135px;
    text-align: center
}
.testimonial_content .testimonials_slider .owl-item.active.center .content .user {
    font-weight: 700;
    color: var(--secondaryColor);
    font-size: 13px
}
.testimonial_content .testimonials_slider .owl-item.active.center .content .position {
    text-transform: uppercase
}
.block.sns-slider.style_slide_v2 .container-slider {
    margin: 0 -10px
}
.block.sns-slider.style_slide_v2 .container-slider .item {
    padding-left: 10px;
    padding-right: 10px
}
.upsell-products {
    margin-bottom: 30px
}
.related-products {
    margin-bottom: 20px
}
.related-products .block-title {
    margin-bottom: 15px
}
.related-products .block-subtitle {
    margin-bottom: 6px
}
.related-products .related {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99
}
.related-products .related input {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 12px;
    margin: 0;
    padding: 0;
    min-height: 12px;
    border-color: #eaeaea
}
.blog-pagev1 #sns_left .block-latestblog-v3 {
    padding: 14px 15px 0 19px
}
.blog-pagev1 #sns_left .block-latestblog-v3 .block-content .list-blog .item-child {
    border-bottom: 1px solid #eaeaea;
    margin-top: 20px;
    padding-bottom: 18px
}
.blog-pagev1 #sns_left .block-latestblog-v3 .block-content .list-blog .item-child:last-child {
    border-bottom: 0
}
.blog-pagev1 #sns_left .block-latestblog-v3 .block-content .list-blog .item-child .item-img {
    float: left;
    margin-right: 10px
}
.blog-pagev1 #sns_left .block-latestblog-v3 .block-content .list-blog .item-child .item-img .post-img img {
    height: 60px;
    width: 60px
}
.blog-pagev1 #sns_left .block-latestblog-v3 .block-content .list-blog .item-child .item-content .post-title {
    font-weight: 700;
    color: #333
}
.blog-pagev1 #sns_left .block-latestblog-v3 .block-content .list-blog .item-child .item-content .date {
    margin-top: 12px
}
.blog-pagev1 #sns_left .block-latestblog-v3 .block-content .list-blog .item-child .item-content .date i {
    margin-right: 2px;
    position: relative;
    top: 0
}
.blog-pagev1 #sns_left .block-latestblog-v3 .block-content .list-blog .item-child .item-content .date span {
    color: #333
}
.blog-pagev1 #sns_main #discount-coupon-form .discount {
    border: 1px solid #eaeaea
}
.blog-pagev1 #sns_main .postWrapper.v1 {
    margin-top: 0
}
.blog-pagev1 #sns_main .postWrapper.v1 .post-content {
    color: #5f5f5f;
    padding-top: 4px
}
.blog-pagev1 #sns_main .postWrapper.v1 .post-content .text2 {
    margin: 20px 0
}
.blog-pagev1 #sns_main .postWrapper.v1 .post-content .text3 {
    background: #f5f5f5;
    padding: 14px 20px;
    margin-bottom: 20px
}
.blog-pagev1 #sns_main .postWrapper.v1 .post-content .text4,
.blog-pagev1 #sns_main .postWrapper.v1 .post-content .text5 {
    margin-bottom: 18px
}
.blog-pagev1 #sns_main .postWrapper.v1 .post-content .blog-social .blog-tt {
    color: #5f5f5f;
    margin-bottom: 1px
}
.blog-pagev1 #sns_main .postWrapper.v1 .post-content .blog-social .bl-cl {
    color: #333
}
.blog-pagev1 #sns_main .postWrapper.v1 .post-content .blog-social ul {
    position: relative;
    bottom: 1px;
    list-style: none;
    padding: 0 0 0 5px;
    display: inline-block;
    margin-bottom: 3px
}
.blog-pagev1 #sns_main .postWrapper.v1 .post-content .blog-social ul li {
    padding: 0 5px;
    display: inline-block
}
.blog-pagev1 #sns_main .postWrapper.v1 .blog-coments h6 {
    color: #333;
    font-weight: 700;
    font-size: 20px;
    margin-top: 0;
    padding-bottom: 5px
}
.blog-pagev1 #sns_main .postWrapper.v1 .blog-coments .blog-pdleft {
    padding-left: 90px
}
.blog-pagev1 #sns_main .postWrapper.v1 .blog-coments .blog-content {
    border-top: 1px solid #eaeaea;
    padding-top: 27px;
    padding-bottom: 17px
}
.blog-pagev1 #sns_main .postWrapper.v1 .blog-coments .blog-content .blog-coments-img {
    padding-top: 2px;
    float: left;
    height: 100px;
    padding-right: 20px
}
.blog-pagev1 #sns_main .postWrapper.v1 .blog-coments .blog-content .blog-coments-emma .title .title1 {
    font-weight: 700;
    color: #333
}
.blog-pagev1 #sns_main .postWrapper.v1 .blog-coments .blog-content .blog-coments-emma .depcisen {
    margin-bottom: 20px
}
.blog-pagev1 #sns_main .postWrapper.v1 .blog-coments .blog-content .blog-coments-emma .bottom-cm .title3 {
    color: #ccc;
    padding-right: 2px
}
.blog-pagev1 #sns_main .postWrapper.v1 .blog-coments .blog-content .blog-coments-emma .bottom-cm .title4 {
    color: #5f5f5f
}
.blog-pagev1 #sns_main .postWrapper {
    margin-top: 0;
    margin-bottom: 60px
}
.blog-pagev1 #sns_main .postWrapper .post-img {
    margin-bottom: 10px;
    display: block
}
.blog-pagev1 #sns_main .postWrapper .date {
    font-size: 12px;
    color: #5f5f5f;
    padding: 3px 0;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea
}
.blog-pagev1 #sns_main .postWrapper .date .post-info {
    display: inline-block;
    padding-left: 15px
}
.blog-pagev1 #sns_main .postWrapper .date .poster {
    padding-right: 19px;
    position: relative
}
.blog-pagev1 #sns_main .postWrapper .date .poster:before {
    display: inline-block;
    position: absolute;
    right: 0;
    content: "";
    height: 10px;
    width: 1px;
    background: #efefef;
    top: 5px
}
.blog-pagev1 #sns_main .postWrapper .post-title {
    margin-top: 13px
}
.blog-pagev1 #sns_main .postWrapper .post-title h1 {
    font-size: 30px;
    font-weight: 700;
    color: #333
}
.blog-pagev1 #sns_main .postWrapper .post-content {
    padding-top: 13px;
    padding-bottom: 18px;
    font-size: 16px;
}
.blog-pagev1 #sns_main .postWrapper .post-content p {
    line-height: 1.33
}
.blog-pagev1 #sns_main .postWrapper .link-readmore {
    display: inline-block
}
.blog-pagev1 #sns_main .postWrapper .link-readmore a {
    border: 1px solid #efefef;
    color: #666;
    font-size: 12px;
    font-weight: 700;
    padding: 10px 21px 9px;
    position: relative
}
.blog-pagev1 #sns_main .postWrapper .link-readmore a:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor);
    color: #fff
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar {
    margin: 65px 0 33px
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar .toolbar-inner .pager {
    float: none;
    border: 0;
    padding: 0!important
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar .toolbar-inner .pager .pages {
    float: none
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar .toolbar-inner .pager .pages ol li.current {
    padding: 6px 14px 4px;
    border: 2px solid var(--secondaryColor);
    margin-right: 10px;
    font-weight: 700;
    color: #666
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar .toolbar-inner .pager .pages ol li {
    height: auto;
    width: auto
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar .toolbar-inner .pager .pages ol li a {
    padding: 6px 14px 4px;
    border: 2px solid #ddd;
    margin-right: 10px;
    font-weight: 700
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar .toolbar-inner .pager .pages ol li a.next {
    width: auto;
    width: 39px;
    height: 38px;
    padding: 0;
    line-height: 35px
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar .toolbar-inner .pager .pages ol li a.next:before {
    content: '\f105';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 12px
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar .toolbar-inner .pager .pages ol li a.previous:before {
    content: '\f104';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 12px
}
.blog-pagev1 #sns_main .blog-toolbar .toolbar .toolbar-inner .pager .pages ol li a:hover {
    border: 2px solid var(--secondaryColor);
    color: #666
}
.discount-form {
    position: relative
}
.discount-form:hover span {
    display: none
}
.discount-form span {
    position: absolute;
    left: 0
}
.discount-form .span {
    display: none
}
.smart-green {
    border: 1px solid #eaeaea;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 30px 20px;
    color: #666
}
.smart-green h2 {
    padding: 20px 0 20px 40px;
    display: block;
    margin: -30px -30px 10px -30px;
    color: #FFF;
    background: #9DC45F;
    text-shadow: 1px 1px 1px #949494;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #89AF4C
}
.onenot {
    display: block;
    font-size: 20px;
    color: 333;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 40px
}
.smart-green .one2 input {
    margin-right: 25px;
    margin-top: 0!important
}
.smart-green .one2 input:last-child {
    margin-right: 0
}
.smart-green .one3 input {
    font-size: 12px;
    text-transform: uppercase;
    color: #666;
    font-weight: 700;
    height: 40px;
    border: 1px solid #eaeaea;
    width: 90px;
    text-align: center;
    background: #fff;
    border-radius: 0;
    padding: 2px 0 0;
    margin-top: 30px
}
.smart-green .one3 input:hover {
    border: 1px solid var(--secondaryColor);
    color: #fff;
    background: var(--secondaryColor)
}
.smart-green label {
    display: inline-block;
    margin: 0 0 5px
}
.smart-green label>span {
    float: left;
    margin-top: 10px;
    color: #5E5E5E
}
.smart-green label input {
    margin-right: 30px
}
.smart-green textarea {
    width: 100%!important;
    height: 180px!important;
    margin-top: 20px!important;
    padding-top: 10px!important
}
.smart-green .button {
    background-color: #9DC45F;
    border-radius: 5px;
    -moz-border-border-radius: 5px;
    border: 0;
    padding: 10px 25px;
    color: #FFF;
    text-shadow: 1px 1px 1px #949494
}
.smart-green .button:hover {
    background-color: #80A24A
}
.smart-green input[type=email],
.smart-green input[type=text],
.smart-green select,
.smart-green textarea {
    color: #666;
    height: 30px;
    line-height: 15px;
    width: 250px;
    padding: 0 0 0 10px;
    margin-top: 2px;
    border: 1px solid #eaeaea;
    background: #fff;
    outline: 0;
    -webkit-box-shadow: inset 1px 1px 2px rgba(238, 238, 238, .2);
    box-shadow: inset 1px 1px 2px rgba(238, 238, 238, .2);
    font: 400 14px/14px Arial, Helvetica, sans-serif
}
.blog-pagev1.detail .postWrapper {
    margin-bottom: 40px!important
}
#sns_breadcrumbs {
    min-height: 20px;
    padding-bottom: 18px;
    padding-top: 5px;
    font-size: 12px
}
body.homepage #sns_breadcrumbs {
    display: none
}
#sns_pathway {
    padding: 0;
    margin: 0
}
#sns_pathway:after,
#sns_pathway:before {
    content: " ";
    display: table
}
#sns_pathway:after {
    clear: both
}
#sns_pathway span[class^=icon-] {
    margin: 0;
    padding: 0;
    list-style: none
}
#sns_pathway .breadcrumbs {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0 0 0 7px;
    vertical-align: middle
}
#sns_pathway .breadcrumbs a {
    color: #666;
    font-size: 100%
}
#sns_pathway .breadcrumbs a:hover {
    color: var(--secondaryColor)
}
#sns_pathway .breadcrumbs li {
    display: inline-block
}
#sns_pathway .breadcrumbs li .fa {
    font-size: 100%;
    position: relative;
    top: 0;
    color: #666
}
#sns_pathway .breadcrumbs li:before {
    color: #ccc;
    content: "/";
    font-size: 9px;
    margin: 0
}
#sns_pathway .breadcrumbs li>* {
    padding: 1px 4px
}
#sns_pathway .breadcrumbs li:first-child:before {
    display: none
}
#sns_pathway .breadcrumbs li.last span {
    color: #333
}
#sns_pathway .breadcrumbs .home span {
    display: none
}
#sns_content {
    margin: 0 0 30px;
    position: relative;
    min-height: 500px
}
#sns_content #login-form h2 {
    margin: 0 0 15px
}
#sns_content #login-form ul {
    list-style: none;
    margin: 0
}
#sns_content #sns_maintop {
    margin-bottom: 50px
}
#sns_content #sns_mainmidle #crosssell-products-list .item {
    margin-top: 0
}
#sns_content #sns_mainmidle #crosssell-products-list .item.show-addtocart {
    margin-top: 17px
}
#sns_content #sns_mainbottom {
    margin-top: 30px
}
#sns_content #sns_mainbottom .sns-tabs .tabs-content .tab-item {
    top: 0
}
#sns_content .sns-tabs .tabs-navi {
    position: relative;
    z-index: 3
}
#sns_content .sns-tabs .tabs-navi li {
    display: block;
    margin-top: 5px;
    position: relative
}
#sns_content .sns-tabs .tabs-navi li:before {
    width: 7px;
    height: 39px;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: var(--secondaryColor);
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out
}
#sns_content .sns-tabs .tabs-navi li a {
    display: block;
    padding: 0 14px;
    height: 37px;
    line-height: 37px;
    border: 1px solid #e9e9e9;
    font-size: 150%;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}
#sns_content .sns-tabs .tabs-navi li a.tabulous_active {
    border-color: var(--secondaryColor);
    color: #FFF;
    background: var(--secondaryColor);
    position: relative;
    z-index: 2
}
#sns_content .sns-tabs .tabs-navi li:hover a {
    color: #FFF;
    text-decoration: none;
    position: relative;
    z-index: 2
}
#sns_content .sns-tabs .tabs-navi li:hover:before {
    width: 100%
}
#sns_content .sns-tabs .tabs-navi li:first-child {
    margin-top: 0
}
#sns_content .paypal-logo {
    text-align: center;
    margin: 0 0 20px
}
#sns_content .more-block .block {
    border: 1px solid #e9e9e9
}
#sns_content .more-block .block .block-title {
    font-size: 165%;
    padding: 8px 0;
    text-align: center;
    background: 0 0
}
#sns_content .more-block .block .block-content {
    padding: 0 10px
}
#sns_content .more-block .block .block-content ul {
    margin: 0;
    list-style: none
}
#sns_content .more-block .block .block-content ul li {
    position: relative;
    border-top: 1px solid #e9e9e9
}
#sns_content .more-block .block .block-content ul li .product-image {
    width: 40%;
    float: left;
    margin: 0;
    border: 0
}
#sns_content .more-block .block .block-content ul li .product-details {
    width: 60%;
    float: left;
    padding: 40px 0 0
}
#sns_content .more-block .block .block-content ul li .product-details .price-box .price {
    font-size: 130%;
    text-align: left
}
#sns_content .more-block .block .block-content ul li .product-details .price-box .old-price .price {
    font-size: 100%
}
#sns_content .more-block .block .block-content ul li .product-details input.checkbox {
    position: absolute;
    top: 10px;
    left: 0
}
#sns_content .more-block .block .block-content ul li:first-child {
    border: 0;
    padding-top: 0
}
#sns_content .block.blank {
    margin: 0
}
#sns_content .block.block-custom .block-content {
    padding: 10px
}
#sns_content .block.slide-banner ul {
    margin: 0
}
#sns_content .block.slide-banner ul li {
    list-style: none;
    display: inline-block;
    float: left;
    width: 270px!important;
    margin: 0
}
#sns_content .block.slide-banner ul li p.img-content {
    margin: 18px 0 40px;
    text-align: center
}
#sns_content .block.slide-banner ul li a.get-it {
    font-size: 150%;
    display: inline-block;
    border: 1px solid #e5e5e5;
    border-radius: 22px;
    padding: 11px 32px;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: #FFF
}
#sns_content .block.slide-banner ul li a.get-it:hover {
    color: #FFF;
    background: var(--secondaryColor);
    border-color: var(--secondaryColor)
}
#sns_content .block.slide-banner .caroufredsel_wrapper {
    width: 100%!important
}
#sns_content .block.slide-banner .paging {
    text-align: center;
    margin-top: 28px
}
#sns_content .block.slide-banner .paging a {
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background: var(--secondaryColor);
    margin: 0 0 0 5px;
    display: inline-block;
    text-indent: -999em;
    -webkit-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out
}
#sns_content .block.slide-banner .paging a:hover {
    background: #333
}
#sns_content .block.slide-banner .paging a.selected {
    border: 2px solid var(--secondaryColor);
    background: #FFF
}
#sns_content .block.block-tags .tags-list {
    list-style: none;
    margin: 0 0 10px;
    display: block
}
#sns_content .block.block-tags .tags-list li {
    display: inline-block;
    margin: 0
}
#sns_content .block.block-tags .tags-list li a {
    display: inline-block;
    padding: 3px 5px;
    color: #666;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out
}
#sns_content .block.block-tags .tags-list li a:hover {
    text-decoration: none;
    color: var(--secondaryColor)
}
#sns_content .block.block-tags .actions {
    display: block;
    text-transform: uppercase;
    margin: 10px 0 0
}
#sns_content .block.block-tags .actions a.button {
    float: left;
    padding: 7px;
    background: 0 0;
    color: var(--secondaryColor)
}
#sns_content .block.block-tags .actions a.button:hover {
    color: #5492db
}
#sns_content .block.block-tags .actions a.button span {
    display: inline-block
}
#sns_content .block.block-poll form {
    margin: 0
}
#sns_content .block.block-poll .block-subtitle {
    margin-bottom: 5px
}
#sns_content .block.block-poll ul#poll-answers {
    list-style: none;
    margin: 0 0 10px;
    padding: 0
}
#sns_content .block.block-poll ul#poll-answers li {
    padding: 4px 0
}
#sns_content .block.block-poll ul#poll-answers li label {
    cursor: pointer;
    margin: 0
}
#sns_content .block.block-poll ol#poll-answers {
    list-style: none;
    margin: 0 0 20px;
    padding: 6px 0 0
}
#sns_content .block.block-poll ol#poll-answers li {
    border-bottom: 1px solid #ededed;
    padding: 8px 0
}
#sns_content .block.block-poll ol#poll-answers li span.answer {
    float: left;
    margin: 0 5px 0 0;
    color: #333
}
#sns_content .block.block-poll ol#poll-answers li:first-child {
    border-top: 1px solid #ededed
}
#sns_content .block.block-account ul {
    margin: 0;
    padding: 0;
    list-style: none
}
#sns_content .block.block-account ul li {
    margin: 3px 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out
}
#sns_content .block.block-account ul li:before {
    content: '\f105';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 130%;
    font-weight: 700
}
#sns_content .block.block-account ul li.current,
#sns_content .block.block-account ul li.current strong {
    color: var(--secondaryColor)
}
#sns_content .block.block-account ul li:hover {
    padding-left: 10px;
    font-weight: 700;
    color: var(--secondaryColor)
}
#sns_content .block.block-account ul li:hover a {
    color: var(--secondaryColor)
}
#sns_content .block.block-reorder ol {
    list-style: none;
    margin: 0;
    padding: 0
}
#sns_content .block.block-reorder ol li input {
    float: left;
    margin: 0 8px 0 0
}
#sns_content .block.block-reorder .actions {
    margin: 10px 0 0
}
#sns_content .block.block-reorder .actions .btn-cart {
    margin-right: 10px
}
#sns_content .block.block-progress dl {
    margin: 0
}
#sns_content .block.block-progress dl>div,
#sns_content .block.block-progress dl>dt {
    margin: 0;
    padding: 8px 0;
    border-top: 1px solid #d4d4d4
}
#sns_content .block.block-progress dl>div:first-child,
#sns_content .block.block-progress dl>dt:first-child {
    border-top: 0
}
#sns_content .block.block-layered-nav .block-content {
    padding: 0
}
#sns_content .block.block-layered-nav ol.js-price {
    text-align: left
}
#sns_content .block.block-layered-nav ol.js-price li {
    margin-top: 10px;
    display: inline-block;
    height: 30px;
    line-height: 18px!important;
    padding-left: 5px!important;
    text-align: left;
    width: 70px;
    border: 1px solid #eaeaea;
    margin-bottom: 26px;
    margin-right: 8px
}
#sns_content .block.block-layered-nav ol.js-price li input {
    width: 50px
}
#sns_content .block.block-layered-nav ol.js-price li:last-child {
    cursor: pointer;
    margin-right: 0;
    padding: 0;
    text-align: center;
    font-weight: 700;
    color: #fff!important;
    background: #222;
    border: 1px solid #222;
    padding-left: 0!important
}
#sns_content .block.block-layered-nav ol.js-price li:last-child:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)
}
#sns_content .block.block-layered-nav ol.js-price li:before {
    display: none!important
}
#sns_content .block.block-layered-nav #slider-range {
    margin-bottom: 16px;
    border-radius: 0;
    width: 100%;
    height: 6px;
    border: 0;
    background: #eaeaea
}
#sns_content .block.block-layered-nav #slider-range .ui-slider-handle {
    border: 3px solid var(--secondaryColor);
    background: #fff;
    border-radius: 5px;
    height: 20px;
    width: 20px;
    top: -7px
}
#sns_content .block.block-layered-nav #slider-range .ui-slider-range {
    background: var(--secondaryColor)
}
#sns_content .block.block-layered-nav .currently {
    padding: 15px 0 0
}
#sns_content .block.block-layered-nav .currently .block-subtitle {
    color: #111;
    text-transform: uppercase;
    margin: 0 0 5px
}
#sns_content .block.block-layered-nav .currently ol {
    margin: 0;
    padding: 0 0 10px 20px
}
#sns_content .block.block-layered-nav .currently ol li {
    position: relative;
    padding: 5px 0
}
#sns_content .block.block-layered-nav .currently ol li .label {
    font-size: 100%;
    padding: 0;
    margin: 0
}
#sns_content .block.block-layered-nav .currently ol li .btn-remove {
    top: 50%;
    margin-top: -10px
}
#sns_content .block.block-layered-nav .currently .color-value span {
    display: inline-block;
    vertical-align: middle
}
#sns_content .block.block-layered-nav .currently .color-value .color-label {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: 1px solid #ddd
}
#sns_content .block.block-layered-nav .actions {
    text-align: right;
    padding: 0 15px 15px;
    border-bottom: 1px solid #ddd
}
#sns_content .block.block-layered-nav dl#narrow-by-list {
    margin: 0
}
#sns_content .block.block-layered-nav dl#narrow-by-list .last.odd.color-img li:hover img {
    border: 1px solid var(--secondaryColor)
}
#sns_content .block.block-layered-nav dl#narrow-by-list dt {
    text-transform: uppercase;
    position: relative;
    color: #333;
    padding: 16px 0 0;
    font-weight: 700;
    font-size: 12px
}
#sns_content .block.block-layered-nav dl#narrow-by-list .last.odd.color-img li img {
    top: 9px;
    border: 1px solid #ccc
}
#sns_content .block.block-layered-nav dl#narrow-by-list .last.odd.color-img li:before {
    display: none
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd {
    margin: 0;
    padding: 5px 0 10px;
    border-bottom: 1px solid #eaeaea
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .last-child {
    padding-bottom: 0!important
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .last-child ol {
    padding-bottom: 5px!important
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd a {
    color: #666
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd a:hover {
    color: var(--secondaryColor)
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd.last .last-child {
    background: 0 0;
    padding-bottom: 10px
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd.last .last-child .has-image img {
    position: absolute;
    left: 1px;
    top: 9px;
    width: 12px;
    height: 12px
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .configurable-swatch-list {
    background-position: left bottom;
    background-repeat: repeat-x
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol {
    margin: 0;
    padding: 0;
    list-style: none
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol li {
    padding: 7px 0;
    color: #666;
    position: relative;
}
#sns_content .block.block-layered-nav dl#narrow-by-list .odd2 ol li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label{
        margin: 0;
        display: flex;
        align-items: center;

        input{
            margin: 0 5px 0 0;
        }
    }
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol li a .count {
    float: right
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol li .item-count {
    color: var(--secondaryColor);
    margin-left: 3px
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol li:hover .item-count {
    color: var(--secondaryColor);
    font-style: normal
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol li:hover a {
    color: var(--secondaryColor)
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol.color-filter:after,
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol.color-filter:before {
    content: " ";
    display: table
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol.color-filter:after {
    clear: both
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol.color-filter li {
    padding: 7px 0
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol.color-filter li:after,
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol.color-filter li:before {
    display: none
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol.color-filter li .item span {
    display: inline-block;
    vertical-align: middle
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd ol.color-filter li .color-label {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    border: 1px solid #ddd
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price {
    padding-top: 10px
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box {
    margin: 0 0 15px;
    position: relative;
    width: 100%
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box:after,
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box:before {
    content: " ";
    display: table
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box:after {
    clear: both
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box>.inner>* {
    display: inline-block;
    vertical-align: middle;
    float: none
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box>.inner input {
    background: #fff;
    border: 1px solid #ddd;
    text-align: left;
    height: 28px;
    line-height: 28px;
    padding: 0 5px;
    width: 57px;
    color: #666
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box>.inner button {
    position: relative;
    width: 65px;
    height: 28px;
    line-height: 15px;
    margin-left: 10px;
    border: 0;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 0
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box>.inner button:hover {
    background: #5492db
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box>.inner button:hover:before {
    color: #5492db
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box>.inner button:before {
    content: '\f0d9';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: -22px;
    z-index: 1;
    line-height: 30px;
    color: var(--secondaryColor)
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box .price-separator {
    float: none;
    margin: 0
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box input[type=text] {
    margin: 0;
    width: 60px
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price p input#amount {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price p input#amount:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .range-wrap {
    padding: 6px 0
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .range-wrap #slider-range {
    border-radius: 0;
    margin: 3px 8px;
    background: #ddd;
    height: 10px
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .range-wrap #slider-range .ui-slider-range {
    background: #5492db;
    height: 10px;
    top: 0
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd .price .range-wrap #slider-range .ui-slider-handle {
    width: 25px;
    height: 25px;
    background: var(--secondaryColor);
    margin-left: -12px;
    top: 50%;
    margin-top: -12px;
    z-index: 1;
    border-radius: 4px
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd.last {
    border: 0
}
#sns_content .block.block-layered-nav dl#narrow-by-list dd.last ol {
    margin-bottom: 0
}
#sns_content .sns-productlist.style-default .item .item-inner {
    padding: 8px 15px;
    min-height: 85px
}
#sns_content .sns-productlist .block-content {
    padding: 5px 0 20px
}
#sns_content .sns-productlist .block-content .item:hover .item-inner .item-box a.product-image {
    border: 0
}
#sns_content .sns-productlist .block-content .item:hover .item-inner .item-box .product-image {
    border: 1px solid #ddd
}
#sns_content .sns-productlist .block-content .item .item-inner:after,
#sns_content .sns-productlist .block-content .item .item-inner:before {
    content: " ";
    display: table
}
#sns_content .sns-productlist .block-content .item .item-inner:after {
    clear: both
}
#sns_content .sns-productlist .block-content .item .item-inner .item-box {
    float: left;
    margin-right: 10px
}
#sns_content .sns-productlist .block-content .item .item-inner .item-box a.product-image {
    border: 0
}
#sns_content .sns-productlist .block-content .item .item-inner .item-box .product-image {
    border: 1px solid transparent;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;
    overflow: hidden
}
#sns_content .sns-productlist .block-content .item .item-inner .item-title {
    color: #666;
    font-weight: 700;
    text-transform: uppercase
}
#sns_content .sns-productlist .block-content .item .ratings,
#sns_content .sns-productlist .block-content .item .ratings .amount {
    display: none
}
#sns_content .sns-productlist .block-content .item .more-info {
    overflow: hidden
}
.my-account {
    border: 1px solid #ddd;
    padding: 15px
}
.my-account .dashboard .box-content {
    padding: 0
}
.my-account .box-head h2 {
    margin: 20px 0 0;
    font-size: 125%;
    text-transform: capitalize
}
.my-account .box .box-title {
    position: relative;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 12px 0
}
.my-account .box .box-title h3 {
    margin: 0;
    font-size: 110%;
    text-transform: capitalize
}
.my-account .box .box-title>a {
    position: absolute;
    right: 0;
    top: 12px
}
.my-account button.btn-cart {
    display: inline-block;
    vertical-align: middle;
    padding: 5px 15px 5px 40px;
    position: relative;
    font-size: 100%;
    border-color: var(--secondaryColor);
    background: var(--secondaryColor);
    color: #fff
}
.my-account button.btn-cart i {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    display: block;
    top: 0;
    left: 0;
    line-height: 30px;
    font-size: 16px;
    padding-left: 15px
}
.my-account button.btn-cart:hover {
    border-color: #5492db;
    background: #5492db;
    color: #fff
}
.my-account button.btn-cart:hover:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent #5492db transparent transparent
}
.slider-inner .pull-right small {
    font-size: 13px
}
.slider-inner .pull-right small .fa {
    padding-right: 2px
}
.drop-submenu12 .wrap_dropdown {
    padding: 18px 25px 21px 30px!important;
    width: max-content;
    min-width: 100%;
}
.drop-submenu12 .wrap_dropdown ul.level1 {
    padding: 0;
    list-style: none
}
.drop-submenu12 .wrap_dropdown ul.level1 li {
    line-height: 25px;
    color: #666
}
.drop-submenu12 .wrap_dropdown h6 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 6px
}
.no-width.wrap_submenu {
    border: 0!important;
    padding: 0!important;
    background: none!important;
    width: 800px!important
}
.no-width.wrap_submenu .no-pd {
    width: 800px!important;
    padding: 0!important;
    margin: 0 15px
}
.no-width.wrap_submenu .no-pd .style-pd3 {
    position: relative
}
.no-width.wrap_submenu .no-pd .style-pd3 a {
    position: absolute;
    right: 15px;
    top: 27px
}
.no-width.wrap_submenu .no-pd ul.level1 {
    padding: 3px 0 21px;
    list-style: none
}
.no-width.wrap_submenu .no-pd ul.level1 li {
    line-height: 25px;
    color: #666
}
.no-width.wrap_submenu .no-pd h6 {
    margin-top: 26px!important;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 6px
}
.no-width.wrap_submenu .wrap_dropdown {
    padding-left: 30px;
    border: 1px solid #eaeaea;
    margin-right: 30px;
    background: #fff
}
#sns_custommenu li:hover {
    z-index: 1
}
#sns_custommenu ul.mainnav {
    margin: 0;
    padding: 0;
    list-style: none
}
#sns_custommenu ul.mainnav:after,
#sns_custommenu ul.mainnav:before {
    content: " ";
    display: table
}
#sns_custommenu ul.mainnav:after {
    clear: both
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul ul {
    margin: 0;
    padding: 0;
    list-style: none
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level0 {
    list-style: none;
    padding: 0
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level0>li>a {
    font-size: 130%;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
    color: #666
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level0>li>a:hover {
    color: var(--secondaryColor)
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li {
    position: relative;
    display: block;
    padding: 0
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li.parent:after {
    position: absolute;
    top: 50%;
    right: 14px;
    font-size: 14px;
    margin-top: -7px;
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 14px;
    height: 14px;
    text-align: center;
    line-height: 14px;
    color: #cdcdcd
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li li {
    position: relative
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li li.parent:after {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 14px;
    height: 14px;
    text-align: center;
    line-height: 14px;
    position: absolute;
    top: 50%;
    right: 14px;
    font-size: 14px;
    margin-top: -7px;
    color: #cdcdcd
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li.last a {
    border-bottom: 0
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li li.active.parent:after,
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li li:hover.parent:after,
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li.active.parent:after,
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li:hover.parent:after {
    color: var(--secondaryColor)
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li li.active>a,
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li li:hover>a,
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li.active>a,
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li:hover>a {
    background: #eaeaea
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li li:hover>.wrap_submenu,
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li:hover>.wrap_submenu {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: all .25s ease-out .2s;
    transition: all .25s ease-out .2s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    display: block;
    visibility: visible
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li a {
    display: block;
    padding: 0 15px;
    line-height: 32px;
    color: #666
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li .wrap_submenu {
    position: absolute;
    min-width: 225px;
    padding: 15px 0;
    top: 0;
    left: 100%;
    background: #fff;
    border: 1px solid #eaeaea;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    display: block;
    visibility: hidden
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li .wrap_submenu li.last a {
    border-bottom: 0
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li:hover>a {
    padding-left: 5px;
    color: var(--secondaryColor);
    background: 0 0
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li>a {
    padding: 0;
    padding: 8px 0;
    color: #666;
    display: block;
    border-bottom: 1px solid #eee
}
#sns_custommenu ul.mainnav .group-item .wrap_group ul.level1>li>a:hover {
    padding-left: 5px;
    color: var(--secondaryColor);
    background: 0 0
}
#sns_custommenu ul.mainnav li.level0 {
    border: 0;
    position: relative;
    display: inline-block;
    text-align: left
}
#sns_custommenu ul.mainnav li.level0.custom-itemdrop-staticblock>a:after,
#sns_custommenu ul.mainnav li.level0.custom-itemdrop-staticblock>a:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}
#sns_custommenu ul.mainnav li.level0.custom-itemdrop-staticblock>a:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 11px;
    margin-left: -6px
}
#sns_custommenu ul.mainnav li.level0.custom-itemdrop-staticblock>a:before {
    border-color: rgba(234, 234, 234, 0);
    border-bottom-color: #eaeaea;
    border-width: 12px;
    margin-left: -7px
}
#sns_custommenu ul.mainnav li.level0.custom-itemdrop-staticblock>a:after,
#sns_custommenu ul.mainnav li.level0.custom-itemdrop-staticblock>a:before {
    display: none;
    left: 45%;
    bottom: 0;
    z-index: 99999
}
#sns_custommenu ul.mainnav li.level0.custom-itemdrop-staticblock>a:after {
    bottom: -1px!important
}
#sns_custommenu ul.mainnav li.level0.drop-submenu {
    position: relative
}
#sns_custommenu ul.mainnav li.level0.drop-submenu.group-item {
    position: static
}
#sns_custommenu ul.mainnav li.level0.drop-submenu:after,
#sns_custommenu ul.mainnav li.level0.drop-submenu:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}
#sns_custommenu ul.mainnav li.level0.drop-submenu:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 11px;
    margin-left: -6px
}
#sns_custommenu ul.mainnav li.level0.drop-submenu:before {
    border-color: rgba(234, 234, 234, 0);
    border-bottom-color: #eaeaea;
    border-width: 12px;
    margin-left: -7px
}
#sns_custommenu ul.mainnav li.level0.drop-submenu:after,
#sns_custommenu ul.mainnav li.level0.drop-submenu:before {
    display: none;
    left: 45%;
    bottom: -1px;
    z-index: 99999
}
#sns_custommenu ul.mainnav li.level0:hover>div {
    display: block;
    z-index: 9999;
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    top: 100%;
    top: 97%!important
}
#sns_custommenu ul.mainnav li.level0:hover.custom-itemdrop-staticblock>a:after,
#sns_custommenu ul.mainnav li.level0:hover.custom-itemdrop-staticblock>a:before,
#sns_custommenu ul.mainnav li.level0:hover.drop-submenu.parent:after,
#sns_custommenu ul.mainnav li.level0:hover.drop-submenu.parent:before {
    display: block
}
.wrap_submenu .level0{
    padding: 0;
}
#sns_custommenu ul.mainnav li.level0>div {
    position: absolute;
    left: 0;
    background: #fff;
    visibility: hidden;
    display: block;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 120%;
    border: 1px solid #eaeaea
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu {
    min-width: 225px;
    padding: 5px 0 15px;
    margin-top: 3px
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu:before {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 100%;
    height: 15px;
    content: ''
}
#sns_custommenu ul.mainnav li>div.wrap_submenu ul,
#sns_custommenu ul.mainnav li>div.wrap_submenu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
#sns_custommenu ul li {
    position: relative;
    list-style: none;
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li.parent:after {
    position: absolute;
    top: 50%;
    right: 14px;
    font-size: 14px;
    margin-top: -7px;
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 14px;
    height: 14px;
    text-align: center;
    line-height: 14px;
    color: #cdcdcd
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li li {
    position: relative
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li li.parent:after {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 14px;
    height: 14px;
    text-align: center;
    line-height: 14px;
    position: absolute;
    top: 50%;
    right: 14px;
    font-size: 14px;
    margin-top: -7px;
    color: #cdcdcd
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li.last a {
    border-bottom: 0
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li li.active.parent:after,
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li li:hover.parent:after,
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li.active.parent:after,
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li:hover.parent:after {
    color: var(--secondaryColor)
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li li.active>a,
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li li:hover>a,
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li.active>a,
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li:hover>a {
    background: #eaeaea
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li li:hover>.wrap_submenu,
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li:hover>.wrap_submenu {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: all .25s ease-out .2s;
    transition: all .25s ease-out .2s;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    display: block;
    visibility: visible
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li a {
    display: block;
    padding: 0 15px;
    line-height: 32px;
    color: #666
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li .wrap_submenu {
    position: absolute;
    min-width: 225px;
    padding: 15px 0;
    top: 0;
    left: 100%;
    background: #fff;
    border: 1px solid #eaeaea;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    display: block;
    visibility: hidden
}
#sns_custommenu ul.mainnav li.level0>div.wrap_submenu ul.level0>li .wrap_submenu li.last a {
    border-bottom: 0
}
#sns_custommenu ul.mainnav li.level0>div.fullwidth {
    width: 100%;
    padding: 25px;
    padding-bottom: 15px
}
#sns_custommenu ul.mainnav li.level0>div.fullwidth:before {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 100%;
    height: 15px;
    content: ''
}
#sns_custommenu ul.mainnav li.level0>div.fullwidth .headtitle {
    margin-bottom: 2px;
    margin-top: -10px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 0 10px
}
#sns_custommenu ul.mainnav li.level0>div.fullwidth .menu_custom_category .row {
    margin-bottom: 10px
}
#sns_custommenu ul.mainnav li.level0>div.fullwidth .menu_custom_category .row:first-child {
    margin-bottom: 20px
}
#sns_custommenu ul.mainnav li.level0>div.fullwidth .menu_custom_category .menu_category_title {
    color: #666;
    border-bottom: 1px dotted #ccc;
    padding-bottom: 15px
}
#sns_custommenu ul.mainnav li.level0>div.fullwidth .menu_custom_category ul li a {
    border: 0;
    padding: 2px 0!important
}
#sns_custommenu ul.mainnav li.level0>div.fullwidth .menu_custom_category.v2 .row {
    margin-bottom: 5px
}
#sns_custommenu ul.mainnav li.level0>div .custom-menuv2 .title {
    font-size: 116.667%;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 12px;
    margin-bottom: 6px
}
#sns_custommenu ul.mainnav li.level0>div .custom-menuv2 .menu li a {
    border: 0;
    padding: 3px 0!important
}
#sns_custommenu ul.mainnav li.level0:hover>a:after {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out
}
#sns_custommenu ul.mainnav li.level0 .pd-menu116 {
    padding-left: 0
}
#sns_custommenu ul.mainnav li.level0>a {
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    display: block;
    line-height: 50px;
    padding: 0 14px 0 15px;
    position: relative;
    color: #333
}
#sns_custommenu ul.mainnav li.level0>a>span.title {
    font-size: 12px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}
#sns_custommenu ul.mainnav li.level0>a>span.title>.label {
    position: absolute;
    top: -26px;
    left: 50%;
    margin: 0;
    margin-left: -16px;
    padding: 0
}
#sns_custommenu ul.mainnav li.level0>a>span.title>.label span {
    background: var(--secondaryColor);
    padding: 1px 4px;
    text-transform: uppercase;
    font-size: 10px;
    color: #fff;
    font-weight: 400;
    line-height: 12px
}
#sns_custommenu ul.mainnav li.level0>a>span.title>.label span:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: var(--secondaryColor) transparent transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -4px
}
#sns_custommenu ul.mainnav li.level0>a>span.title>.label span.new {
    background: #5492db;
    color: #333
}
#sns_custommenu ul.mainnav li.level0>a>span.title>.label span.new:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: #5492db transparent transparent
}
#sns_custommenu ul.mainnav li.level0>a>span.title>.label span.hot {
    background: var(--secondaryColor);
    color: #fff
}
#sns_custommenu ul.mainnav li.level0>a>span.title>.label span.hot:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: var(--secondaryColor) transparent transparent
}
#sns_custommenu ul.mainnav li.level0>a>span.title>.label span.sale {
    background: #236fe1;
    color: #fff
}
#sns_custommenu ul.mainnav li.level0>a>span.title>.label span.sale:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: #236fe1 transparent transparent
}
#sns_custommenu ul.mainnav li.level0.active>a,
#sns_custommenu ul.mainnav li.level0:hover>a {
    color: var(--secondaryColor)
}
#sns_custommenu ul.mainnav li.level0.active>a>span.title:before,
#sns_custommenu ul.mainnav li.level0:hover>a>span.title:before {
    bottom: -18px;
    opacity: 1
}
li.custom-item:hover>a:after {
    display: none!important
}
ul.mainnav li ul.menu li a {
    border-bottom: 1px solid #ddd;
    padding-top: 8px!important;
    padding-bottom: 8px!important
}
ul.mainnav li ul.menu li:last-child a {
    border-bottom: 0
}
#sns_mommenu {
    margin: 10px 0
}
#sns_mommenu ul {
    margin: 0;
    padding: 0;
    list-style: none
}
#sns_mommenu>[class*=btn] {
    padding: 0;
    border: 0;
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    line-height: 30px;
    margin-right: 20px;
    cursor: pointer;
    color: #333
}
#sns_mommenu>[class*=btn] i {
    font-size: 20px;
    line-height: 30px
}
#sns_mommenu>[class*=btn].leftsidebar,
#sns_mommenu>[class*=btn].rightsidebar {
    display: none
}
#sns_mommenu>[class*=btn]:hover {
    color: var(--secondaryColor)
}
#sns_mommenu .mainnav .accr_content {
    padding-left: 15px
}
#sns_mommenu .mainnav a.active {
    color: var(--secondaryColor)
}
#sns_mommenu .mainnav .accr_header {
    border-bottom: 1px solid #393939;
    padding: 10px 0
}
#sns_mommenu .mainnav .accr_header .btn_accor {
    cursor: pointer;
    float: right
}
#sns_mommenu .btn2 .overlay {
    content: "";
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    background: #000;
    opacity: .2;
    filter: alpha(opacity=20);
    cursor: pointer
}
#sns_mommenu .collapse_wrap {
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 9999;
    background: #222
}
#sns_mommenu .collapse_wrap:before {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    left: -1000px;
    right: -1000px;
    z-index: -1;
    background: #222;
    content: '';
    display: none
}
#sns_mommenu #menu_collapse>ul {
    padding: 20px
}
#sns_mommenu #menu_collapse a {
    color: #b9b9b9
}
#sns_mommenu #menu_collapse a:hover {
    color: #fff
}
#sns_mommenu #menu_offcanvas {
    position: fixed;
    top: 0;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
    opacity: 0;
    filter: alpha(opacity=0);
    left: -250px;
    height: 100%;
    overflow: auto;
    width: 250px;
    background: #222;
    color: #b9b9b9;
    padding: 20px;
    z-index: 9999
}
#sns_mommenu #menu_offcanvas>ul {
    padding-bottom: 50px
}
#sns_mommenu #menu_offcanvas.active {
    left: 0;
    opacity: 1;
    visibility: visible;
    filter: alpha(opacity=100)
}
#sns_mommenu #menu_offcanvas a {
    color: #b9b9b9
}
#sns_mommenu #menu_offcanvas a:hover {
    color: #fff
}
#sns_header.solid #sns_menu.keep-menu {
    background: #fff!important
}
#sns_menu .fullwidth .custom-banner-menu h4.title {
    font-size: 100%;
    text-transform: none;
    margin-top: 15px;
    margin-bottom: 13px
}
#sns_menu .fullwidth .custom-banner-menu p {
    margin-bottom: 0
}
.sns-products-detail1 #sns_header {
    margin-bottom: 7px
}
.sns-products-detail1 #sns_content {
    margin-bottom: 25px!important
}
#profile {
    color: #333
}
#profile .collateral-box .form-add h2 {
    font-size: 18px;
    margin: 0
}
#profile .collateral-box .form-add #review-form fieldset h3 {
    margin-top: 10px;
    font-size: 14px;
    text-transform: uppercase
}
#profile .collateral-box .form-add #review-form fieldset ul {
    list-style: none;
    padding: 0
}
#profile .collateral-box .form-add #review-form fieldset ul li {
    margin-bottom: 10px
}
#profile .collateral-box .form-add #review-form fieldset ul li input,
#profile .collateral-box .form-add #review-form fieldset ul li textarea {
    border: 1px solid #eaeaea
}
#profile .collateral-box .form-add #review-form fieldset ul label {
    font-weight: 700;
    color: #333
}
#profile .collateral-box .form-add #review-form .buttons-set button {
    background: #fff;
    border: 1px solid #ebebeb;
    color: gray;
    display: inline-block;
    font-size: 100%;
    height: 35px;
    line-height: 34px;
    padding: 0 15px;
    position: relative;
    text-transform: uppercase;
    -webkit-transition: all .25s ease-out 0s;
    transition: all .25s ease-out 0s;
    vertical-align: middle
}
#profile .collateral-box .form-add #review-form .buttons-set button:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)
}
#profile .collateral-box .form-add #review-form .buttons-set button:hover span {
    color: #fff
}
.bottom.row {
    margin-top: 50px
}
.products-related {
    margin-bottom: 6px
}
.detai-products1 {
    position: relative
}
.detai-products1 .owl-nav {
    margin: 0!important;
    position: absolute;
    top: -7px;
    background: #fff;
    right: 0;
    display: inline-block;
    z-index: 1;
    padding-left: 0
}
.detai-products1 .owl-nav .owl-prev {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea;
    margin-right: 10px
}
.detai-products1 .owl-nav .owl-prev:before {
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
.detai-products1 .owl-nav .owl-next {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea
}
.detai-products1 .owl-nav .owl-next:before {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
.detai-products1 .title {
    margin-bottom: 24px
}
.detai-products1 .title h3 {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    text-transform: uppercase
}
.detai-products1 .products-grid {
    margin: 0 -10px
}
.detai-products1 .products-grid form.top {
    margin-bottom: 5px;
    padding-left: 10px
}
.detai-products1 .products-grid form.top input {
    margin: 0 5px 0 0;
    position: relative;
    top: 2px
}
.detai-products1 .products-grid .item-row {
    position: static!important
}
.detai-products1 .products-grid .item-row .item {
    margin-top: 0;
    padding: 0 10px
}
.detai-products1 .products-grid .item-row .item form.bot {
    bottom: -5px;
    display: inline-block;
    left: 0;
    position: absolute;
    z-index: 1
}
#sns_mainm .description {
    margin-bottom: 47px
}
#sns_mainm .description .sns_producttaps_wraps1 {
    position: relative
}
#sns_mainm .description .sns_producttaps_wraps1 .detail-none {
    position: relative;
    display: none;
    margin: 0;
    padding: 15px 20px 14px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0;
    border: 1px solid #eaeaea
}
#sns_mainm .description .sns_producttaps_wraps1 .detail-none:hover {
    background: #c4b498;
    color: #fff
}
#sns_mainm .description .sns_producttaps_wraps1 .detail-none:hover i {
    color: #fff
}
#sns_mainm .description .sns_producttaps_wraps1 .detail-none i {
    position: absolute;
    right: 16px;
    top: 16px
}
#sns_mainm .description .sns_producttaps_wraps1 .nav.nav-tabs {
    border: 1px solid #eaeaea
}
#sns_mainm .description .sns_producttaps_wraps1 .nav.nav-tabs li div{
    margin: 0;
    padding: 15px 20px 14px;
    cursor: pointer;
    border: 0;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 0;
    border-right: 1px solid #eaeaea
}
#sns_mainm .description .sns_producttaps_wraps1 .nav.nav-tabs li div:hover,
#sns_mainm .description .sns_producttaps_wraps1 .nav.nav-tabs li.active div{
    background: #222;
    color: #fff
}
#sns_mainm .description .sns_producttaps_wraps1 .tab-content {
    padding: 17px 20px 15px;
    border: 1px solid #eaeaea;
    border-top: 0
}
#sns_mainm .description .sns_producttaps_wraps1 .tab-content .style1 .mid,
#sns_mainm .description .sns_producttaps_wraps1 .tab-content .style1 .top {
    margin-bottom: 19px
}
#sns_mainm .description .sns_producttaps_wraps1 .tab-content .style1 .bot {
    margin: 0
}
.sns-product-detail .product-essential .product-img-box .detail-img {
    position: relative
}
.sns-product-detail .product-essential {
    .swiper-button-next, .swiper-button-prev{
        color: #222222;
        &:hover{
           color: var(--mainColor)
        }
    }
    .product-slide {
        cursor: pointer;
        margin-bottom: 10px;
        img {
        border: 1px solid #eaeaea;
        max-height: 300px;
        width: 100%;
        object-fit: contain;
        }
    }
}
.sns-product-detail .product-essential .product-slide2{
    cursor: pointer;
    img {
        border: 1px solid #eaeaea;
        max-height: 101px;
        width: 100%;
        object-fit: contain;
    }
}
.sns-product-detail .product-essential .product-img-box .detail-img .deals {
    bottom: 0;
    position: absolute;
    margin: 0;
    width: 100%;
    overflow: hidden;
    height: 40px;
    line-height: 40px
}
.sns-product-detail .product-essential .product-img-box .detail-img .deals:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #eaeaea;
    position: absolute;
    top: 0
}
.sns-product-detail .product-essential .product-img-box .detail-img .deals .style1 {
    color: #fff;
    position: relative;
    padding: 0 10px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    background: #c4b498
}
.sns-product-detail .product-essential .product-img-box .detail-img .deals .style1:after {
    background: #c4b498 none repeat scroll 0 0;
    content: "";
    display: block;
    height: 134%;
    position: absolute;
    right: -10px;
    top: 0;
    -webkit-transform: rotate(158deg)!important;
    -ms-transform: rotate(158deg)!important;
    transform: rotate(158deg)!important;
    width: 15px
}
.sns-product-detail .product-essential .product-img-box .detail-img .deals .style1 .style2 {
    font-size: 12px;
    font-weight: 400
}
.sns-product-detail .product-essential .product-img-box .small-img {
    margin: 0 -5px;
    position: relative
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail {
    margin-top: 10px;
    cursor: pointer
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail:hover .owl-next,
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail:hover .owl-prev {
    display: block!important
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail {
    .owl-item{
        height: 100%;
    }
    .item {
        margin: 0 5px;
        display: inline-block;
        height: 100%;
    }
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .item img {
    border: 1px solid #eaeaea;
    height: 118px;
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-controls {
    margin: 0
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-nav .owl-next,
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-nav .owl-prev {
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-nav .owl-next:hover,
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-nav .owl-prev:hover {
    border: 1px solid var(--secondaryColor);
    background: var(--secondaryColor)
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-nav .owl-next:hover:before,
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-nav .owl-prev:hover:before {
    color: #fff
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-nav .owl-prev {
    border: 1px solid #eaeaea;
    height: 30px;
    left: 5px;
    margin: -16.5px 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 30px;
    background: #fff
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-controls .owl-nav .owl-prev:before {
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s;
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    line-height: 27px;
    color: #ccc
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-nav .owl-next {
    margin: -16.5px 0;
    position: absolute;
    right: 5px;
    border: 1px solid #eaeaea;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    top: 50%;
    background: #fff
}
.sns-product-detail .product-essential .product-img-box .small-img #sns_thumbail .owl-nav .owl-next:before {
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s;
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    line-height: 27px;
    color: #ccc
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .item-title {
    position: relative;
    top: -5px;
    margin-bottom: 3px;
    text-transform: uppercase
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .rating-block {
    margin-bottom: 15px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .rating-block .separator {
    color: #ccc
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .desc {
    margin-bottom: 18px;
    color: #666
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .desc h5 {
    margin-bottom: 5px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .desc p {
    line-height: 20px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .item-title {
    padding-top: 0
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .item-title {
    font-size: 20px;
    text-transform: none;
    font-weight: 400;
    color: #666
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .item-title a:hover {
    color: var(--secondaryColor)
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .item-price {
    margin-bottom: 15px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .item-price .price {
    font-size: 16px;
    color: #333;
    font-weight: 700
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .desc {
    padding: 10px 0 5px;
    margin-top: 10px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .desc h5 {
    color: #333;
    font-size: 12px;
    font-weight: 700
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form {
    font-size: 12px;
    color: #666
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form select {
    height: 30px;
    border: 1px solid #eaeaea;
    width: 50%;
    padding-left: 3px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form select option {
    padding-left: 5px;
    height: 30px;
    padding-top: 5px;
    cursor: pointer
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form .style-color {
    padding-left: 5px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form .style-color option {
    position: relative;
    padding-left: 5px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form .style-color option:before {
    background-position: center;
    background-repeat: no-repeat, repeat;
    background-repeat: repeat-x;
    bottom: 0;
    content: "";
    left: 0;
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 10px;
    border: 1px solid #eaeaea;
    margin-right: 9px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form .style-color .red:before {
    background: url(../images/shopby/color1.jpg) no-repeat
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form .style-color .yellow:before {
    background: url(../images/shopby/color2.jpg) no-repeat
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form .style-color .blue:before {
    background: url(../images/shopby/color3.jpg) no-repeat
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form .style-color .green:before {
    background: url(../images/shopby/color4.jpg) no-repeat
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form .mg-size {
    margin-bottom: 5px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form .mg-color {
    margin-top: 16px;
    margin-bottom: 5px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form p {
    font-weight: 700;
    color: #333
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info form p span {
    color: red
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .gfont {
    font-size: 12px!important;
    font-weight: 700!important;
    color: #333;
    text-transform: uppercase
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    margin-right: 18px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container button {
    display: inline-block;
    vertical-align: middle;
    color: #333;
    width: 35px;
    height: 35px;
    padding: 0;
    font-size: 18px;
    float: left;
    font-weight: 700;
    border-radius: 0;
    background: 0 0;
    font-family: Times New Roman!important;
    border: 1px solid #eaeaea
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container button.qty-decrease {
    background: url(../images/qty-.png);
    background-position: center;
    background-repeat: no-repeat
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container button.qty-decrease:hover {
    background: url(../images/qty--.png);
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor)
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container button.qty-increase {
    background: url(../images/qty+.png);
    background-position: center;
    background-repeat: no-repeat
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container button.qty-increase:hover {
    background: url(../images/qty++.png);
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor)
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container .qty-container {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    margin-right: 18px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container .qty-container button {
    display: inline-block;
    vertical-align: middle;
    color: #333;
    width: 35px;
    height: 35px;
    padding: 0;
    font-size: 18px;
    float: left;
    font-weight: 700;
    border-radius: 0;
    background: 0 0;
    font-family: Times New Roman!important;
    border: 1px solid #eaeaea
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container .qty-container button.qty-decrease {
    background: url(../images/qty-.png);
    background-position: center;
    background-repeat: no-repeat
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container .qty-container button.qty-decrease:hover {
    background: url(../images/qty--.png);
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor)
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container .qty-container button.qty-increase {
    background: url(../images/qty+.png);
    background-position: center;
    background-repeat: no-repeat
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container .qty-container button.qty-increase:hover {
    background: url(../images/qty++.png);
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--secondaryColor);
    border-color: var(--secondaryColor)
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container .qty-container input {
    display: inline-block;
    vertical-align: middle;
    background: 0 0;
    float: left;
    border: 0;
    font-weight: 700;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    height: 35px;
    width: 35px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container .qty-container input.qty {
    height: 35px;
    width: 38px;
    text-align: center
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container input {
    display: inline-block;
    vertical-align: middle;
    background: 0 0;
    float: left;
    border: 0;
    font-weight: 700;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    height: 35px;
    width: 35px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .qty-container input.qty {
    height: 35px;
    width: 38px;
    text-align: center
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions {
    margin-top: 20px;
    padding: 20px 0;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .btn-cart {
    display: inline-block;
    vertical-align: middle;
    width: 174px;
    height: 36px;
    position: relative;
    font-size: 14px;
    background: #fff;
    text-transform: uppercase;
    font-weight: 700;
    color: #333;
    line-height: 35px;
    margin-right: 6px;
    border: 1px solid #eaeaea
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .btn-cart i {
    font-size: 16px;
    margin-right: 2px;
    line-height: 33px;
    width: 18px;
    height: 35px;
    margin-left: 8px;
    text-align: center
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .btn-cart:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor);
    color: #fff
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .btn-cart:hover i {
    animation: 1.3s ease-out 75ms normal none 1 running wobble;
    -webkit-animation: 1.3s ease-out 75ms normal none 1 running wobble
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .add-to-links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    vertical-align: middle
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .add-to-links li {
    float: left
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .link-compare,
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .link-wishlist,
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .sns-btn-quickview {
    width: 36px;
    height: 36px;
    float: left;
    text-align: center;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    position: relative;
    color: #666;
    font-size: 12px;
    border: 1px solid #eaeaea;
    background: 0 0;
    line-height: 30px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .link-compare:hover,
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .link-wishlist:hover,
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .sns-btn-quickview:hover {
    border-color: var(--secondaryColor);
    background: var(--secondaryColor);
    line-height: 30px;
    color: #fff
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .link-wishlist:before {
    content: '\f004';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .link-compare {
    border-left: 0;
    border-right: 0
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .link-compare:before {
    content: '\f079';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .quickview-wrap {
    display: inline-block;
    vertical-align: middle;
    width: 34px;
    height: 34px
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .sns-btn-quickview {
    display: inline-block;
    vertical-align: middle
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .sns-btn-quickview span {
    display: none
}
.sns-product-detail .product-essential .product-shop .item-inner.product_list_style .item-info .actions .sns-btn-quickview:before {
    content: '\f06e';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px
}
.addthis_native_toolbox {
    margin-top: 25px
}
.products-grid1 #sns_content {
    margin-bottom: 25px!important
}
body {
    font-family: 'Montserrat';
    font-size: 12px
}
a:focus {
    outline: 0;
    outline-offset: 0
}
#sns_main {
    z-index: 0
}
@media (max-width: 641px) {
    .stickynote-top {
        display: none
    }
    .col2-set .col-1,
    .col2-set .col-2 {
        width: 100%;
        margin-bottom: 10px
    }
    .cart .col2-set {
        width: 100%
    }
    .cart tbody td .product-name {
        line-height: 18px
    }
    .cart tfoot td {
        padding: 10px 5px
    }
    .cart .totals {
        width: 100%
    }
    .cart .a-center input.input-text {
        padding: 6px 2px;
        text-align: center
    }
    #sns_content .block.block-social .block-title {
        float: none;
        display: block;
        margin-bottom: 20px
    }
    #sns_content .block.slide-banner ul {
        margin-left: 0!important
    }
    #sns_content .block.slide-banner ul>li {
        width: 620px!important
    }
    #sns_content #sns_left,
    #sns_content #sns_right {
        margin-bottom: 30px
    }
    #sns_content #sns_left .block-layered-nav .price .text-box input[type=text],
    #sns_content #sns_right .block-layered-nav .price .text-box input[type=text] {
        width: 60px
    }
    #sns_content #sns_mainmidle .category-products .toolbar .sort-by,
    #sns_promotions {
        display: none
    }
    .price-box .old-price {
        margin-right: 5px
    }
}
#sns_mommenu .btn.leftsidebar,
#sns_mommenu .btn.rightsidebar {
    display: none
}
#sns_mommenu .btn.leftsidebar .overlay,
#sns_mommenu .btn.rightsidebar .overlay {
    background: #000;
    opacity: .2;
    filter: alpha(opacity=20);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
    display: none
}
@media (max-width: 991px) {
    #sns_right {
        position: fixed;
        top: 0;
        right: -280px;
        width: 280px;
        padding: 15px;
        bottom: 0;
        background: #fff;
        Z-index: 9999;
        overflow: auto;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transition: all .3s ease-in;
        transition: all .3s ease-in
    }
    #sns_right.active {
        right: 0;
        opacity: 1;
        filter: alpha(opacity=100)
    }
    #sns_right .sns-col-inner {
        padding: 0 0 50px
    }
    #sns_left {
        position: fixed;
        top: 0;
        left: -280px;
        width: 280px;
        padding: 15px;
        bottom: 0;
        background: #fff;
        Z-index: 9999;
        overflow: auto;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transition: all .3s ease-in;
        transition: all .3s ease-in
    }
    #sns_left.active {
        left: 0;
        opacity: 1;
        filter: alpha(opacity=100)
    }
    #sns_left .sns-col-inner {
        padding: 30px 0 50px
    }
}
.show-sidebar #sns_menu {
    display: block!important;
    opacity: 1!important;
    filter: alpha(opacity=100)!important;
    z-index: 3!important
}
#sns_menu>.container>.inner {
    background: var(--secondaryColor)
}
#sns_menu>.container>.inner:after,
#sns_menu>.container>.inner:before {
    content: " ";
    display: table
}
#sns_menu>.container>.inner:after {
    clear: both
}
#sns_menu>.container>.inner #sns_mainnav {
    float: left
}
#sns_menu.keep-menu {
    background-color: var(--secondaryColor);
    position: fixed!important;
    bottom: auto!important;
    top: 0!important;
    z-index: 20;
    -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .19999999999999996);
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .19999999999999996)
}
#sns_menu.keep-menu h1#logo {
    padding: 10px 0
}
#sns_menu.keep-menu .container>.inner .header-right {
    padding: 20px 0
}
#sns_menu .container>.inner {
    position: relative
}
#sns_menu #sns_mainnav {
    position: static
}
#sns_menu .fullwidth h4.title {
    font-size: 130%;
    font-weight: 700
}
#sns_menu .fullwidth ul.menu {
    margin: 0;
    padding: 0;
    list-style: none
}
#sns_menu .fullwidth ul.menu li {
    display: block;
    padding: 0
}
#sns_menu .fullwidth ul.menu li a {
    padding: 0;
    padding: 2px 0;
    color: #666;
    display: block
}
#sns_menu .fullwidth ul.menu li a:hover {
    padding-left: 5px;
    color: var(--secondaryColor)
}
#sns_menu .wrap_topblock {
    padding-bottom: 20px
}
#sns_menu .wrap_bottomblock {
    padding-top: 20px
}
#sns_menu .block.sns-slider .block-title {
    margin: 0 0 20px;
    padding: 0
}
#sns_menu .block.sns-slider .container-slider {
    padding: 0;
    margin: 0 -15px
}
img {
    max-width: 100%
}
a:focus,
a:hover {
    color: var(--secondaryColor);
    text-decoration: underline
}
a {
    color: #666
}
a:hover,
i:hover,
span:hover {
    -webkit-transition: all .25s ease 0s;
    transition: all .25s ease 0s;
    text-decoration: none
}
.no-breadcrumbs #sns_menu {
    margin-bottom: 30px
}
.cms-home #sns_menu {
    margin-bottom: 50px
}
.cms-home #sns_content {
    margin: 0 0 50px
}
#sns_promotions {
    position: relative;
    z-index: 2;
    margin: 0 0 50px
}
#sns_productswrap {
    padding: 0
}
#sns_productswrap .sns-slider {
    margin-bottom: 25px
}
#sns_productswrap .sns-slider .owl-wrapper {
    margin: 10px 0 0
}
#sns_header {
    position: relative;
    z-index: 2;
    margin-bottom: 27px;
    font-size: 12px
}
#sns_header.transparent #sns_menu {
    background: 0 0
}
#sns_header.solid {
    margin-bottom: 30px
}
#sns_header.solid #sns_slideshow {
    min-height: 0!important
}
#sns_header.solid #sns_mainnav #sns_custommenu ul.mainnav li a,
#sns_header.solid #sns_mommenu>span.btn-navbar,
#sns_header.solid .tongle,
#sns_header.solid .tongle .fa {
    color: #333!important
}
#sns_header .sns_header_top {
    background: #222;
    vertical-align: middle;
    position: relative;
    height: 39px
}
#sns_header .sns_header_top .sns_module {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}
#sns_header .sns_header_top>div>* {
    display: inline-block;
    vertical-align: middle
}
#sns_header .sns_header_top .mycart .content {
    position: absolute;
    right: 0;
    min-width: 120px;
    background: #fff;
    z-index: 9999;
    padding: 0!important;
    border: 1px solid #eaeaea;
    visibility: hidden;
    display: block;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 120%
}
#sns_header .sns_header_top .mycart .content:after,
#sns_header .sns_header_top .mycart .content:before {
    bottom: 100%;
    left: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}
#sns_header .sns_header_top .mycart .content:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 11px;
    margin-left: -6px
}
#sns_header .sns_header_top .mycart .content:before {
    border-color: rgba(234, 234, 234, 0);
    border-bottom-color: #eaeaea;
    border-width: 12px;
    margin-left: -7px
}
#sns_header .sns_header_top .mysetting .content {
    position: absolute;
    right: 0;
    min-width: 120px;
    background: #fff;
    z-index: 9999;
    padding: 0!important;
    border: 1px solid #eaeaea;
    visibility: hidden;
    display: block;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 120%
}
#sns_header .sns_header_top .mysetting .content:after,
#sns_header .sns_header_top .mysetting .content:before {
    bottom: 100%;
    left: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}
#sns_header .sns_header_top .mysetting .content:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px!important;
    margin-left: -6px!important
}
#sns_header .sns_header_top .mysetting .content:before {
    border-color: rgba(234, 234, 234, 0);
    border-bottom-color: #eaeaea;
    border-width: 0!important;
    margin-left: -7px!important
}
#sns_header .sns_header_top .mycart,
#sns_header .sns_header_top .mysetting {
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s
}
#sns_header .sns_header_top .mycart:hover .tongle,
#sns_header .sns_header_top .mysetting:hover .tongle {
    cursor: pointer
}
#sns_header .sns_header_top .mycart:hover .tongle .fa:after,
#sns_header .sns_header_top .mysetting:hover .tongle .fa:after {
    opacity: 1;
    filter: alpha(opacity=100)
}
#sns_header .sns_header_top .mycart:hover .content,
#sns_header .sns_header_top .mysetting:hover .content {
    display: block;
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    top: 100%
}
#sns_header .sns_header_top .mycart .tongle,
#sns_header .sns_header_top .mysetting .tongle {
    font-size: 100%;
    color: #888;
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s
}
#sns_header .sns_header_top .mycart .tongle:hover,
#sns_header .sns_header_top .mysetting .tongle:hover {
    color: var(--secondaryColor)
}
#sns_header .sns_header_top .mycart .tongle .fa,
#sns_header .sns_header_top .mysetting .tongle .fa {
    position: relative
}
#sns_header .sns_header_top .mycart .tongle .fa:before,
#sns_header .sns_header_top .mysetting .tongle .fa:before {
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    display: block;
    height: 38px;
    width: 15px;
    line-height: 38px;
    border-radius: 4px;
    text-align: center
}
#sns_header .sns_header_top .mycart .tongle .fa:after,
#sns_header .sns_header_top .mysetting .tongle .fa:after {
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-left: -6px;
    opacity: 0;
    filter: alpha(opacity=0)
}
#sns_header .sns_header_top .mycart .content ul,
#sns_header .sns_header_top .mysetting .content ul {
    margin: 0;
    list-style: none;
    padding: 0;

    span{
        margin-left: 10px;
        cursor: pointer;
    }
}
#sns_header .sns_header_top .mycart .content ul li,
#sns_header .sns_header_top .mysetting .content ul li {
    min-height: 30px;
    line-height: 30px
}
#sns_header .sns_header_top .mycart .content ul li a:hover,
#sns_header .sns_header_top .mysetting .content ul li a:hover {
    color: #333
}
#sns_header .sns_header_top .mycart .content ul li i,
#sns_header .sns_header_top .mysetting .content ul li i {
    display: none
}
#sns_header .sns_header_top .mycart .content ul li a,
#sns_header .sns_header_top .mysetting .content ul li a {
    padding: 0 0 0 10px;
    display: block;
    width: 100%
}
#sns_header .sns_header_top .mycart .content ul li a.selected,
#sns_header .sns_header_top .mysetting .content ul li a.selected {
    background: #f5f5f5
}
#sns_header .sns_header_top .mycart .content ul li a:hover,
#sns_header .sns_header_top .mysetting .content ul li a:hover {
    background: #ccc
}
#sns_header .sns_header_top .mycart .content ul li:last-child a,
#sns_header .sns_header_top .mysetting .content ul li:last-child a {
    border: 0
}
#sns_header .sns_header_top .mysetting {
    display: inline-block;
    position: relative
}
#sns_header .sns_header_top .mysetting .content>span {
    color: #333;
    text-transform: uppercase;
    padding: 0 0 5px;
    display: block
}
#sns_header .sns_header_top .mysetting .content .list-lang {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd
}
#sns_header .sns_header_top .mysetting .content .list-lang img {
    margin-right: 5px
}
#sns_header .sns_header_top .mysetting .content li>span {
    color: #5492db
}
#sns_header .sns_header_top .myaccount .content ul.links {
    padding: 5px 0
}
#sns_header .sns_header_top .myaccount .content ul.links li a {
    text-decoration: none!important;
    color: #fff;
}
#sns_header .sns_header_top .myaccount .content ul.links li a:before {
    margin-right: 5px;
    width: 14px!important;
    text-align: center
}
#sns_header .sns_header_top .myaccount .content ul.links li a.top-link-myaccount:before {
    content: '\f007';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto;
    height: auto;
    text-align: center;
    line-height: auto
}
#sns_header .sns_header_top .myaccount .content ul.links li a.top-link-wishlist:before {
    content: '\f08a';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto;
    height: auto;
    text-align: center;
    line-height: auto
}
#sns_header .sns_header_top .myaccount .content ul.links li a.top-link-cart:before {
    content: '\f07a';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto;
    height: auto;
    text-align: center;
    line-height: auto
}
#sns_header .sns_header_top .myaccount .content ul.links li a.top-link-checkout:before {
    content: '\f046';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto;
    height: auto;
    text-align: center;
    line-height: auto
}
#sns_header .sns_header_top .myaccount .content ul.links li a.top-link-login:before {
    content: '\f084';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto;
    height: auto;
    text-align: center;
    line-height: auto
}
#sns_header .sns_header_top .myaccount .content ul.links li a.top-link-logout:before {
    content: '\f023';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto;
    height: auto;
    text-align: center;
    line-height: auto
}
#sns_header .sns_header_top .myaccount .content ul.links li a.top-link-myaccount:before {
    font-size: 16px
}
#sns_header .sns_header_top .language-switcher.mysetting {
    margin-right: 8px
}
#sns_header .sns_header_top .language-switcher img {
    float: left;
    margin-right: 5px;
    margin-top: 13px
}
#sns_header .sns_header_top .language-switcher #select-language img {
    margin-top: 10px;
    margin-right: 7px
}
#sns_header .sns_header_top .header-setting,
#sns_header .sns_header_top .header-setting .module-setting {
    display: inline-block
}
#sns_header .sns_header_top .header-setting .tongle {
    line-height: 36px;
    height: 39px;
    padding-right: 32px;
    position: relative
}

#sns_header .sns_header_top .header-setting .tongle:after {
    content: '\f0d7';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: auto;
    height: auto;
    text-align: center;
    position: absolute;
    top: 0;
    right: 18px;
    line-height: 39px
}
#sns_header .sns_header_top .header-setting .tongle span {
    position: relative;
    top: 2px
}
#sns_header .sns_header_top .header-setting .currency-switcher .tongle:before {
    display: none
}
#sns_header .sns_header_top .header-account {
    float: right
}
#sns_header .sns_header_top .header-account .myaccount {
    border-right: 0;
    padding-right: 0;
    color: #fff
}
#sns_header .sns_header_top .header-account .myaccount .tongle,
#sns_header .sns_header_top .header-account .myaccount .tongle span {
    display: none
}
#sns_header .sns_header_top .header-account .myaccount .content ul.links {
    padding: 0
}
#sns_header .sns_header_top .header-account .myaccount .content ul.links li a:hover {
    color: var(--secondaryColor)
}
#sns_header .sns_header_top .header-account .myaccount .customer-ct {
    display: block
}
#sns_header .sns_header_top .header-account .myaccount .customer-ct ul {
    list-style: none;
    margin: 0
}
#sns_header .sns_header_top .header-account .myaccount .customer-ct ul li {
    display: inline-block;
    position: relative;
    padding-left: 13px;
    margin-left: 10px;
    line-height: 37px;
    font-size: 13px;
    float: left;
    border-left: 1px solid #363636;
    cursor: pointer;
}
#sns_header .sns_header_top .header-account .myaccount .customer-ct ul li:last-child {
    padding-right: 10px;
    border-right: 1px solid #363636
}
#sns_header .sns_header_top .header-account .myaccount .customer-ct ul li:last-child:before {
    display: none
}
#sns_header .sns_header_top .currency-switcher .content,
#sns_header .sns_header_top .language-switcher .content {
    left: 0!important
}
#sns_header #sns_menu .container_in {
    position: relative
}
#sns_header #sns_menu .container_in:after,
#sns_header #sns_menu .container_in:before {
    content: " ";
    display: table
}
#sns_header #sns_menu .container_in:after {
    clear: both
}
#sns_header #sns_menu h1.responsv {
    margin: 0;
    line-height: 1;
    padding: 20px 0;
    float: left
}
@media (max-width: 479px) {
    #sns_header #sns_menu h1.responsv {
        text-align: center
    }
}
#sns_header #sns_menu .header-menu-block {
    float: right
}
#sns_header #sns_menu .header-menu-block #sns_mainnav {
    display: inline-block;
    vertical-align: middle
}
#sns_header #sns_header_bot {
    background: #5492db
}
#sns_header #sns_header_bot .block_categories {
    display: table-cell;
    vertical-align: top;
    padding-right: 10px
}
#sns_header #sns_header_bot .block_topsearch {
    display: table-cell;
    vertical-align: top
}
.sns-revolutionslider-wrap {
    position: relative;
    z-index: 0
}
.sns-revolutionslider-wrap ul {
    margin: 0;
    padding: 0;
    list-style: none
}
.sns-revolutionslider-wrap .fsbg {
    background: rgba(225, 225, 225, .15);
    padding: 10px 12px!important
}
.sns-revolutionslider-wrap .cwhite {
    color: #fff
}
.sns-revolutionslider-wrap .cred {
    color: #dd5858
}
.sns-revolutionslider-wrap .cyellow {
    color: #5492db
}
.sns-revolutionslider-wrap .fs80 {
    font-size: 80px
}
.sns-revolutionslider-wrap .fs70 {
    font-size: 70px
}
.sns-revolutionslider-wrap .fs50 {
    font-size: 50px
}
.sns-revolutionslider-wrap .fs40 {
    font-size: 40px
}
.sns-revolutionslider-wrap .fs34 {
    font-size: 34px
}
.sns-revolutionslider-wrap .fs30 {
    font-size: 30px
}
.sns-revolutionslider-wrap .fs24 {
    font-size: 24px
}
.sns-revolutionslider-wrap .fs20 {
    font-size: 20px
}
.sns-revolutionslider-wrap .fs16 {
    font-size: 16px
}
.sns-revolutionslider-wrap .fs13 {
    font-size: 13px
}
.sns-revolutionslider-wrap .fwbold {
    font-weight: 700
}
.sns-revolutionslider-wrap .fwnormal {
    font-weight: 400
}
.sns-revolutionslider-wrap .tuppercase {
    text-transform: uppercase
}
.sns-revolutionslider-wrap .tleft {
    text-align: left
}
.sns-revolutionslider-wrap .tright {
    text-align: right
}
.sns-revolutionslider-wrap .tcenter {
    text-align: center
}
.sns-revolutionslider-wrap .btn-more {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px;
    display: inline-block;
    background: var(--secondaryColor);
    font-size: 16px
}
.sns-revolutionslider-wrap .sns-revolutionslider {
    overflow: hidden
}
.sns-revolutionslider-wrap .wrap-inner {
    position: relative
}
.sns-revolutionslider-wrap .wrap-inner:hover .tparrows {
    opacity: 1;
    filter: alpha(opacity=100)
}
.sns-revolutionslider-wrap .wrap-inner .tparrows {
    background: 0 0;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 50px;
    height: 50px
}
.sns-revolutionslider-wrap .wrap-inner .tparrows:before {
    content: '\f104';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, .5);
    font-size: 32px;
    color: #fff;
    line-height: 50px;
    text-align: center
}
.sns-revolutionslider-wrap .wrap-inner .tparrows:hover:before {
    background: #fff;
    color: #666
}
.sns-revolutionslider-wrap .wrap-inner .tparrows.tp-leftarrow:before {
    content: "\f104"
}
.sns-revolutionslider-wrap .wrap-inner .tparrows.tp-rightarrow:before {
    content: "\f105"
}
.sns-revolutionslider-wrap .wrap-inner .tp-bullets .bullet {
    width: 12px!important;
    height: 12px!important;
    float: none!important;
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px!important;
    background: #fff!important
}
.sns-revolutionslider-wrap .wrap-inner .tp-bullets .bullet.selected,
.sns-revolutionslider-wrap .wrap-inner .tp-bullets .bullet:hover {
    outline: 1px solid #fff;
    outline-offset: 2px
}
.sns-revolutionslider-wrap [class*=col-] {
    padding-bottom: 30px
}
.customer-banner .banner2 {
    margin-bottom: 18px
}
.banner-home-v {
    margin-bottom: 50px
}
.sns-latestblog {
    position: relative;
    margin-bottom: 36px
}
.sns-latestblog .block-title {
    margin-bottom: 25px;
    position: relative;
    display: block
}
.sns-latestblog .block-title:before {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background: #eaeaea;
    content: "";
    left: 0;
    top: 9px;
    z-index: -1
}
.sns-latestblog .block-title h3, #sns_partners .block-title h3 {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin: 0;
    display: inline-block;
    background: #fff;
    padding-right: 25px
}
#sns_partners .block-title h3{
    margin-bottom: 25px;
}
.sns-latestblog .latestblog-content {
    position: static
}
.sns-latestblog .latestblog-content .owl-nav {
    margin: 0;
    position: absolute;
    top: -7px;
    background: #fff;
    right: 0;
    display: inline-block;
    z-index: 1;
    padding-left: 30px
}
.sns-latestblog .latestblog-content .owl-nav .owl-prev {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea;
    margin-right: 10px
}
.sns-latestblog .latestblog-content .owl-nav .owl-prev:before {
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
.sns-latestblog .latestblog-content .owl-nav .owl-next {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea
}
.sns-latestblog .latestblog-content .owl-nav .owl-next:before {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
.sns-latestblog .latestblog-content .item {
    padding: 0 15px;
    cursor: pointer;
}
.sns-latestblog .latestblog-content .banner5 {
    margin-bottom: 20px
}
.sns-latestblog .latestblog-content .blog-page {
    display: inline-block;
    position: relative;
}
.sns-latestblog2 .latestblog-content .blog-page{
    padding-left: 0;
}
.sns-latestblog .latestblog-content .blog-left {
    text-align: center;
    border: 1px solid #eaeaea;
    padding: 2px 9px 0;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0
}
.sns-latestblog .latestblog-content .blog-left .text1 {
    font-size: 25px;
    color: #333;
    font-weight: 700;
    display: block;
    margin: 0
}
.sns-latestblog .latestblog-content .blog-left .text2 {
    position: relative;
    bottom: 4px;
    font-size: 12px;
    color: #666;
    text-transform: uppercase;
    display: block;
    margin: 0
}
.sns-latestblog .latestblog-content .blog-right .style1 {
    margin: 0 0 3px
}
.sns-latestblog .latestblog-content .blog-right .style1 p {
    font-size: 12px;
    color: var(--secondaryColor);
    margin:0;
}
.sns-latestblog .latestblog-content .blog-right .style2 {
    font-size: 14px;
    color: #333;
    font-weight: 700;
    margin: 0 0 6px
}
.sns-latestblog .latestblog-content .blog-right .style3 {
    color: #666;
    margin: 0
}
#sns_content .sns_producttaps_wraps {
    margin-top: 18px;
    margin-bottom: 65px
}
#sns_content .sns_producttaps_wraps .precar {
    display: none
}
#sns_content .sns_producttaps_wraps .bt-more {
    text-align: center;
    margin-top: 5px
}
#sns_content .sns_producttaps_wraps .bt-more span {
    font-size: 12px;
    color: #666;
    border: 1px solid #eaeaea;
    font-weight: 700;
    text-transform: uppercase;
    padding: 11px 73px 9px;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    cursor: pointer
}
#sns_content .sns_producttaps_wraps .bt-more span:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor);
    color: #fff
}
#sns_content .sns_producttaps_wraps ul.nav {
    position: relative;
    margin-bottom: 25px;
    border: 0
}
#sns_content .sns_producttaps_wraps ul.nav:before {
    background: #eaeaea none repeat scroll 0 0;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 12px;
    width: 100%;
    z-index: -1
}
#sns_content .sns_producttaps_wraps ul.nav li {
    background: #fff;
    position: relative;
    padding: 0 22px;
    text-align: left
}
#sns_content .sns_producttaps_wraps ul.nav li:after {
    position: absolute;
    right: -1px;
    top: 5px;
    height: 15px;
    width: 1px;
    content: "";
    display: inline-block;
    background: #eaeaea;
    z-index: 2
}
#sns_content .sns_producttaps_wraps ul.nav li:first-child {
    padding-left: 0
}
#sns_content .sns_producttaps_wraps ul.nav li:last-child {
    padding-right: 30px
}
#sns_content .sns_producttaps_wraps ul.nav li:last-child:after {
    display: none
}
#sns_content .sns_producttaps_wraps ul.nav li.active,
#sns_content .sns_producttaps_wraps ul.nav li.active a {
    -webkit-transition: all .25s ease 0s;
    transition: all .25s ease 0s
}
#sns_content .sns_producttaps_wraps ul.nav li.active a:after,
#sns_content .sns_producttaps_wraps ul.nav li.active a:before {
    opacity: 1;
    margin-top: 0
}
#sns_content .sns_producttaps_wraps ul.nav li a {
    margin: 0!important;
    padding: 0;
    border: 0;
    color: #333;
    font-size: 20px;
    margin-bottom: 24px;
    text-transform: uppercase
}
#sns_content .sns_producttaps_wraps ul.nav li a::before {
    background: #333 none repeat scroll 0 0;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    margin-top: 10px;
    opacity: 0;
    position: absolute;
    top: 100%;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    width: 100%
}
#sns_content .sns_producttaps_wraps ul.nav li a:after {
    border-color: #333 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 7px 6px 0;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin: 10px -8px 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    -webkit-transform: rotate(0deg)!important;
    -ms-transform: rotate(0deg)!important;
    transform: rotate(0deg)!important;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    width: 0
}
#sns_content .sns_producttaps_wraps ul.nav li a:hover {
    background: 0 0
}
#sns_content .sns_producttaps_wraps ul.nav li a:hover:after,
#sns_content .sns_producttaps_wraps ul.nav li a:hover:before {
    opacity: 1;
    margin-top: 0
}
#sns_content .sns_producttaps_wraps .tab-content {
    -webkit-transition: all .25s ease 0s;
    transition: all .25s ease 0s
}
#sns_content .sns_producttaps_wraps .tab-content .item {
    margin-bottom: 9px
}
#sns_content .sns_producttaps_wraps .tab-content .item .item-inner .prd .item-info {
    padding-bottom: 0
}
#sns_content .sns_banner {
    margin-bottom: 53px;
    position: relative;
    clear: both;
    background: #F4F4F4;
    padding: 32px 84px 32px 94px;
}
#sns_content .sns_banner .style-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 107.5%;
    color: #333333;
    text-transform: uppercase;
    margin-bottom: 24px;
    position: relative;
}
#sns_content .sns_banner .style-title::after{
    position: absolute;
    content: "";
    background-color: var(--mainColor);
    height: 1px;
    width: 24%;
    left: 0;
    bottom: -12px;
}
#sns_content .sns_banner .style-text1 {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #666666;
}
#sns_content .sns_banner .style-text2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    text-transform: uppercase;
    margin-bottom: 5px;
}
#sns_content .sns_banner .mr-24{
    margin-bottom: 24px;
}
#sns_content .sns_banner .mr-5{
    margin-bottom: 5px;
}
#sns_content .sns_banner .style-text3 {
    font-size: 13px;
    line-height: 19px;
    color: #999999;
}
#sns_content .sns_banner .style-text4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 38px;
    text-transform: uppercase;
    color: #999999;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
#sns_content .sns_banner .style-text4 span{
    position: relative;
    z-index: 1;
    background-color: #F4F4F4;
    padding: 0 8px;
}
#sns_content .sns_banner .style-text4::before{
    position: absolute;
    content: "";
    background-color: #999999;
    height: 1px;
    width: 100%;
    right: 0;
}
#sns_content .sns_banner .input {
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    outline: none;
    height: 35px;
    padding: 0 10px;
    width: 100%;
}
#sns_content .sns_banner .button {
    outline: none;
    background: #333333;
    border: none;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    color: #FFFFFF;
    height: 35px;
    padding: 0 18px;
    transition: all .2s linear;
}
#sns_content .sns_banner .button:hover{
    color: #fff;
    background: var(--secondaryColor);
}
#sns_content .sns_banner .flex{
    display: flex;
    gap: 35px;
}
#sns_content .sns_banner .flex3{
    display: flex;
}
#sns_content .sns_banner .mr-right{
    margin-right: 50px;
}
#sns_content .sns_banner .flex4{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 160px;
}
#sns_content .sns_banner .align-self-center{
    align-self: center;
}
#sns_content .sns_banner .flex2{
    display: flex;
    gap: 16px;
    align-items: center;

    img{
        height: 63px;
        object-fit: contain;
    }
}
#sns_content .sns_banner .style-text5{
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    text-transform: uppercase;
}
#sns_content .sns_suggest {
    margin-bottom: 15px
}
#sns_content .sns_suggest .block-title {
    position: relative
}
#sns_content .sns_suggest .block-title h3 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin-bottom: 24px
}
#sns_content .sns_suggest .block-title .fa {
    display: none;
    border: 1px solid #ebebeb;
    color: #666;
    cursor: pointer;
    font-size: 17px;
    height: 35px;
    line-height: 35px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -7px;
    width: 35px
}
#sns_content .sns_suggest .suggest-content {
    display: inline-block;
    position: relative;
    border: 1px solid #eaeaea
}
#sns_content .sns_suggest .suggest-content .suggest-item {
    position: relative;
    -webkit-transition: all .25s ease 0s;
    transition: all .25s ease 0s;
    text-align: center;
    width: 11.11%;
    float: left;
    border-right: 1px solid #eaeaea;
    line-height: 17px
}
#sns_content .sns_suggest .suggest-content .suggest-item:last-child {
    border-right: 0
}
#sns_content .sns_suggest .suggest-content .suggest-item img {
    width: 59%
}
#sns_content .sns_suggest .suggest-content .suggest-item .title {
    position: relative;
    bottom: 8px;
    display: inline-block
}
#sns_content .sns_suggest .suggest-content .suggest-item:after {
    border-color: #333 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 8px 8px 0;
    top: 100%;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    -webkit-transform: rotate(0deg)!important;
    -ms-transform: rotate(0deg)!important;
    transform: rotate(0deg)!important;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    width: 0;
    opacity: 0;
    margin: 10px -8px 0 -8px
}
#sns_content .sns_suggest .suggest-content .suggest-item:before {
    background: #333;
    top: 100%;
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    left: 0;
    position: absolute;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    opacity: 0;
    margin-top: 10px
}
#sns_content .sns_suggest .suggest-content .suggest-item:hover:after,
#sns_content .sns_suggest .suggest-content .suggest-item:hover:before {
    opacity: 1;
    margin-top: 0
}
#sns_content .products-index:hover .owl-controls {
    opacity: 1
}
#sns_content .products-index .owl-controls {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    position: static;
    z-index: 99;
    opacity: 0
}
#sns_content .products-index .owl-controls .owl-prev {
    margin: 0;
    left: 0;
    position: absolute;
    top: 32%
}
#sns_content .products-index .owl-controls .owl-next {
    margin: 0;
    right: 0;
    position: absolute;
    top: 32%
}
#sns_content .sns_banner1 {
    margin: 17px 0 52px
}
#sns_content .sns-products-list {
    margin-bottom: 25px;
    position: relative
}
#sns_content .sns-products-list .block-title {
    margin-bottom: 25px;
    position: relative;
    display: block
}
#sns_content .sns-products-list .block-title:before {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background: #eaeaea;
    content: "";
    left: 0;
    top: 9px;
    z-index: -1
}
#sns_content .sns-products-list .block-title h3 {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin: 0;
    display: inline-block;
    background: #fff;
    padding-right: 25px
}
#sns_content .sns-products-list .products-small {
    position: static
}
#sns_content .sns-products-list .products-small .owl-controls {
    margin: 0;
    position: absolute;
    top: -7px;
    background: #fff;
    right: 0;
    display: inline-block;
    z-index: 1;
    padding-left: 30px
}
#sns_content .sns-products-list .products-small .owl-controls .owl-nav .owl-prev {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea;
    margin-right: 10px
}
#sns_content .sns-products-list .products-small .owl-controls .owl-nav .owl-prev:before {
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
#sns_content .sns-products-list .products-small .owl-controls .owl-nav .owl-next {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea
}
#sns_content .sns-products-list .products-small .owl-controls .owl-nav .owl-next:before {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
#sns_content .sns-products-list .products-small .item-row {
    padding: 0 15px
}
#sns_content .sns-products-list .products-small .item {
    margin-bottom: 11px;
    display: inline-block
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-img {
    float: left;
    margin-right: 20px
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-img .product-image .img-main {
    border: 1px solid #eaeaea;
    display: inline-block;
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-img .product-image .img-main:hover {
    border: 1px solid var(--secondaryColor)
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-img .product-image .img-main img {
    width: 100px;
    height: 120px
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info {
    float: left
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .info-inner .item-title {
    margin-top: 8px
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .info-inner .item-title a {
    font-size: 13px;
    color: #666;
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .info-inner .item-title a:hover {
    color: var(--secondaryColor)
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .info-inner .item-price {
    margin-top: 5px
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .info-inner .item-price .price {
    font-size: 16px;
    color: #333;
    font-weight: 700
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .action-bot {
    margin-top: 10px
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    padding: 0;
    font-size: 12px;
    color: #888
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart i {
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s;
    display: inline-block;
    color: #888;
    font-size: 12px;
    position: relative;
    top: 0
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart span {
    -webkit-transition: all .2s ease 0s;
    transition: all .2s ease 0s;
    display: inline-block
}
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart:hover i,
#sns_content .sns-products-list .products-small .item .item-inner .prd .item-info .action-bot .wrap-addtocart .btn-cart:hover span {
    color: var(--secondaryColor)
}
#sns_content .index_block {
    margin-bottom: 50px
}
#sns_content .index_block.row20 {
    margin-bottom: 30px
}
#sns_content .index_block .block,
#sns_content .index_block.our_partners {
    margin-bottom: 0
}
#sns_botsl {
    margin-bottom: 30px
}
#sns_botsl2 {
    margin-bottom: 50px
}
#sns_botsl2 .block {
    margin: 0
}
#sns_partners {
    margin: 50px 0 100px ;
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 {
    border-top: 1px solid #eaeaea;
    padding-top: 20px;
    position: relative
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1:hover .owl-nav {
    opacity: 1
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .owl-nav {
    margin: 0;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    position: static!important;
    z-index: 99;
    opacity: 0
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .owl-prev {
    display: block!important;
    margin: 0;
    left: 0;
    position: absolute;
    top: 45%;
    overflow: hidden;
    height: 30px;
    width: 30px;
    border: 1px solid #eaeaea;
    background: #fff
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .owl-prev:before {
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .owl-prev:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .owl-prev:hover:before {
    color: #fff
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .owl-next {
    background: #fff;
    display: block!important;
    margin: 0;
    right: 0;
    position: absolute;
    top: 45%;
    overflow: hidden;
    height: 30px;
    width: 30px;
    border: 1px solid #eaeaea
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .owl-next:before {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 27px
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .owl-next:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .owl-next:hover:before {
    color: #fff
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .item a img {
    -webkit-transition: all .25s ease 0s;
    transition: all .25s ease 0s
}
#sns_partners .slider-wrap .partners_slider_in #partners_slider1 .item a img:hover {
    background: #F5F5F5
}
#sns_footer_top a {
    text-decoration: none;
    -webkit-transition: all 1s ease;
    transition: all 1s ease
}
#sns_footer_top a:hover {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    color: var(--secondaryColor)
}
#sns_footer_top .pd-right {
    padding-right: 50px
}
#sns_footer_top h3 {
    margin-bottom: 15px
}
#sns_footer_top .column {
    padding-bottom: 25px
}
#sns_footer_top .column ul li {
    line-height: 22px
}
#sns_footer_top ul {
    margin: 0;
    padding: 0;
    list-style: none
}
#sns_footer_top ul li {
    padding: 3px 0;
    color: #666
}
#sns_footer_top .fa-ul {
    padding-left: 20px
}
#sns_footer_top .fa-ul li .fa-li {
    margin-top: 0;
    font-size: 120%
}
#sns_footer_top {
    background: #F8F8F6
}
#sns_footer_top .container_in {
    padding-bottom: 10px
}
#sns_footer_top .container_in>.row>[class*=col-] {
    padding-bottom: 20px
}
#sns_footer_top h6 {
    color: #333;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase
}
#sns_footer_top .block-subscribe p {
    margin-bottom: 18px;
    padding-top: 5px
}
#sns_footer_top .block-subscribe .input-box {
    width: 100%
}
#sns_footer_top .block-subscribe .input-box .input_warp {
    display: block;
    width: 100%;
    margin-bottom: 10px
}
#sns_footer_top .block-subscribe .input-box .input_warp input {
    width: 100%;
    height: 36px;
    border: 1px solid #eaeaea!important;
    background: #fff;
    padding-left: 10px
}
#sns_footer_top .block-subscribe .input-box .input_warp::-webkit-input-placeholder {
    color: #000!important
}
#sns_footer_top .block-subscribe .input-box .input_warp:-moz-placeholder {
    color: #000!important
}
#sns_footer_top .block-subscribe .input-box .input_warp::-moz-placeholder {
    color: #000!important
}
#sns_footer_top .block-subscribe .input-box .input_warp:-ms-input-placeholder {
    color: #000!important
}
#sns_footer_top .block-subscribe .input-box .button_warp {
    text-align: left
}
#sns_footer_top .block-subscribe .input-box .button_warp button {
    font-size: 12px;
    line-height: 29px;
    color: #fff;
    height: 30px;
    text-align: center;
    padding: 0 15px;
    position: relative;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    background: #222;
    border-color: transparent;
    font-weight: 700;
    text-transform: uppercase
}
#sns_footer_top .block-subscribe .input-box .button_warp button:hover {
    color: #fff;
    background: var(--secondaryColor)
}
#sns_footer_top .bot-social {
    margin-bottom: 19px;
    display: inline-block;
    border-bottom: 1px solid #eaeaea
}
#sns_footer_top .sns-social ul {
    color: #ccc;
    list-style: outside none none;
    margin-top: 15px;
    padding: 0
}
#sns_footer_top .sns-social ul a {
    font-size: 18px;
    color: #ccc
}
#sns_footer_top .sns-social ul li {
    padding: 0;
    display: inline-block;
    vertical-align: middle
}
#sns_footer_top .sns-social ul li .fa {
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}
#sns_footer_top .sns-social ul li .fa:hover {
    color: var(--secondaryColor)
}
#sns_footer_top .contact_us .fa-ul {
    padding-left: 42px
}
#sns_footer_top .contact_us .fa-ul li {
    line-height: 20px;
    min-height: 40px;
    margin-bottom: 8px;
    color: #666
}
#sns_footer_top .contact_us .fa-ul .fa-li {
    width: 34px;
    height: 34px;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 100%;
    left: -45px;
    line-height: 30px;
    color: #888;
    font-size: 13px;
    top: 4px
}
#sns_footer_top .contact_us p {
    margin-bottom: 0
}
#sns_footer_bottom {
    padding: 14px 0 18px;
    color: #666;
    background: #fff
}
#sns_footer_bottom .sns-copyright {
    margin: 0;
    padding-top: 12px
}
#sns_footer_bottom .sns-copyright a {
    color: #666
}
#sns_footer_bottom .sns-copyright a:hover {
    color: var(--secondaryColor)
}
#sns_footer_bottom .payment {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-top: 8px;
    text-align: right
}
#sns_footer_bottom .payment li {
    display: inline-block;
    vertical-align: middle;
    padding-left: 5px
}
#sns_footer_bottom .payment li a {
    font-size: 25px;
    color: #fff
}
#sns_footer_bottom .payment li a:hover {
    color: #5492db
}
#sns_footer_map {
    position: relative
}
#sns_footer_map .btn_gmap {
    background: var(--secondaryColor);
    display: block;
    font-size: 100%;
    left: 50%;
    margin-left: -80px;
    padding: 8px 20px 8px 15px;
    position: absolute;
    text-transform: uppercase;
    top: -15px;
    z-index: 1;
    color: #fff;
    font-weight: 700;
    border-radius: 4px
}
#sns_footer_map .btn_gmap:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: var(--secondaryColor) transparent transparent;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-left: -6px;
    opacity: 1;
    filter: alpha(opacity=100)
}
#sns_footer_map .btn_gmap:before {
    content: '\f124';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: #fff;
    padding-right: 10px;
    z-index: 2
}
#sns_footer_map .btn_gmap:hover {
    background: #5492db
}
#sns_footer_map .btn_gmap:hover:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: #5492db transparent transparent
}
#sns_header_logo .responsv {
    padding-top: 0;
    margin-top: 22px
}
#sns_header_logo .responsv a {
    font-family: Intro;
    font-size: 45px;
    font-weight: 400;
    color: #333
}
#sns_header_logo .responsv:hover a {
    color: var(--secondaryColor)
}
.policy .policy_custom {
    padding: 40px 10px 30px 52px;
    text-align: left;
    position: relative;
    margin-bottom: 7px
}
.policy .policy_custom .policy-titile {
    font-size: 14px;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 2px
}
.policy .policy_custom .policy-ct {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    color: #666
}
.policy .policy_custom .policy-icon {
    position: absolute;
    left: 0;
    top: 38px;
    width: 42px;
    height: 42px;
    text-align: center;
    border-radius: 100%;
    overflow: hidden;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background: #eaeaea
}
.policy .policy_custom .policy-icon:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    background: var(--secondaryColor);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 2
}
.policy .policy_custom .policy-icon em {
    font-size: 16px;
    line-height: 40px;
    color: #666;
    display: inline-block;
    position: relative;
    z-index: 3;
    text-align: center;
    padding-left: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.policy .policy_custom:hover {
    cursor: pointer
}
.policy .policy_custom:hover .policy-ct,
.policy .policy_custom:hover .policy-titile {
    color: #000
}
.policy .policy_custom:hover .policy-icon:before {
    height: 100%;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.policy .policy_custom:hover .policy-icon em {
    color: #fff
}
#sns_menu {
    min-height: 50px;
    position: relative
}
#sns_menu .sns_mainmenu {
    position: relative;
    min-height: 56px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-image: url(../images/border.png);
    background-repeat: repeat-x
}
#sns_menu .sns_mainmenu:before {
    background-image: url(../images/border.png);
    background-repeat: repeat-x;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    position: absolute;
    content: ""
}
#sns_menu #sns_mainnav {
    float: left
}
#sns_menu .sns_menu_right {
    float: right
}
#sns_menu .sns_menu_right .block_topsearch {
    display: inline-block
}
#sns_menu .sns_menu_right .block_topsearch .top-search {
    position: absolute;
    background: #fff;
    top: 3px;
    left: 0;
    width: 100%;
    line-height: 40px;
    height: 50px;
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all .05s ease;
    transition: all .05s ease
}
#sns_menu .sns_menu_right .block_topsearch .top-search:hover {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1
}
#sns_menu .sns_menu_right .block_topsearch .icon-search {
    float: right;
    line-height: 50px
}
#sns_menu .sns_menu_right .block_topsearch .icon-search:before {
    content: '\f002';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    position: relative;
    bottom: 1px;
    left: 2px
}
#sns_menu .sns_menu_right .block_topsearch .icon-search:hover:before {
    color: var(--secondaryColor)
}
#sns_menu .sns_menu_right .block_topsearch .icon-search:hover+.top-search {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1
}
#sns_menu .sns_menu_right .block_topsearch .top-cart {
    float: right
}
.mycart {
    float: right;
    position: relative;
    line-height: 50px;
    padding-left: 16px
}
.mycart:before {
    position: absolute;
    content: "";
    background: #eaeaea;
    width: 1px;
    height: 21px;
    top: 14px;
    left: 0
}
.mycart:hover .tongle {
    cursor: pointer
}
.mycart:hover .tongle .fa:after {
    opacity: 1;
    filter: alpha(opacity=100)
}
.mycart:hover .content {
    display: block;
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    top: 100%
}
.mycart .tongle {
    color: #666!important
}
.mycart .tongle span {
    font-size: 12px
}
.mycart .tongle .total-itemprice span {
    color: #333
}
.mycart .tongle .summary .amount span {
    color: var(--secondaryColor)
}
.mycart .tongle .summary .subtotal {
    display: none
}
.mycart .tongle .summary .price {
    color: #333
}
.mycart .tongle .fa {
    position: relative;
    color: #666!important;
    margin-right: 10px;
    float: left;
    font-size: 14px;
    line-height: 31px
}
.mycart .tongle .fa:before {
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    display: block;
    text-align: center
}
.mycart .tongle .fa:after {
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-left: -6px;
    opacity: 0;
    filter: alpha(opacity=0)
}
.mycart .tongle .summary {
    display: inline-block
}
.mycart .tongle .summary span a {
    color: #666
}
.mycart .tongle .summary .subtotal span.price {
    color: #333
}
.mycart .content {
    position: absolute;
    right: 0;
    min-width: 300px;
    background: #fff;
    z-index: 9999;
    padding: 20px 20px 5px;
    border: 1px solid #eaeaea;
    visibility: hidden;
    display: block;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 120%
}
.mycart .content:after,
.mycart .content:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}
.mycart .content:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff
}
.mycart .content:before {
    border-color: rgba(234, 234, 234, 0);
    border-bottom-color: #eaeaea
}
.mycart .content:after {
    border-width: 10px;
    margin-left: -10px;
    right: 21px!important;
    left: inherit!important
}
.mycart .content:before {
    border-width: 11px;
    margin-left: -11px;
    left: inherit!important;
    right: 20px!important
}
.mycart .content .empty {
    margin-bottom: 0;
    padding-left: 15px;
    line-height: 20px
}
.mycart .content #cart-sidebar {
    border-bottom: 1px solid #eaeaea;
    padding: 0;
    padding-bottom: 3px;
    margin-bottom: 16px
}
.mycart .content #cart-sidebar li {
    list-style: none;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 20px;
    border-top: 0
}
.mycart .content #cart-sidebar li:first-child {
    margin-top: 0
}
.mycart .content #cart-sidebar li .product-image {
    display: inline-block;
    float: left;
    margin-right: 10px
}
.mycart .content #cart-sidebar li .product-image img {
    border: 1px solid #eaeaea;
    width: 90px
}
.mycart .content #cart-sidebar li .product-details {
    display: inline-block;
    line-height: 20px
}
.mycart .content #cart-sidebar li .product-details>span,
.mycart .content #cart-sidebar li .product-details>strong {
    line-height: 20px
}
.mycart .content #cart-sidebar li .product-details .btn-edit {
    border: 0;
    position: absolute;
    top: 5px;
    right: 0;
    display: none
}
.mycart .content #cart-sidebar li .product-details .btn-remove {
    border: 0;
    position: absolute;
    top: -4px;
    right: 0
}
.mycart .content #cart-sidebar li .product-details .btn-remove:before {
    content: '\f057';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 14px
}
.mycart .content #cart-sidebar li .product-details .product-name {
    color: #333;
    padding-right: 25px;
    margin-bottom: 3px;
    line-height: 20px;
    margin-top: -2px;
    font-size: 13px
}
.mycart .content #cart-sidebar li .product-details .product-name a {
    line-height: 20px
}
.mycart .content #cart-sidebar li .product-details .product-name a:hover {
    color: var(--secondaryColor)
}
.mycart .content #cart-sidebar li .product-details .price {
    font-size: 16px;
    font-weight: 700;
    color: #333
}
.mycart .content .cart-subtotal {
    text-align: right;
    margin-bottom: 0;
    padding: 0;
    color: #333;
    line-height: 30px
}
.mycart .content .cart-subtotal .price {
    color: #333;
    font-size: 16px;
    font-weight: 700
}
.mycart .content .cart-subtotal .label {
    padding-right: 0;
    margin-right: 2px;
    color: #666;
    font-size: 14px
}
.mycart .content .actions {
    text-align: right;
    margin-bottom: 5px;
    margin-top: 13px;
    border-top: 1px solid #eaeaea;
    padding-top: 10px
}
.mycart .content .actions a {
    cursor: pointer;
    text-align: center;
    display: inline-block;
    margin-top: 10px;
    height: 35px;
    text-transform: uppercase;
    font-weight: 700;
    border: 1px solid #eaeaea;
    width: 100%;
    line-height: 35px;
    background: #fff
}
.mycart .content .actions a .fa {
    margin-right: 5px
}
.mycart .content .actions a:hover {
    background: var(--secondaryColor);
    color: #fff;
    border: 1px solid var(--secondaryColor)
}
.mycart .content .actions a:hover a {
    color: #fff
}
.mycart .content .actions a.go-to-cart {
    margin-right: 10px
}
.mycart .content ul {
    margin: 0;
    padding: 0;
    list-style: none
}
.mycart .content ul li {
    padding: 4px 0
}
#sns_header.style_v2 #sns_header_logo .sns_menu_right {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-top: 38px;
    padding-bottom: 38px
}
#sns_header.style_v2 #sns_header_logo .sns_menu_right .top-cart {
    float: right
}
#sns_header.style_v2 #sns_header_logo .sns_menu_right .top-search {
    float: left
}
#sns_header.style_v2 #sns_header_logo .sns_menu_right .top-search .form-search {
    position: relative
}
#sns_header.style_v2 #sns_header_logo .sns_menu_right .top-search .form-search input {
    height: 35px;
    line-height: 33px;
    border: 1px solid #eaeaea;
    padding-right: 40px;
    min-width: 350px
}
#sns_header.style_v2 #sns_header_logo .sns_menu_right .top-search .form-search button {
    font-size: 0;
    line-height: 33px;
    height: 33px
}
#sns_header.style_v2 #sns_header_logo .sns_menu_right .top-search .form-search button:before {
    content: '\f002';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 33px;
    height: 33px;
    text-align: center;
    background: 0 0;
    font-size: 13px;
    line-height: 33px;
    top: 0;
    left: 0
}
#sns_header.style_v2 .top-cart .mycart {
    line-height: 33px
}
#sns_header.style_v2 .top-cart .mycart:before {
    display: none
}
#sns_header.style_v2 .top-cart .mycart .fa {
    line-height: 30px;
    font-size: 16px
}
#sns_header.style_v2 .top-cart .mycart .tongle {
    position: relative
}

.main-offer{
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;

    .main-offer-slider{
        overflow-x: hidden;
    }
    .grid{
        grid-template-columns: 1fr 1fr;
        display: grid;
        gap: 15px;
    }
    .flex{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .owl-carousel, .owl-carousel .owl-stage-outer, .owl-carousel .owl-stage, .owl-carousel.owl-drag .owl-item, .item{
        height: 100%;
    }
    .item-text{
        position: absolute;
        bottom: 80px;
        z-index: 1;
        padding: 0 60px 0 23px;
        color: #fff;
    }
    .item-title{
        font-weight: 700;
        font-size: 36px;
        line-height: 109.5%;
        margin-bottom: 8px;
    }
    img {
        z-index: 0;
        height: 100%;
    }

    .main-offer-slider .owl-dots {
        bottom: 6px;
        display: inline-block;
        left: 50%;
        margin: 0 -39px;
        position: absolute;

        .owl-dot {
            display: inline-block;
            
            span {
                height: 14px;
                width: 14px;
                background: #fff;
                border: 2px solid #000;
                border-radius: 0;
                margin: 0 5px;
                cursor: pointer;
                -webkit-transition: all .2s linear;
                transition: all .2s linear;
                opacity: 1!important;
                display: block;
            }
        }
        .owl-dot.active span, .owl-dot:hover span{
            background: var(--secondaryColor);
            border: 2px solid var(--secondaryColor)
        }
    }
}

#header-slideshow .banner-right .banner6 {
    padding: 0
}
#header-slideshow .banner-right .pdno {
    margin-top: 30px
}
#header-slideshow .banner-right .banner7 {
    padding-left: 0
}
#header-slideshow .banner-right .banner7:last-child {
    padding-left: 15px;
}
#header-slideshow .banner-right .banner7 .style-italic {
    display: inline-block;
    margin: 0 -89px;
    position: absolute;
    left: 50%;
    top: 20.5%
}
#header-slideshow .banner-right .banner7 .style-italic .style-color1 {
    display: inline-block;
    font-size: 30px;
    color: #333;
    text-transform: uppercase;
    margin-right: 5px
}
#header-slideshow .banner-right .banner7 .style-italic .style-color2 {
    display: inline-block;
    font-size: 30px;
    color: #61add9;
    font-weight: 700;
    text-transform: uppercase
}
#header-slideshow .banner-right .banner8 {
    padding-left: 15px;
    padding-right: 0
}
#header-slideshow .banner-right .banner8 .style-sales {
    display: inline-block;
    position: absolute;
    left: 60%;
    top: 24%;
    font-size: 26px;
    color: #c4b498;
    border-radius: 50%;
    font-weight: 700;
    background: #fff;
    width: 80px;
    height: 80px;
    line-height: 83px;
    text-align: center;
    -webkit-box-shadow: 0 5px 0 rgba(196, 180, 152, .63);
    box-shadow: 0 5px 0 rgba(196, 180, 152, .63)
}

#sns_slideshow_home2 .row {
    margin: 0 -5px
}
#sns_slideshow_home2 .row>div {
    padding: 0 5px
}
#sns_slideshow_home2 .row .sns_banner1 .row>div {
    margin-bottom: 10px
}
#sns_slideshow_home2 .row .sns_banner1 .row>div:last-child {
    margin-bottom: 0
}
#sns_slideshow_home2 .navslider {
    display: none!important
}
.banner_home1 {
    padding-top: 85px;
    padding-bottom: 95px;
    background-repeat: no-repeat;
    background-position: center
}
.banner_home1 .module .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #333
}
.banner_home1 .module .content {
    color: #666;
    font-size: 12px
}
.banner_home1 .module .active {
    margin-top: 20px
}
.banner_home1 .module .active button {
    margin-right: 10px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px
}
.bottom_content {
    padding-top: 50px;
    padding-bottom: 50px
}
.bottom_content.homev3 {
    padding-bottom: 0;
    padding-top: 40px
}
.banner_home2 {
    text-align: center;
    margin-bottom: 15px
}
.banner_home2 .row>div {
    margin-bottom: 20px
}
.banner_home2 .module {
    padding: 5px 80px 35px
}
.banner_home2 .module .title {
    color: #333;
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 5px
}
.banner_home2 .module .title p {
    margin-bottom: 0
}
.banner_home2 .module .title span {
    font-weight: 700
}
.banner_home2 .module .content {
    margin-bottom: 0
}
.home1_slide {
    padding-top: 50px;
    padding-bottom: 50px
}
.home1_slide .block {
    margin-bottom: 0
}
#sns_slideshow_top .sns_slideshow>.container {
    display: none
}
#sns_slideshow_v2 .sns_slideshow>.container {
    display: block;
    position: relative;
    z-index: 9
}
#sns_slideshow_v2 .sns_slideshow .navslider {
    position: absolute;
    bottom: 10px;
    right: 15px;
    height: 40px
}
#sns_slideshow_v2 .sns_slideshow .navslider a {
    float: right;
    margin-left: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    background: var(--secondaryColor);
    padding-top: 8px;
    line-height: 12px
}
#sns_slideshow_v2 .sns_slideshow .navslider a:before {
    float: left;
    width: 100%;
    font-family: FontAwesome;
    color: #fff;
    font-size: 16px;
    font-weight: 700
}
#sns_slideshow_v2 .sns_slideshow .navslider a.prev:before {
    content: ""
}
#sns_slideshow_v2 .sns_slideshow .navslider a.next:before {
    content: ""
}
#sns_slideshow_v2 .sns_slideshow .navslider a:hover {
    opacity: .7
}
#sns_slideshow_v2 .sns_slideshow .owl-controls .owl-nav {
    display: block
}
#sns_slideshow_v2 .sns_slideshow .owl-controls .owl-dots {
    display: none!important
}
.banner_stylev2 {
    margin-bottom: 20px
}
.banner_stylev2 a {
    margin-bottom: 29px
}
.partnerv2 #sns_partners .sns_module {
    background: 0 0
}
.home3_slide {
    padding-top: 40px;
    padding-bottom: 40px
}
.home3_slide .style_slide_v2:first-child {
    margin-bottom: 45px
}
.home3_slide .style_slide_v2.block .block-title,
.simev3_banner_top {
    margin-bottom: 20px
}
.simev3_banner_top a {
    margin-bottom: 25px
}
.cms-simen-home-page-v3 .sns-producttabs {
    margin-bottom: 20px
}
.sns-producttabs .sns-pdt-head.style_v3 {
    margin-bottom: 13px
}
.sns-producttabs .sns-pdt-head.style_v3 h2 {
    float: left;
    margin-bottom: 0
}
.sns-producttabs .sns-pdt-head.style_v3 .sns-pdt-nav {
    float: right
}
.sns-producttabs .sns-pdt-head.style_v3 .sns-pdt-nav .pdt-nav>span {
    border: 0;
    margin-left: 5px;
    margin-right: 0;
    padding-left: 15px;
    position: relative
}
.sns-producttabs .sns-pdt-head.style_v3 .sns-pdt-nav .pdt-nav>span:before {
    position: absolute;
    content: "/";
    top: 0;
    left: 0;
    color: #cfcfcf;
    font-size: 14px;
    font-weight: 400
}
.sns-producttabs .sns-pdt-head.style_v3 .sns-pdt-nav .pdt-nav>span:first-child:before {
    display: none
}
.sns-producttabs .sns-pdt-head.style_v3 .sns-pdt-nav .pdt-nav>span>span {
    border: 0;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: #333
}
.sns-producttabs .sns-pdt-head.style_v3 .sns-pdt-nav .pdt-nav>span.active span,
.sns-producttabs .sns-pdt-head.style_v3 .sns-pdt-nav .pdt-nav>span:hover span,
.sns-producttabs .sns-pdt-head.style_v3 .sns-pdt-nav .pdt-nav>span>span:hover {
    border: 0
}
.block-blog-inner .block-title {
    margin-bottom: 25px!important
}
.block-blog-inner ul {
    list-style: none;
    padding: 19px 15px 5px 0
}
.block-blog-inner ul li {
    margin-bottom: 12px
}
.block-blog-inner ul li span {
    border: 1px solid #cdcdcd;
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    line-height: 31px;
    text-align: center;
    width: 30px;
    margin-right: 9px
}
.block-blog-inner ul li:hover,
.block-blog-inner ul li:hover a {
    color: var(--secondaryColor)
}
.block-blog-inner ul li:hover span {
    border: 1px solid var(--secondaryColor)
}
.block-blog-inner .block-content {
    padding: 0!important;
    border: 0!important
}
.blogs-page .page-title,
.blogs-page .page-title h1 {
    margin-bottom: 0
}
.blogs-page .postWrapper {
    margin-top: 20px
}
.blogs-page .postWrapper .postTitle h2 {
    font-size: 123%
}
.blogs-page .postWrapper .postTitle h3 {
    font-size: 89%
}
.blogs-page .postContent img {
    margin-bottom: 10px
}
.blogs-page .post-info {
    padding-top: 10px
}
.blogs-page .post-info .postDetails {
    margin-bottom: 10px
}
.block-blog-inner {
    border: 0!important;
    padding: 0!important
}
.block-blog-inner ul {
    border: 0;
    padding-left: 0;
    margin-bottom: 0!important
}
.block-blog-inner .menu-categories p {
    margin: 0
}
.block-blog-inner .menu-categories p span {
    text-transform: uppercase;
    display: inline-block
}
.block-blog-inner .menu-categories p i {
    color: #333;
    display: inline-block;
    left: 5px;
    position: relative!important;
    top: 0!important
}
.block-blog-inner .menu-categories p:hover {
    cursor: pointer;
    color: var(--secondaryColor)
}
.block-blog-inner .menu-categories p:hover i {
    color: var(--secondaryColor)
}
.block-blog-inner .block-content>div {
    padding: 14px 20px 15px;
    border: 1px solid #eaeaea;
    margin-bottom: 30px
}
.block-blog-inner .block-content>div:last-child {
    margin-bottom: 0
}
.block-blog-inner .block-content>div .block-title {
    margin-bottom: 0!important
}
.tabs-slidev2 .owl-controls {
    position: absolute;
    top: -44px;
    right: 10px;
    margin-right: 0!important;
    width: 62px;
    height: 30px;
    background: #fff;
    z-index: 3
}
.tabs-slidev2 .owl-controls:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 27px;
    background: #eaeaea;
    z-index: 2;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.tabs-slidev2 .owl-controls:hover:before {
    background: var(--secondaryColor)
}
.tabs-slidev2 .owl-controls:after {
    position: absolute;
    width: 20px;
    height: 100%;
    background: #fff;
    content: "";
    left: -21px;
    z-index: 1
}
.tabs-slidev2 .owl-controls .owl-nav>div {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    float: left;
    text-align: center;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border: 1px solid #eaeaea
}
.tabs-slidev2 .owl-controls .owl-nav>div i {
    text-align: center;
    line-height: 28px;
    font-size: 16px;
    color: #cacaca;
    background: 0 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}
.tabs-slidev2 .owl-controls .owl-nav>div i:hover {
    color: #fff
}
.tabs-slidev2 .owl-controls .owl-nav>div:hover {
    background: var(--secondaryColor);
    color: #fff;
    border-color: var(--secondaryColor)
}
.tabs-slidev2 .owl-controls .owl-nav>div:hover 1 {
    color: #fff
}
.tabs-slidev2 .owl-controls .owl-nav>div.owl-next {
    border-left: 0
}
.tabs-slidev2 .owl-controls .owl-nav>div.owl-prev {
    border-right: 0
}
.tabs-slidev2 .sns-pdt-nav {
    padding-right: 95px
}
.cms-simen-home-page-v2.cmspage #sns_content {
    margin-bottom: 15px
}
.cms-simen-home-page-v2.cms-index-index #sns_breadcrumbs {
    padding-bottom: 15px
}
.cms-simen-home-page-v2.cms-index-index .btn2.btn-navbar.leftsidebar {
    display: none!important
}
.product-view.sns-product-detail .banner_detail_right {
    display: none
}
.catalog-product-view .product-view.sns-product-detail .banner_detail_right {
    display: block
}
.footer_style #sns_footer_top {
    padding-top: 42px;
    padding-bottom: 14px
}
#header-slideshow #sns_header_bot #sns_slideshow_top .sns_slideshow {
    position: relative;
    overflow: hidden
}
.catalog-product-view #sns_content #sns_main {
    position: static
}
.customer-account-create #form-validate .form-list .control input#is_subscribed {
    height: 15px
}
#sns_header .sns_header_top .mysetting .content::after,
#sns_header .sns_header_top .mysetting .content::before {
    left: 13px
}
#sns_header .sns_header_top .mysetting.language-switcher .content::after,
#sns_header .sns_header_top .mysetting.language-switcher .content::before {
    left: 39px
}
.sns-producttabs .sns-pdt-head.style_v3 h2 {
    font-family: Titillium Web;
    font-size: 22px
}
#super-product-table {
    margin-top: 20px
}
.sns-producttabs .sns-pdt-container .item .item-inner.tabs_icov2 {
    overflow: inherit
}
.sns-producttabs .sns-pdt-container .item .item-inner.tabs_icov2 .prd .tabs_ico {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 101%;
    overflow: hidden;
    height: 80px
}
.sns-contact-us #sns_header {
    margin-bottom: 7px
}
.sns-contact-us #sns_content {
    margin-bottom: 27px!important
}
.sns-contact-us #contact_gmap .page-title h1 {
    font-size: 20px;
    color: #333;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 18px
}
.sns-contact-us #contact_gmap #googleMap {
    margin-bottom: 27px!important
}
.sns-contact-us #contact_gmap .row.clearfix {
    color: #666
}
.sns-contact-us #contact_gmap .row.clearfix ul.fa-ul {
    margin: 24px 0 0;
    padding: 0 0 0 20px
}
.sns-contact-us #contact_gmap .row.clearfix ul.fa-ul li {
    line-height: 22px
}
.sns-contact-us #contact_gmap .row.clearfix ul.fa-ul i {
    margin-top: 0;
    font-size: 12px;
    color: #666
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 .style1 {
    margin-bottom: 16px
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .buttons-set button {
    background: 0 0;
    border: 1px solid #eaeaea;
    width: 115px;
    height: 39px
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .buttons-set button span {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    position: relative;
    top: 1px
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .buttons-set button:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .buttons-set button:hover span {
    color: #fff
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .form-group {
    margin-bottom: 18px
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .form-group input {
    height: 40px;
    line-height: 40px
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .form-group textarea {
    height: 98px
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .form-group input,
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .form-group textarea {
    border-radius: 0;
    border: 1px solid #eaeaea;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, .075) inset;
    box-shadow: 0 0 0 rgba(0, 0, 0, .075) inset
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .form-group::-webkit-input-placeholder {
    color: #666!important;
    font-size: 12px
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .form-group:-moz-placeholder {
    color: #666!important;
    font-size: 12px
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .form-group::-moz-placeholder {
    color: #666!important;
    font-size: 12px
}
.sns-contact-us #contact_gmap .row.clearfix .col-md-8 #contactForm .form-group:-ms-input-placeholder {
    color: #666!important;
    font-size: 12px
}
.owl-controls .owl-prev:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)!important;
    color: #fff!important
}
.owl-controls .owl-prev:hover:before {
    color: #fff!important
}
.owl-controls .owl-next:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)!important;
    color: #fff!important
}
.owl-controls .owl-next:hover:before {
    color: #fff!important
}
.show-sidebar1 #sns_menu .btn_accor i {
    color: #999;
    top: 0
}
.cms-index-index2 #sns_content #header-slideshow {
    margin-bottom: 53px
}
.cms-index-index2 #sns_content #header-slideshow .pdno {
    margin: 0
}
.cms-index-index2 #sns_content #header-slideshow .pdno .banner8 {
    padding-left: 0!important;
    margin-top: 30px
}
.cms-index-index2 #sns_content #sns_producttaps1 {
    margin-bottom: 26px
}
.cms-index-index2 #sns_content .sns-slider-wraps,
.cms-index-index2 #sns_content .sns_producttaps_wraps {
    position: relative
}
.cms-index-index2 #sns_content .sns-slider-wraps h3.precar,
.cms-index-index2 #sns_content .sns_producttaps_wraps h3.precar {
    text-transform: uppercase;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin-bottom: 16px;
    margin-top: 0
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs {
    padding-top: 5px;
    display: inline-block;
    margin: 0;
    float: right
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs:before,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs:before {
    display: none
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs li,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs li {
    position: relative;
    padding: 0 15px
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs li.active a,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs li.active a {
    -webkit-transform: none!important;
    -ms-transform: none!important;
    transform: none!important;
    color: var(--secondaryColor)
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs li:after,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs li:after {
    content: "/";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    color: #ccc;
    background: 0 0
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs li:last-child,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs li:last-child {
    padding-right: 100px
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs li:last-child:after,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs li:last-child:after {
    display: none
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs li a,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs li a {
    font-size: 12px;
    color: #666
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs li a:after,
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs li a:before,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs li a:after,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs li a:before {
    display: none
}
.cms-index-index2 #sns_content .sns-slider-wraps ul.nav-tabs li a:hover,
.cms-index-index2 #sns_content .sns_producttaps_wraps ul.nav-tabs li a:hover {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: var(--secondaryColor)
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content {
    margin: 0
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content .detai-products1,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content .detai-products1 {
    position: static!important
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content .tab-pane,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content .tab-pane {
    margin: 0 -10px
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content .tab-pane .products-grid,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content .tab-pane .products-grid {
    margin: 0;
    position: static
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content .tab-pane .products-grid .owl-stage-outer,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content .tab-pane .products-grid .owl-stage-outer {
    position: static!important
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content .tab-pane .products-grid .owl-controls,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content .tab-pane .products-grid .owl-controls {
    margin: 0;
    position: absolute;
    top: -5px;
    background: #fff;
    right: 0;
    display: inline-block;
    z-index: 1;
    padding-left: 30px
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content .tab-pane .products-grid .owl-controls .owl-nav .owl-prev,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content .tab-pane .products-grid .owl-controls .owl-nav .owl-prev {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea;
    margin-right: 10px
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content .tab-pane .products-grid .owl-controls .owl-nav .owl-prev:before,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content .tab-pane .products-grid .owl-controls .owl-nav .owl-prev:before {
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 28px
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content .tab-pane .products-grid .owl-controls .owl-nav .owl-next,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content .tab-pane .products-grid .owl-controls .owl-nav .owl-next {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea
}
.cms-index-index2 #sns_content .sns-slider-wraps .tab-content .tab-pane .products-grid .owl-controls .owl-nav .owl-next:before,
.cms-index-index2 #sns_content .sns_producttaps_wraps .tab-content .tab-pane .products-grid .owl-controls .owl-nav .owl-next:before {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 28px
}
.cms-index-index2 #sns_content .sns_banner_page2 {
    margin-bottom: 53px
}
.cms-index-index2 #sns_content .sns-slider-wraps {
    margin-bottom: 21px
}
.cms-index-index2 #sns_content .sns_banner2 {
    margin-bottom: 52px
}
.cms-index-index2 #sns_content .row.bottom {
    margin-top: 0
}
.cms-index-index2 #sns_content .row.bottom .sns_banner3 {
    margin-bottom: 52px
}
.cms-index-index2 #sns_content .row.bottom .products-index {
    margin-bottom: 2px
}
.header-style3.sns-contact-us #sns_breadcrumbs,
.header-style3.sns-products-detail1 #sns_breadcrumbs {
    margin-top: 10px
}
.header-style3 #sns_header {
    position: static;
    margin-bottom: 0
}
.header-style3 #sns_header #sns_header_full {
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}
.header-style3 #sns_header .sns_header_top {
    background: 0 0;
    z-index: 3;
    border-bottom: 1px solid rgba(255, 255, 255, .19999999999999996)
}
.header-style3 #sns_header .sns_header_top .sns_module .module-setting .mysetting .tongle {
    color: #fff
}
.header-style3 #sns_header .sns_header_top .sns_module .module-setting .mysetting .tongle:before {
    background: rgba(255, 255, 255, .19999999999999996)
}
.header-style3 #sns_header .sns_header_top .sns_module .header-account .myaccount .tongle {
    color: #fff
}
.header-style3 #sns_header .sns_header_top .sns_module .header-account .myaccount ul.links li {
    cursor: pointer;
    margin-left: 0;
    padding-right: 10px;
    border-left: 1px solid rgba(255, 255, 255, .19999999999999996)
}
.header-style3 #sns_header .sns_header_top .sns_module .header-account .myaccount ul.links li a {
    color: #fff
}
.header-style3 #sns_header .sns_header_top .sns_module .header-account .myaccount ul.links li:last-child {
    border-right: 1px solid rgba(255, 255, 255, .19999999999999996)
}
.header-style3 #sns_header .sns_header_top .sns_module .header-account .myaccount ul.links li:hover {
    background: rgba(0, 0, 0, .09999999999999998)
}
.header-style3 #sns_header #sns_menu {
    z-index: 2
}
.header-style3 #sns_header #sns_menu #sns_header_logo {
    padding-left: 13px
}
.header-style3 #sns_header #sns_menu #sns_header_logo h1 {
    padding: 22px 0 20px
}
.header-style3 #sns_header #sns_menu #sns_header_logo .responsv1 {
    display: none
}
.header-style3 #sns_header #sns_menu .sns_mainmenu {
    text-align: right;
    background: 0 0;
    padding-top: 35px;
    padding-right: 0;
    margin-right: 15px;
    float: right
}
.header-style3 #sns_header #sns_menu .sns_mainmenu:before {
    display: none
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_mainnav {
    display: inline-block;
    float: none!important
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav li.level0>div {
    right: 15px!important;
    left: inherit
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav li.level0>div.fullwidth {
    top: 100%!important
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav li.level0:hover>div.fullwidth {
    top: 90%!important
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu {
    margin-right: 20px
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav .level0.nav-1.no-group.first.drop-submenu.parent:after,
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav .level0.nav-1.no-group.first.drop-submenu.parent:before {
    display: none!important
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav li.active a.menu-title-lv0:hover span:after {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px)
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav li.active a.menu-title-lv0 span:after {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    content: '';
    opacity: 1!important
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav li a.menu-title-lv0 {
    color: #fff
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav li a.menu-title-lv0 span {
    position: relative
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav li a.menu-title-lv0 span:after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #fff;
    content: '';
    opacity: 0;
    -webkit-transition: height .3s, opacity .3s, -webkit-transform .3s;
    transition: height .3s, opacity .3s, transform .3s;
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px)
}
.header-style3 #sns_header #sns_menu .sns_mainmenu #sns_custommenu ul.mainnav li a.menu-title-lv0:hover span:after {
    height: 2px;
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px)
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-search {
    top: 35px;
    margin-right: 15px
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart {
    line-height: inherit;
    padding-left: 10px;
    padding-right: 14px
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart:before {
    position: absolute;
    content: "";
    background: rgba(255, 255, 255, .19999999999999996);
    width: 1px;
    height: 12px;
    top: 17px;
    left: 0
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart:hover .content {
    top: 50px
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart .content {
    top: 65px
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart .block-minicart .tongle i {
    font-size: 12px;
    color: #fff!important
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart .block-minicart .tongle .summary a {
    position: absolute;
    top: 2px;
    right: 6px
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart .block-minicart .tongle .summary a span {
    color: #222;
    background: #fff;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0 6px
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .icon-search {
    color: #fff;
    font-size: 12px
}
.header-style3 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .icon-search:before {
    color: #fff;
    font-size: 12px;
    width: 35px;
    height: 35px
}
.header-style3 #sns_header #sns_slideshows3 {
    z-index: 0
}
.header-style3 #sns_header #sns_slideshows3:hover .owl-controls .owl-nav .owl-next,
.header-style3 #sns_header #sns_slideshows3:hover .owl-controls .owl-nav .owl-prev {
    opacity: 1
}
.header-style3 #sns_header #sns_slideshows3 img {
    max-width: 100%!important
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls {
    margin: 0
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-nav .owl-prev {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
    display: inline-block!important;
    margin-top: -30px;
    position: absolute;
    top: 50%;
    left: 20px;
    overflow: hidden;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    line-height: 57px;
    background: rgba(255, 255, 255, .09999999999999998);
    border: 0!important
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-nav .owl-prev:before {
    content: '\f104';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 60px;
    height: 60px;
    text-align: center;
    color: #fff;
    font-size: 40px;
    border: 0!important;
    line-height: 57px
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-nav .owl-prev:hover {
    background: var(--secondaryColor)
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-nav .owl-prev:hover:before {
    color: #fff;
    border: 0!important
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-nav .owl-next {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
    background: rgba(255, 255, 255, .09999999999999998);
    display: inline-block!important;
    margin-top: -30px;
    position: absolute;
    top: 50%;
    right: 20px;
    overflow: hidden;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    line-height: 57px;
    border: 0!important
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-nav .owl-next:before {
    content: '\f105';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 60px;
    height: 60px;
    text-align: center;
    color: #fff;
    font-size: 40px;
    border: 0!important;
    line-height: 57px
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-nav .owl-next:hover {
    background: var(--secondaryColor)
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-nav .owl-next:hover:before {
    color: #fff;
    border: 0!important
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-dots {
    bottom: 66px;
    display: inline-block;
    left: 50%;
    margin: 0 -39px;
    position: absolute
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-dots .owl-dot {
    display: inline-block
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-dots .owl-dot span {
    height: 14px;
    width: 14px;
    background: 0 0;
    border: 2px solid #fff;
    border-radius: 0;
    margin: 0 5px;
    cursor: pointer;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    opacity: 1!important;
    display: block
}
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-dots .owl-dot.active span,
.header-style3 #sns_header #sns_slideshows3 .owl-controls .owl-dots .owl-dot:hover span {
    background: #fff;
    border: 2px solid #fff
}
.cms-index-index3.blog-pagev1 #sns_menu .btn2.btn-navbar.leftsidebar,
.cms-index-index3.products-grid1 #sns_menu .btn2.btn-navbar.leftsidebar,
.cms-index-index3.products-list1 #sns_menu .btn2.btn-navbar.leftsidebar {
    display: inline-block!important;
    margin-right: 10px!important
}
.cms-index-index3.blog-pagev1 #sns_menu .btn2.btn-navbar.leftsidebar i,
.cms-index-index3.products-grid1 #sns_menu .btn2.btn-navbar.leftsidebar i,
.cms-index-index3.products-list1 #sns_menu .btn2.btn-navbar.leftsidebar i {
    color: #fff;
    display: block!important
}
.cms-index-index3.header-prd #sns_header {
    position: static!important
}
.cms-index-index3.header-prd #sns_header #sns_header_full {
    background-image: url(../images/bg_header.jpg);
    position: relative
}
@media (min-width: 768px) and (max-width: 991px) {
    .cms-index-index3.header-prd #sns_header #sns_menu .btn2.btn-navbar.leftsidebar {
        display: inline-block!important;
        margin-right: 10px!important
    }
    .cms-index-index3.header-prd #sns_header #sns_menu .btn2.btn-navbar.leftsidebar i {
        color: #fff;
        display: block!important
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .cms-index-index3.header-prd #sns_header #sns_header_full {
        margin-bottom: 0!important
    }
    .cms-index-index3.header-prd #sns_header #sns_header_logo {
        text-align: center
    }
}
.cms-index-index3.header-prd #sns_breadcrumbs {
    padding-top: 8px!important;
    margin-bottom: 0
}
.cms-index-index3.header-prd #sns_content {
    margin-top: 0!important
}
.cms-index-index3 #sns_content {
    margin-top: -50px;
    z-index: 1
}
.cms-index-index3 #sns_content .policy-page3 {
    background: #fff;
    margin-bottom: 35px
}
.cms-index-index3 #sns_content .policy-page3 ul.ca-menu {
    padding: 30px 0;
    border: 1px solid #eaeaea;
    width: 100%;
    display: inline-block;
    list-style: none;
    text-align: center
}
.cms-index-index3 #sns_content .policy-page3 ul.ca-menu li {
    border-right: 1px solid #eaeaea
}
.cms-index-index3 #sns_content .policy-page3 ul.ca-menu li:last-child {
    border-right: 0
}
.cms-index-index3 #sns_content .policy-page3 ul.ca-menu li a .ca-icon i {
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    font-size: 40px;
    color: #000
}
.cms-index-index3 #sns_content .policy-page3 ul.ca-menu li a .ca-icon:hover i {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3)
}
.cms-index-index3 #sns_content .policy-page3 ul.ca-menu li a .ca-content .ca-main {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #333;
    margin-top: 25px;
    margin-bottom: 6px
}
.cms-index-index3 #sns_content .policy-page3 ul.ca-menu li a .ca-content .ca-sub {
    font-size: 12px;
    color: #666;
    margin: 0 0 3px
}
.cms-index-index3 #sns_content .sns-products-list {
    margin-bottom: 50px
}
.cms-index-index3 #sns_content .sns-products-list .price2 {
    color: #888;
    font-size: 13px;
    text-decoration: line-through;
    font-weight: 400;
    display: block;
    margin-top: 5px
}
.cms-index-index3 #sns_content .sns-products-list .products-small .item-row h3 {
    margin-bottom: 24px;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    margin-top: 0
}
.cms-index-index3 #sns_content .sns-products-list .products-small .item-row h3:after {
    border-color: #333 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 7px 6px 0;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin: 2px -8px 0;
    opacity: 1;
    position: absolute;
    top: 100%;
    -webkit-transform: rotate(0deg)!important;
    -ms-transform: rotate(0deg)!important;
    transform: rotate(0deg)!important;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    width: 0
}
.cms-index-index3 #sns_content .sns-products-list .products-small .item-row h3:before {
    background: #333 none repeat scroll 0 0;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    margin-top: 2px;
    opacity: 1;
    position: absolute;
    top: 100%;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    width: 100%
}
.cms-index-index3 #sns_content .sns-products-list .products-small .item-row .item-content {
    padding: 20px 18px 15px;
    border: 1px solid #eaeaea
}
.cms-index-index3 #sns_content .sns-products-list .products-small .item-row .item-content .item:last-child {
    margin-bottom: 0
}
.cms-index-index3 #sns_content .sns-products-list .products-small .item-row .item-content .item .item-img {
    margin-right: 10px
}
.cms-index-index3 #sns_content .sns-products-list .products-small .item-row .item-content .item .item-img img {
    height: 94px!important;
    width: 78px!important
}
.cms-index-index3 #sns_content #header-slideshow {
    margin-bottom: 52px
}
.header-style4 #sns_header .sns_header_top {
    background: 0 0;
    border-bottom: 1px solid #eaeaea
}
.header-style4 #sns_header .sns_header_top .sns_module .header-setting .mysetting .content:before {
    border-width: 9px!important
}
.header-style4 #sns_header .sns_header_top .sns_module .header-setting .language-switcher .tongle:before {
    display: none
}
.header-style4 #sns_header .sns_header_top ul.links li {
    border-left: 1px solid #eaeaea!important
}
.header-style4 #sns_header .sns_header_top ul.links li:last-child {
    border-right: 1px solid #eaeaea!important
}
.header-style4 #sns_header #sns_menu {
    z-index: 1;
    margin-top: 3px
}
.header-style4 #sns_header #sns_menu .sns_mainmenu {
    min-height: 50px!important;
    padding: 0!important;
    background: #292929
}
.header-style4 #sns_header #sns_menu .sns_mainmenu:before {
    display: none
}
.header-style4 #sns_header #sns_menu .sns_mainmenu ul.mainnav li:hover>.wrap_dropdown.fullwidth {
    display: block;
    opacity: 1;
    top: 100%!important;
    visibility: visible;
    z-index: 9999
}
.header-style4 #sns_header #sns_menu .sns_mainmenu ul.mainnav li .menu-title-lv0 span.title {
    color: #fff
}
.header-style4 #sns_header #sns_menu .sns_mainmenu ul.mainnav li .menu-title-lv0:hover span.title {
    color: var(--secondaryColor)
}
.header-style4 #sns_header #sns_menu .sns_mainmenu ul.mainnav li .pd-menu116 {
    padding-left: 15px
}
.header-style4 #sns_header #sns_menu .sns_mainmenu ul.mainnav li.active .menu-title-lv0 span.title {
    color: var(--secondaryColor)
}
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart {
    padding-right: 5px
}
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart:before {
    height: 15px;
    top: 17px;
    background: #505050
}
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart .block-minicart .tongle i {
    color: #fff!important
}
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart .block-minicart .tongle .summary .amount a,
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart .block-minicart .tongle .summary .amount a span {
    color: #fff
}
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .icon-search:before {
    color: #fff;
    height: 44px
}
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-search,
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-search button,
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-search input {
    height: 44px
}
.header-style4 #sns_header #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-search button:before {
    height: 26px
}
.cms-index-index4 #sns_slideshows3 {
    z-index: 0;
    position: relative;
    bottom: 30px
}
.cms-index-index4 #sns_slideshows3:hover .owl-controls .owl-nav .owl-next,
.cms-index-index4 #sns_slideshows3:hover .owl-controls .owl-nav .owl-prev {
    opacity: 1
}
.cms-index-index4 #sns_slideshows3 img {
    max-width: 100%!important
}
.cms-index-index4 #sns_slideshows3 .owl-controls {
    margin: 0
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-nav .owl-prev {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
    display: inline-block!important;
    margin-top: -30px;
    position: absolute;
    top: 50%;
    left: 20px;
    overflow: hidden;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    line-height: 57px;
    background: rgba(255, 255, 255, .09999999999999998);
    border: 0!important
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-nav .owl-prev:before {
    content: '\f104';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 60px;
    height: 60px;
    text-align: center;
    color: #fff;
    font-size: 40px;
    border: 0!important;
    line-height: 57px
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-nav .owl-prev:hover {
    background: var(--secondaryColor)
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-nav .owl-prev:hover:before {
    color: #fff;
    border: 0!important
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-nav .owl-next {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0;
    background: rgba(255, 255, 255, .09999999999999998);
    display: inline-block!important;
    margin-top: -30px;
    position: absolute;
    top: 50%;
    right: 20px;
    overflow: hidden;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    line-height: 57px;
    border: 0!important
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-nav .owl-next:before {
    content: '\f105';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 60px;
    height: 60px;
    text-align: center;
    color: #fff;
    font-size: 40px;
    border: 0!important;
    line-height: 57px
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-nav .owl-next:hover {
    background: var(--secondaryColor)
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-nav .owl-next:hover:before {
    color: #fff;
    border: 0!important
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-dots {
    bottom: 16px;
    display: inline-block;
    left: 50%;
    margin: 0 -39px;
    position: absolute
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-dots .owl-dot {
    display: inline-block
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-dots .owl-dot span {
    height: 14px;
    width: 14px;
    background: 0 0;
    border: 2px solid #fff;
    border-radius: 0;
    margin: 0 5px;
    cursor: pointer;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    opacity: 1!important;
    display: block
}
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-dots .owl-dot.active span,
.cms-index-index4 #sns_slideshows3 .owl-controls .owl-dots .owl-dot:hover span {
    background: #fff;
    border: 2px solid #fff
}
.cms-index-index4 #header-slideshow {
    margin-bottom: 53px
}
.cms-index-index4 .policy-page3 {
    margin-top: 30px;
    background: #fff;
    margin-bottom: 35px
}
.cms-index-index4 .policy-page3 ul.ca-menu {
    padding: 30px 0;
    border: 1px solid #eaeaea;
    width: 100%;
    display: inline-block;
    list-style: none;
    text-align: center
}
.cms-index-index4 .policy-page3 ul.ca-menu li {
    border-right: 1px solid #eaeaea
}
.cms-index-index4 .policy-page3 ul.ca-menu li:last-child {
    border-right: 0
}
.cms-index-index4 .policy-page3 ul.ca-menu li a .ca-icon i {
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    font-size: 40px;
    color: #000
}
.cms-index-index4 .policy-page3 ul.ca-menu li a .ca-icon:hover i {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3)
}
.cms-index-index4 .policy-page3 ul.ca-menu li a .ca-content .ca-main {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #333;
    margin-top: 25px;
    margin-bottom: 6px
}
.cms-index-index4 .policy-page3 ul.ca-menu li a .ca-content .ca-sub {
    font-size: 12px;
    color: #666;
    margin: 0 0 3px
}
.cms-index-index4 .products-upsell.products-index.index4 {
    position: relative
}
.cms-index-index4 .products-upsell.products-index.index4 .detai-products1 {
    position: static!important
}
.cms-index-index4 .products-upsell.products-index.index4 .title {
    position: relative;
    margin-bottom: 25px
}
.cms-index-index4 .products-upsell.products-index.index4 .title:before {
    background: #eaeaea;
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 9px;
    left: 0;
    z-index: -1
}
.cms-index-index4 .products-upsell.products-index.index4 .title h3 {
    padding-right: 22px;
    background: #fff;
    color: #333;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    margin: 0
}
.cms-index-index4 .products-upsell.products-index.index4 .owl-controls {
    background: #fff;
    padding-left: 30px;
    position: absolute!important;
    top: -5px;
    right: 0;
    opacity: 1!important
}
.cms-index-index4 .products-upsell.products-index.index4 .owl-controls .owl-nav .owl-prev {
    margin-right: 10px!important;
    position: relative!important;
    left: inherit!important
}
.cms-index-index4 .products-upsell.products-index.index4 .owl-controls .owl-nav .owl-next {
    position: relative!important;
    right: inherit!important
}
.cms-index-index4 #sns_catrgories {
    margin-top: 27px;
    padding-bottom: 50px;
    padding-top: 50px;
    background: #f5f5f5;
    margin-bottom: 52px;
    overflow: hidden
}
.cms-index-index4 #sns_catrgories .col-md-12 {
    z-index: 1
}
.cms-index-index4 #sns_catrgories .title {
    position: relative;
    margin-bottom: 25px
}
.cms-index-index4 #sns_catrgories .title:before {
    background: #eaeaea;
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 9px;
    left: 0;
    z-index: -1;
    opacity: 1
}
.cms-index-index4 #sns_catrgories .title h3 {
    padding-right: 22px;
    background: #f5f5f5;
    color: #333;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    margin: 0
}
.cms-index-index4 #sns_catrgories .owl-controls {
    margin: 0 15px 0 0;
    display: inline-block;
    z-index: 1
}
.cms-index-index4 #sns_catrgories .owl-controls .owl-nav .owl-prev {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea
}
.cms-index-index4 #sns_catrgories .owl-controls .owl-nav .owl-prev:before {
    content: '\f177';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 28px
}
.cms-index-index4 #sns_catrgories .owl-controls .owl-nav .owl-next {
    display: inline-block!important;
    height: 30px;
    overflow: hidden;
    padding: 0;
    width: 30px;
    text-align: center;
    border: 1px solid #eaeaea
}
.cms-index-index4 #sns_catrgories .owl-controls .owl-nav .owl-next:before {
    content: '\f178';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    line-height: 28px
}
.cms-index-index4 #sns_catrgories .owl-controls {
    background: #f5f5f5;
    padding-left: 30px;
    position: absolute!important;
    top: -5px;
    right: 0;
    opacity: 1!important
}
.cms-index-index4 #sns_catrgories .owl-controls .owl-nav .owl-prev {
    margin-right: 10px!important;
    position: relative!important;
    left: inherit!important
}
.cms-index-index4 #sns_catrgories .owl-controls .owl-nav .owl-next {
    position: relative!important;
    right: inherit!important
}
.cms-index-index4 #sns_catrgories .sns-category .category {
    margin: 0 -15px
}
.cms-index-index4 #sns_catrgories .sns-category .category .owl-stage-outer {
    overflow: inherit
}
.cms-index-index4 #sns_catrgories .sns-category .category .featured-slider {
    position: static!important
}
.cms-index-index4 #sns_catrgories .sns-category .category .featured-slider .item {
    position: relative;
    margin: 0 15px;
    text-align: center
}
.cms-index-index4 #sns_catrgories .sns-category .category .featured-slider .item:hover a,
.cms-index-index4 #sns_catrgories .sns-category .category .featured-slider .item:hover span,
.cms-index-index4 #sns_catrgories .sns-category .category .featured-slider .item:hover:before {
    opacity: 1
}
.cms-index-index4 #sns_catrgories .sns-category .category .featured-slider .item:before {
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    background: rgba(0, 0, 0, 0) url(../images/nencategory.png) repeat-x scroll left top;
    content: "";
    display: inline-block;
    width: 100%;
    height: 150px;
    left: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;
    opacity: 0
}
.cms-index-index4 #sns_catrgories .sns-category .category .featured-slider .item a {
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    cursor: pointer;
    opacity: 0;
    color: #fff;
    font-weight: 700;
    height: 36px;
    left: 50%;
    line-height: 36px;
    margin: -18px -54px;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    width: 107px;
    background: #000;
    z-index: 999
}
.cms-index-index4 #sns_catrgories .sns-category .category .featured-slider .item a:hover {
    background: var(--secondaryColor)
}
.cms-index-index4 #sns_catrgories .sns-category .category .featured-slider .item span {
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    opacity: 0;
    position: absolute;
    bottom: 20px;
    display: block;
    left: 16%;
    text-transform: uppercase;
    color: #333;
    font-weight: 700
}
.category-products {
    margin-bottom: 15px
}
.category-products .configurable-swatch-list {
    margin: 0;
    padding: 0;
    list-style: none
}
.category-products .configurable-swatch-list li {
    display: inline-block;
    vertical-align: middle
}
.category-title {
    display: none
}
.category-image {
    margin-bottom: 20px
}
.products-grid .item {
    margin-top: 30px
}
.products-grid .item .item-inner {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 0;
    position: relative;
    overflow: hidden
}
.products-grid .item .item-inner:hover .sns-btn-quickview:before {
    -webkit-transform: scale(1)!important;
    -ms-transform: scale(1)!important;
    transform: scale(1)!important
}
/*.products-grid .item .item-inner:hover .action-bot .actions,
.products-grid .item .item-inner:hover .action-bot .wrap-addtocart {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px)
}*/
.products-grid .item .item-inner .prd {
    position: relative;
    background: #fff;
    text-align: center
}
.products-grid .item .item-inner .item-content {
    min-height: 24px
}
.products-grid .item .item-inner .info-inner {
    position: relative
}
.products-grid .item .item-inner .ratings {
    font-size: 12px;
    margin: 0
}
.products-grid .item .item-inner .ratings .rating-links {
    display: none
}
.products-grid .item .item-inner .no-rating {
    font-size: 12px;
    margin: 0
}
.products-grid .item .item-inner .no-rating a {
    display: none
}
.products-grid .item .item-inner .no-rating:before {
    content: '\f005\f005\f005\f005\f005';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    letter-spacing: 2px;
    color: #dfdfdf
}
.products-grid .item .item-inner .more-buttons .button,
.products-grid .item .item-inner .more-buttons button {
    padding: 8px 15px
}
.products-grid .item .item-inner .item-img {
    position: relative;
    margin-bottom: 10px;
    z-index: 0
}
.products-grid .item .item-inner .item-img.have-additional .img-main {
    display: block;
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out
}
body.eimg-preserve-3d .products-grid .item .item-inner .item-img.have-additional .img-main {
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -ms-transform: rotateX(0deg) rotateY(0deg);
    transform: rotateX(0deg) rotateY(0deg);
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden
}
body.eimg-preserve-3d.ie .products-grid .item .item-inner .item-img.have-additional .img-main {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}
body.eimg-translatex .products-grid .item .item-inner .item-img.have-additional .img-main {
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform: translateX(0)
}
body.eimg-translatey .products-grid .item .item-inner .item-img.have-additional .img-main {
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform: translateY(0)
}
.products-grid .item .item-inner .item-img.have-additional .img-additional {
    text-align: center;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%;
    z-index: -1;
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out
}
body.eimg-preserve-3d .products-grid .item .item-inner .item-img.have-additional .img-additional {
    -webkit-transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden
}
body.eimg-preserve-3d.ie .products-grid .item .item-inner .item-img.have-additional .img-additional {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}
body.eimg-translatex .products-grid .item .item-inner .item-img.have-additional .img-additional {
    -ms-transform: translateX(-120%);
    transform: translateX(-120%);
    -webkit-transform: translateX(-120%)
}
body.eimg-translatey .products-grid .item .item-inner .item-img.have-additional .img-additional {
    -ms-transform: translateY(-120%);
    transform: translateY(-120%);
    -webkit-transform: translateY(-120%)
}
body.eimg-preserve-3d .products-grid .item .item-inner .item-img.have-additional:hover .img-main {
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg)
}
body.eimg-preserve-3d.ie .products-grid .item .item-inner .item-img.have-additional:hover .img-main {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}
body.eimg-translatex .products-grid .item .item-inner .item-img.have-additional:hover .img-main {
    -ms-transform: translateX(120%);
    transform: translateX(120%);
    -webkit-transform: translateX(120%)
}
body.eimg-translatey .products-grid .item .item-inner .item-img.have-additional:hover .img-main {
    -ms-transform: translateY(120%);
    transform: translateY(120%);
    -webkit-transform: translateY(120%)
}
.products-grid .item .item-inner .item-img.have-additional:hover .img-additional {
    z-index: 1
}
body.eimg-preserve-3d .products-grid .item .item-inner .item-img.have-additional:hover .img-additional {
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    -ms-transform: rotateX(0deg) rotateY(0deg);
    transform: rotateX(0deg) rotateY(0deg)
}
body.eimg-preserve-3d.ie .products-grid .item .item-inner .item-img.have-additional:hover .img-additional {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}
body.eimg-translatex .products-grid .item .item-inner .item-img.have-additional:hover .img-additional {
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform: translateX(0)
}
body.eimg-translatey .products-grid .item .item-inner .item-img.have-additional:hover .img-additional {
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform: translateY(0)
}
.products-grid .item .item-inner .item-img>a {
    position: relative;
    z-index: 0
}
.products-grid .item .item-inner .item-img .sns-action {
    position: absolute;
    width: 100%;
    height: 35px;
    left: 0;
    bottom: -40px;
    opacity: 0;
    -webkit-transition: all .2s linear;
    transition: all .2s linear
}
.products-grid .item .item-inner .item-img .sns-btn-quickview {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 50%;
    top: 50%;
    margin-left: -35px;
    margin-top: -35px;
    border-radius: 100%
}
.products-grid .item .item-inner .item-img .sns-btn-quickview span {
    display: none
}
.products-grid .item .item-inner .item-img .sns-btn-quickview:before {
    background: none repeat scroll 0 0 rgba(0, 0, 0, .3);
    border-radius: 100%;
    color: #fff;
    font-size: 20px;
    content: "";
    font-family: FontAwesome;
    width: 70px;
    height: 70px;
    left: 0;
    line-height: 70px;
    position: absolute;
    text-align: center;
    top: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all .2s linear 0s;
    transition: all .2s linear 0s;
    z-index: 1
}
.products-grid .item .item-inner .item-img .sns-btn-quickview:hover:before {
    color: var(--secondaryColor)
}
.products-grid .item .item-inner .item-img .countdown {
    position: absolute;
    top: 35px;
    right: 10px;
    display: none
}
.products-grid .item .item-inner .item-img .countdown .countdown-section {
    display: block;
    text-align: center;
    background: var(--secondaryColor);
    color: #fff;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    text-transform: uppercase;
    -webkit-transition: all .2s linear;
    transition: all .2s linear
}
.products-grid .item .item-inner .item-img .countdown .countdown-section .countdown-amount {
    display: block;
    line-height: 20px;
    font-size: 16px;
    font-weight: 700
}
.products-grid .item .item-inner .item-img .countdown .countdown-section .countdown-period {
    font-size: 9px;
    line-height: 20px;
    min-width: 40px;
    display: block;
    background: #5492db
}
.products-grid .item .item-inner .item-img .zoomWrapper img {
    left: 0
}
.products-grid .item .item-inner .item-img .zoomWrapper .zoomContainer {
    left: 0!important;
    top: 0!important
}
.products-grid .item .item-inner .item-img .product-image {
    border: 1px solid #eaeaea;
    display: block;
    overflow: hidden;
    text-align: center;
    position: relative;
    img{
        height: 300px;
        object-fit: contain;
    }
}
.products-grid .item .item-inner .item-img .product-image .img-main {
    position: relative;
    display: block;
    margin: 0 auto
}
.products-grid .item .item-inner .item-img .product-image .img-main:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    z-index: 888;
    content: '';
    display: block
}
.products-grid .item .item-inner .item-img .product-image .img-main.loading:before {
    background: #000
}
.products-grid .item .item-inner .item-info {
    padding: 0 10px;
    padding-bottom: 15px;
    min-height: 60px
}
.products-grid .item .item-inner .item-info .item-title {
    padding: 0;
    position: relative;
    font-size: 14px;
    margin-bottom: 3px;
    overflow: hidden
}
.products-grid .item .item-inner .item-info .item-title a {
    color: #333
}
.products-grid .item .item-inner .item-info .item-title a:hover {
    color: var(--secondaryColor)
}
.products-grid .item .item-inner .item-info .item-price .price1 {
    font-size: 16px;
    color: #222;
    font-weight: 700;
    margin-right: 3px
}
.products-grid .item .item-inner .item-info .item-price .price2 {
    font-size: 13px;
    color: #888;
    text-decoration: line-through
}
.products-grid .item .item-inner .item-info .item-price>* {
    display: inline-block;
    vertical-align: middle
}
.products-grid .item .item-inner .item-info .item-price .price-box .special-price {
    float: left;
    margin-right: 5px
}
.products-grid .item .item-inner .item-info .item-price .old-price .price {
    font-weight: 400;
    color: #ccc
}
.products-grid .item .item-inner .action-bot {
    text-align: center;
    z-index: 900;
    position: absolute;
    bottom: 0;
    width: 100%
}
.products-grid .item .item-inner .action-bot .wrap-addtocart {
    /*-webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);*/
    -webkit-transition: all .5s ease .1s;
    transition: all .5s ease .1s
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart {
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    display: block;
    font-family: inherit;
    font-weight: 700;
    width: 100%;
    cursor: pointer;
    font-size: 116.667%;
    line-height: 21px;
    padding: 0!important;
    position: relative;
    text-align: center;
    -webkit-transition: all 0s ease;
    transition: all 0s ease;
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart span {
    color: #999999;
    padding: 0;
    height: 35px;
    line-height: 38px;
    font-size: 14px;
    text-transform: uppercase;
    -webkit-transition: all 0s ease;
    transition: all 0s ease,
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart span i {
    font-size: 16px;
    margin-right: 2px;
    line-height: 33px;
    width: 18px;
    height: 35px;
    margin-left: 8px;
    text-align: center
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart span.text-indent {
    text-indent: -999em
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart:before {
    line-height: 35px;
    padding: 0;
    left: 30px
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart:hover {
    background: #333333;
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart:hover span{
    color: #fff;
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart:hover:after {
    display: none
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart:hover i {
    animation: 1.3s ease-out 75ms normal none 1 running wobble;
    -webkit-animation: 1.3s ease-out 75ms normal none 1 running wobble
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart i {
    font-size: 14px;
    margin-right: 7px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    display: none
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart.out-of-stock {
    border: 2px solid #ddd
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart.out-of-stock span {
    display: none
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart.out-of-stock:hover {
    background: #fde4e4;
    border-color: #fccbcb;
    color: #d60c0c
}
.products-grid .item .item-inner .action-bot .wrap-addtocart .btn-cart.out-of-stock:before {
    content: '\f06a';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px
}
.products-grid .item .item-inner .action-bot .actions {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    display: table;
    transform: translateX(-100%);
    -webkit-transition: all .5s ease .1s;
    transition: all .5s ease .1s;
    width: 100%
}
.products-grid .item .item-inner .action-bot .actions .add-to-links {
    display: table-row
}
.products-grid .item .item-inner .action-bot .actions .add-to-links li {
    display: table-cell;
    position: relative
}
.products-grid .item .item-inner .action-bot .actions .add-to-links li:before {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
    background: #eaeaea;
    content: ""
}
.products-grid .item .item-inner .action-bot .actions .add-to-links li:last-child:before {
    display: none
}
.products-grid .item .item-inner .action-bot .actions .add-to-links li a {
    color: #666;
    display: inline-block;
    width: 100%;
    line-height: 20px;
    height: 35px
}
.products-grid .item .item-inner .action-bot .actions .add-to-links li a i {
    color: #666;
    font-size: 12px!important
}
.products-grid .item .item-inner .action-bot .actions .add-to-links li a:hover i {
    color: var(--secondaryColor)
}
.products-grid .item .item-inner .ico-product.ico-new {
    background: #5492db;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
    left: -18px;
    overflow: hidden;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}
.products-grid .item .item-inner .ico-product.ico-sale {
    background: #e14141;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
    right: -15px;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}
.products-grid.style_grid {
    margin: 0 -10px
}
.products-grid.style_grid .item {
    padding: 0 10px;
    margin-bottom: 12px;
    margin-top: 0
}
.products-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: -3px;
    margin-bottom: 30px
}
.products-list>li.item {
    margin-top: 25px
}
.products-list>li.item:after,
.products-list>li.item:before {
    content: " ";
    display: table
}
.products-list>li.item:after {
    clear: both
}
.products-list>li.item:first-child {
    margin-top: 0
}
.products-list>li.item .item-title {
    margin-bottom: 10px;
    padding-top: 6px;
    text-transform: uppercase
}
.products-list>li.item .item-title a {
    font-size: 120%;
    font-weight: 700;
    color: #333
}
.products-list>li.item .rating-block {
    margin-bottom: 15px
}
.products-list>li.item .desc {
    margin-bottom: 18px;
    color: #666
}
.products-list>li.item .product_list_style {
    float: left;
    width: 100%
}
.products-list>li.item .col-left {
    float: left
}
.products-list>li.item .col-left .item-img {
    border: 1px solid #eaeaea;
    overflow: hidden;
    float: left;
    width: 205px;
    margin-top: 5px;
    margin-right: 20px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 0;
    position: relative
}
.products-list>li.item .col-left .item-img .ico-product.ico-new {
    background: #5492db;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
    left: -18px;
    overflow: hidden;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}
.products-list>li.item .col-left .item-img .ico-product.ico-sale {
    background: #e14141;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
    right: -15px;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}
.products-list>li.item .col-left .item-img .countdown {
    position: absolute;
    top: 35px;
    right: 10px
}
.products-list>li.item .col-left .item-img .countdown .countdown-section {
    display: block;
    text-align: center;
    background: var(--secondaryColor);
    color: #fff;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    text-transform: uppercase;
    -webkit-transition: all .2s linear;
    transition: all .2s linear
}
.products-list>li.item .col-left .item-img .countdown .countdown-section .countdown-amount {
    display: block;
    line-height: 20px;
    font-size: 130%px;
    font-weight: 700
}
.products-list>li.item .col-left .item-img .countdown .countdown-section .countdown-period {
    font-size: 75%;
    line-height: 20px;
    min-width: 40px;
    display: block;
    background: #5492db
}
.products-list>li.item .col-left .item-img.have-additional .img-main {
    display: block;
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out
}
body.eimg-preserve-3d .products-list>li.item .col-left .item-img.have-additional .img-main {
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -ms-transform: rotateX(0deg) rotateY(0deg);
    transform: rotateX(0deg) rotateY(0deg);
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden
}
body.eimg-preserve-3d.ie .products-list>li.item .col-left .item-img.have-additional .img-main {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}
body.eimg-translatex .products-list>li.item .col-left .item-img.have-additional .img-main {
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform: translateX(0)
}
body.eimg-translatey .products-list>li.item .col-left .item-img.have-additional .img-main {
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform: translateY(0)
}
.products-list>li.item .col-left .item-img.have-additional .img-additional {
    text-align: center;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 100%;
    z-index: -1;
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out
}
body.eimg-preserve-3d .products-list>li.item .col-left .item-img.have-additional .img-additional {
    -webkit-transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden
}
body.eimg-preserve-3d.ie .products-list>li.item .col-left .item-img.have-additional .img-additional {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}
body.eimg-translatex .products-list>li.item .col-left .item-img.have-additional .img-additional {
    -ms-transform: translateX(-120%);
    transform: translateX(-120%);
    -webkit-transform: translateX(-120%)
}
body.eimg-translatey .products-list>li.item .col-left .item-img.have-additional .img-additional {
    -ms-transform: translateY(-120%);
    transform: translateY(-120%);
    -webkit-transform: translateY(-120%)
}
body.eimg-preserve-3d .products-list>li.item .col-left .item-img.have-additional:hover .img-main {
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg)
}
body.eimg-preserve-3d.ie .products-list>li.item .col-left .item-img.have-additional:hover .img-main {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}
body.eimg-translatex .products-list>li.item .col-left .item-img.have-additional:hover .img-main {
    -ms-transform: translateX(120%);
    transform: translateX(120%);
    -webkit-transform: translateX(120%)
}
body.eimg-translatey .products-list>li.item .col-left .item-img.have-additional:hover .img-main {
    -ms-transform: translateY(120%);
    transform: translateY(120%);
    -webkit-transform: translateY(120%)
}
.products-list>li.item .col-left .item-img.have-additional:hover .img-additional {
    z-index: 1
}
body.eimg-preserve-3d .products-list>li.item .col-left .item-img.have-additional:hover .img-additional {
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    -ms-transform: rotateX(0deg) rotateY(0deg);
    transform: rotateX(0deg) rotateY(0deg)
}
body.eimg-preserve-3d.ie .products-list>li.item .col-left .item-img.have-additional:hover .img-additional {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}
body.eimg-translatex .products-list>li.item .col-left .item-img.have-additional:hover .img-additional {
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform: translateX(0)
}
body.eimg-translatey .products-list>li.item .col-left .item-img.have-additional:hover .img-additional {
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform: translateY(0)
}
.products-list>li.item .col-left .item-img .product-image {
    display: block;
    text-align: center
}
.products-list>li.item .col-right {
    position: relative;
    overflow: hidden
}
.products-list>li.item .col-right .item-title {
    padding-top: 0
}
.products-list>li.item .col-right .item-title a {
    font-size: 14px;
    text-transform: none;
    font-weight: 400
}
.products-list>li.item .col-right .item-title a:hover {
    color: var(--secondaryColor)
}
.products-list>li.item .col-right .item-price {
    bottom: 7px;
    position: relative
}
.products-list>li.item .col-right .item-price .price1 {
    font-size: 16px;
    color: #222;
    font-weight: 700;
    margin-right: 3px
}
.products-list>li.item .col-right .item-price .price2 {
    font-size: 13px;
    color: #888;
    text-decoration: line-through
}
.products-list>li.item .col-right .item-price>* {
    display: inline-block;
    vertical-align: middle
}
.products-list>li.item .col-right .item-price .price-box .special-price {
    float: left;
    margin-right: 5px
}
.products-list>li.item .col-right .item-price .old-price .price {
    font-weight: 400;
    color: #ccc
}
.products-list>li.item .col-right .desc {
    margin-top: 10px
}
.products-list>li.item .col-right .actions {
    margin-top: 20px
}
.products-list>li.item .col-right .actions .btn-cart {
    display: inline-block;
    vertical-align: middle;
    width: 174px;
    height: 36px;
    position: relative;
    font-size: 14px;
    background: #fff;
    text-transform: uppercase;
    font-weight: 700;
    color: #666;
    line-height: 36px;
    margin-right: 6px;
    border: 1px solid #eaeaea
}
.products-list>li.item .col-right .actions .btn-cart i {
    font-size: 16px;
    margin-right: 2px;
    line-height: 33px;
    width: 18px;
    height: 35px;
    margin-left: 8px;
    text-align: center
}
.products-list>li.item .col-right .actions .btn-cart:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor);
    color: #fff
}
.products-list>li.item .col-right .actions .btn-cart:hover i {
    animation: 1.3s ease-out 75ms normal none 1 running wobble;
    -webkit-animation: 1.3s ease-out 75ms normal none 1 running wobble
}
.products-list>li.item .col-right .actions .add-to-links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    vertical-align: middle
}
.products-list>li.item .col-right .actions .add-to-links li {
    float: left
}
.products-list>li.item .col-right .actions .link-compare,
.products-list>li.item .col-right .actions .link-wishlist,
.products-list>li.item .col-right .actions .sns-btn-quickview {
    width: 36px;
    height: 36px;
    float: left;
    text-align: center;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    position: relative;
    color: #666;
    font-size: 12px;
    border: 1px solid #eaeaea;
    background: 0 0;
    line-height: 30px
}
.products-list>li.item .col-right .actions .link-compare:hover,
.products-list>li.item .col-right .actions .link-wishlist:hover,
.products-list>li.item .col-right .actions .sns-btn-quickview:hover {
    border-color: var(--secondaryColor);
    background: var(--secondaryColor);
    line-height: 30px;
    color: #fff
}
.products-list>li.item .col-right .actions .link-wishlist:before {
    content: '\f004';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px
}
.products-list>li.item .col-right .actions .link-compare {
    border-left: 0;
    border-right: 0
}
.products-list>li.item .col-right .actions .link-compare:before {
    content: '\f079';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px
}
.products-list>li.item .col-right .actions .quickview-wrap {
    display: inline-block;
    vertical-align: middle;
    width: 34px;
    height: 34px
}
.products-list>li.item .col-right .actions .sns-btn-quickview {
    display: inline-block;
    vertical-align: middle
}
.products-list>li.item .col-right .actions .sns-btn-quickview span {
    display: none
}
.products-list>li.item .col-right .actions .sns-btn-quickview:before {
    content: '\f06e';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px
}
.catalog-product-compare-index .add-to-links {
    padding: 0
}
.catalog-product-compare-index .product-name {
    margin: 10px 0
}
.no-rating,
.ratings {
    margin: 0 0 5px
}
.rating-box {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0
}
.rating-box:before {
    content: '\f005\f005\f005\f005\f005';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    letter-spacing: 2px;
    color: #dfdfdf;
    white-space: nowrap
}
.rating-box .rating {
    left: 0;
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden
}
.rating-box .rating:before {
    content: '\f005\f005\f005\f005\f005';
    white-space: nowrap;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    letter-spacing: 2px;
    color: #fad507
}
.no-rating a {
    color: #888
}
.no-rating a:hover {
    color: var(--secondaryColor)
}
.rating-links {
    display: inline-block;
    vertical-align: middle;
    margin: 0
}
.rating-links .separator {
    color: #888
}
.rating-links a {
    margin: 0 1px;
    color: #888
}
.rating-links a:hover {
    color: var(--secondaryColor)
}
.products-list1 #sns_content {
    margin-bottom: 23px!important
}
.ico-label span {
    position: absolute;
    top: 7px;
    height: 15px;
    width: 60px;
    color: #fff;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 15px
}
.blog-pagev1 #sns_header,
.products-grid1 #sns_header,
.products-list1 #sns_header {
    margin-bottom: 7px
}
@media (min-width: 1200px) {
    .container {
        width: 1200px
    }
}
@media (min-width: 1200px) {
    .col-lg-first {
        clear: left
    }
}
@media (min-width: 992px) {
    .col-md-first {
        clear: left
    }
}
@media (min-width: 768px) {
    .col-sm-first {
        clear: left
    }
}
@media (max-width: 767px) {
    .col-xs-first {
        clear: left
    }
}
@media (max-width: 479px) {
    [class*=col-phone-] {
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        float: left
    }
    .col-phone-12 {
        width: 100%
    }
    .col-phone-11 {
        width: 91.66666666666666%
    }
    .col-phone-10 {
        width: 83.33333333333334%
    }
    .col-phone-9 {
        width: 75%
    }
    .col-phone-8 {
        width: 66.66666666666666%
    }
    .col-phone-7 {
        width: 58.333333333333336%
    }
    .col-phone-6 {
        width: 50%
    }
    .col-phone-5 {
        width: 41.66666666666667%
    }
    .col-phone-4 {
        width: 33.33333333333333%
    }
    .col-phone-3 {
        width: 25%
    }
    .col-phone-2 {
        width: 16.666666666666664%
    }
    .col-phone-1 {
        width: 8.333333333333332%
    }
    .visible-phone {
        display: block!important
    }
}
@media (max-width: 1199px) {
    .row.index_block>[class*=col-lg-] {
        padding-top: 30px
    }
    .row.index_block>[class*=col-lg-]:first-child {
        padding-top: 0
    }
}
@media (max-width: 991px) {
    .row.index_block>[class*=col-lg-],
    .row.index_block>[class*=col-md-] {
        padding-top: 30px
    }
    .row.index_block>[class*=col-lg-]:first-child,
    .row.index_block>[class*=col-md-]:first-child {
        padding-top: 0
    }
}
@media (max-width: 767px) {
    .row.index_block>[class*=col-lg-],
    .row.index_block>[class*=col-md-],
    .row.index_block>[class*=col-sm-] {
        padding-top: 30px
    }
    .row.index_block>[class*=col-lg-]:first-child,
    .row.index_block>[class*=col-md-]:first-child,
    .row.index_block>[class*=col-sm-]:first-child {
        padding-top: 0
    }
}
@media (max-width: 1024px) {
    .block .block-title {
        font-size: 130%!important
    }
    .products-list>li.item .col-left .item-img .countdown {
        top: 10px!important
    }
    #sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box>.inner input {
        width: 40px
    }
    #sns_content .block.block-layered-nav dl#narrow-by-list dd .price .text-box>.inner button {
        width: 55px
    }
}
@media (min-width: 980px) and (max-width: 1024px) {
    .sns-product-detail #sns_tab_products ul.sns-tab-navi li a {
        padding: 9px
    }
    .promo-wrap .pro-col.pro-mid .title {
        padding: 30px 0 18px;
        font-size: 200%
    }
    .promo-wrap.twocol-special .pro-col.pro-left {
        width: 55%
    }
    .promo-wrap.twocol-special .pro-col.pro-mid {
        width: 45%
    }
}
@media (min-width: 768px) and (max-width: 979px) {
    .promo-wrap .pro-col.pro-mid .title {
        padding: 30px 0 15px;
        font-size: 150%;
        line-height: 22px
    }
    .promo-wrap .pro-col.pro-mid .sub-title {
        padding: 10px 0;
        line-height: 18px
    }
    .promo-wrap.twocol-special .pro-col.pro-left {
        width: 55%
    }
    .promo-wrap.twocol-special .pro-col.pro-mid {
        width: 45%
    }
}
@media (max-width: 979px) {
    #sns_header #sns_menu .header-menu-block {
        padding: 20px
    }
    .data-table thead tr td,
    .data-table thead tr th {
        white-space: normal;
        padding: 5px
    }
    .data-table tbody td {
        padding: 5px
    }
    .data-table input.input-text {
        width: auto;
        max-width: 70%
    }
    .data-table textarea {
        max-width: 70%
    }
    .data-table .button {
        padding-left: 7px;
        padding-right: 7px;
        margin: 2px 0
    }
    .data-table .price-box .price {
        font-size: 120%
    }
    .wrap-quickview {
        display: none!important
    }
    #sns_content .category-products .toolbar .limiter,
    #sns_content .category-products .toolbar .pager .amount,
    #sns_content .category-products .toolbar .sort-by label,
    #sns_content .category-products .toolbar .view-mode label,
    #sns_cpanel,
    .sns-btn-quickview {
        display: none
    }
}
@media (max-width: 641px) {
    .stickynote-top {
        display: none
    }
    .col2-set .col-1,
    .col2-set .col-2 {
        width: 100%;
        margin-bottom: 10px
    }
    .cart .col2-set {
        width: 100%
    }
    .cart tbody td .product-name {
        line-height: 18px
    }
    .cart tfoot td {
        padding: 10px 5px
    }
    .cart .totals {
        width: 100%
    }
    .cart .a-center input.input-text {
        padding: 6px 2px;
        text-align: center
    }
    #sns_content .block.block-social .block-title {
        float: none;
        display: block;
        margin-bottom: 20px
    }
    #sns_content .block.slide-banner ul {
        margin-left: 0!important
    }
    #sns_content .block.slide-banner ul>li {
        width: 620px!important
    }
    #sns_content #sns_left,
    #sns_content #sns_right {
        margin-bottom: 30px
    }
    #sns_content #sns_left .block-layered-nav .price .text-box input[type=text],
    #sns_content #sns_right .block-layered-nav .price .text-box input[type=text] {
        width: 60px
    }
    #sns_content #sns_mainmidle .category-products .toolbar .sort-by,
    #sns_promotions {
        display: none
    }
    .price-box .old-price {
        margin-right: 5px
    }
}
@media (max-width: 991px) {
    #sns_header_logo #logo {
        text-align: center
    }
    .cms-simen-home-page #sns_header_bot .sns_banner1 {
        margin-top: 30px
    }
    .cms-simen-home-page #sns_header_bot .sns_banner1 img {
        width: 100%
    }
    .testimonial_content .navslider a.prev {
        left: 60px
    }
    .testimonial_content .navslider a.next {
        right: 60px
    }
    .products-list>li.item .col-right .actions .link-compare {
        border-right: 1px solid #eaeaea
    }
    .products-list>li.item .col-left {
        width: 100%;
        margin: 0 0 20px
    }
    .products-list>li.item .col-right {
        position: static;
        overflow: inherit
    }
    .products-list>li.item {
        margin-bottom: 40px
    }
}
@media (max-width: 767px) {
    .sns-product-detail #sns_tab_products ul.nav-tabs>li.tabdrop>a i {
        display: inline-block
    }
    .sns-product-detail .product-shop .add-to-box .add-to-links {
        margin-left: 0
    }
    .toolbar .view-mode {
        margin-bottom: 20px!important
    }
    .testimonial_content .testimonials_slider .owl-item.active.center .avatar-info::after,
    .testimonial_content .testimonials_slider .owl-item.active.center .avatar-info::before {
        display: none
    }
    .testimonial_content .testimonials_slider .owl-item.active.center .content {
        position: inherit!important;
        margin-left: 0!important;
        width: 100%!important
    }
    .testimonial_content .navslider a {
        width: 60px;
        height: 60px;
        color: #888;
        text-align: center;
        background: 0 0;
        opacity: .2;
        top: 45px
    }
    .testimonial_content .navslider a.next {
        right: 16%
    }
    .testimonial_content .navslider a.next:before {
        font-family: FontAwesome;
        content: "";
        font-size: 50px;
        display: inline-block
    }
    .testimonial_content .navslider a.prev {
        left: 16%
    }
    .testimonial_content .navslider a.prev:before {
        font-family: FontAwesome;
        content: "";
        font-size: 50px;
        display: inline-block
    }
    .testimonial_content .navslider a:hover {
        background: 0 0;
        opacity: 1;
        color: var(--secondaryColor)
    }
    .footer_style #sns_footer_top div.pull-right {
        float: inherit!important
    }
    #sns_header .sns_header_top .header-account .myaccount {
        cursor: pointer;
        height: 39px;
        padding: 8px 12px 6px;
        position: relative
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .tongle {
        cursor: pointer
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .tongle .fa:after {
        opacity: 1;
        filter: alpha(opacity=100)
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .content {
        display: block;
        padding-bottom: 5px;
        visibility: visible;
        opacity: 1;
        filter: alpha(opacity=100);
        top: 100%;
        top: 100%!important
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .content li {
        border-bottom: 0;
        padding: 0
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .content li:last-child {
        border-bottom: 0
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .content li a {
        padding: 6px 15px;
        display: block;
        width: 100%;
        color: #888
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .content li a:hover {
        background: 0 0;
        color: var(--secondaryColor)
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .content li a i {
        display: none
    }
    #sns_header .sns_header_top .header-account .myaccount .tongle .fa {
        position: relative
    }
    #sns_header .sns_header_top .header-account .myaccount .content {
        position: absolute;
        right: 0;
        min-width: 120px;
        background: #fff;
        z-index: 9999;
        padding: 0!important;
        border: 1px solid #eaeaea;
        visibility: hidden;
        display: block;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        opacity: 0;
        filter: alpha(opacity=0);
        top: 120%
    }
    #sns_header .sns_header_top .header-account .myaccount .content:after,
    #sns_header .sns_header_top .header-account .myaccount .content:before {
        bottom: 100%;
        left: 14px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none
    }
    #sns_header .sns_header_top .header-account .myaccount .content:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 8px!important;
        margin-left: -6px!important
    }
    #sns_header .sns_header_top .header-account .myaccount .content:before {
        border-color: rgba(234, 234, 234, 0);
        border-bottom-color: #eaeaea;
        border-width: 0!important;
        margin-left: -7px!important
    }
    #sns_header .sns_header_top .header-account .myaccount .content ul {
        margin: 0;
        list-style: none;
        padding: 8px 0
    }
    #sns_header .sns_header_top .header-account .myaccount .content ul li {
        min-height: 20px;
        line-height: 20px;
        border: 0;
        margin-left: 0;
        display: block;
        width: 100%
    }
    #sns_header .sns_header_top .header-account .myaccount .content ul li a:hover {
        color: #333
    }
    #sns_header .sns_header_top .header-account .myaccount .content ul li i {
        display: none
    }
    #sns_header .sns_header_top .header-account .myaccount .content ul li a {
        padding: 0 0 0 10px;
        display: block;
        width: 100%;
        border: 0;
        line-height: 20px!important
    }
    #sns_header .sns_header_top .header-account .myaccount .content ul li a.selected,
    #sns_header .sns_header_top .header-account .myaccount .content ul li a:hover {
        background: #eaeaea
    }
    #sns_header .sns_header_top .header-account .myaccount .content ul li:last-child a {
        border: 0
    }
    #sns_header .sns_header_top .header-account .myaccount .customer-ct.content ul.links li {
        padding: 5px 10px;
        line-height: 20px;
        color: #888;
    }
    #sns_header .sns_header_top .header-account .myaccount .customer-ct.content ul.links li:last-child {
        border-right: 0
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .tongle:after {
        color: var(--secondaryColor)
    }
    #sns_header .sns_header_top .header-account .myaccount:hover .tongle .fa,
    #sns_header .sns_header_top .header-account .myaccount:hover .tongle span {
        color: var(--secondaryColor)!important
    }
    #sns_header .sns_header_top .header-account .myaccount .tongle {
        top: 3px;
        display: block;
        float: right;
        padding-right: 15px;
        position: relative;
        color: #666
    }
    #sns_header .sns_header_top .header-account .myaccount .tongle .fa {
        top: -1px;
        color: #666
    }
    #sns_header .sns_header_top .header-account .myaccount .tongle .fa-angle-down {
        display: none
    }
    #sns_header .sns_header_top .header-account .myaccount .tongle span {
        display: inline-block;
        color: #666!important;
        margin-left: 3px
    }
    #sns_header .sns_header_top .header-account .myaccount .tongle:after {
        position: absolute;
        content: "\f0d7";
        font-family: FontAwesome;
        top: 0;
        right: 0
    }
    #sns_slideshow_home2 .sns_banner1 {
        margin-top: 30px
    }
    .tabs-slidev2 .owl-controls {
        top: -61px
    }
    .tabs-slidev2 .sns-pdt-nav {
        padding-right: 75px
    }
    .sns-producttabs-slider .sns-pdt-head .block-title,
    .sns-producttabs-slider .sns-pdt-head h3.pdt-nav {
        display: none
    }
    .sns-producttabs-slider li.dropdown {
        display: block!important
    }
    .sns-producttabs-slider .display-tab {
        float: right;
        margin-top: 1px
    }
    .policy {
        display: none
    }
    .footer_style #sns_footer_bottom .payment {
        margin-top: 10px;
        text-align: center
    }
    .footer_style #sns_footer_bottom .sns-copyright {
        text-align: center
    }
    .sns-pdt-nav h3.pdt-nav {
        display: none
    }
    .sns-pdt-nav>.dropdown {
        display: block!important
    }
    .block.sns-deals .countdown .countdown-section {
        margin-left: 1px!important
    }
    #sns_footer_map .btn_gmap {
        margin-left: -95px!important;
        top: -28px!important
    }
    #newsletter-fixed {
        width: 275px!important;
        height: 280px!important;
        padding: 15px!important
    }
    #newsletter-fixed .subscribe-content .subscribe_form #newsletter {
        min-width: 180px;
        width: 180px
    }
}
@media (max-width: 480px) {
    .cart ul.checkout-types {
        padding-left: 0!important;
        float: left;
        width: 100%!important
    }
    .cart .data-table .button {
        display: block!important;
        width: 100%;
        margin: 10px 0
    }
    .cart-collaterals .totals table td {
        text-align: left!important;
        padding: 0!important
    }
}
@media (max-width: 479px) {
    .sns-product-detail .product-shop .add-to-box .add-to-cart .btn-cart {
        margin-top: 15px
    }
    .testimonial_content .navslider a.prev {
        left: 0
    }
    .testimonial_content .navslider a.next {
        right: 0
    }
    #sns_header.style_v2 #sns_header_logo .sns_menu_right .top-search .form-search input {
        min-width: 120px;
        width: 200px!important
    }
    #sns_header.style_v2 .top-cart .mycart {
        padding-left: 0
    }
    #sns_header #sns_menu h1 {
        margin: 0
    }
    #sns_header #sns_menu .header-menu-block {
        padding: 0!important
    }
    #sns_header #sns_header_bot .block_categories {
        display: none!important
    }
    #sns_header #sns_header_bot .container {
        width: 100%!important
    }
    #sns_header.transparent #sns_menu {
        position: static!important
    }
    .mrb40 .slideshow-homepage {
        margin-bottom: 20px
    }
    .mrb50 {
        margin-bottom: 30px
    }
    .mrb50 .banner {
        margin-bottom: 2px
    }
    #sns_header #sns_menu .header-menu-block .header-tools {
        float: right;
        margin-top: 4px
    }
}
.wrap {
    width: 100%!important
}
.block-categories-nav .content {
    position: relative!important;
    display: block
}
.block-categories-nav.test .content {
    position: relative!important;
    display: none
}
@media (min-width: 320px) and (max-width: 479px) {
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 .detail-none {
        display: block
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 h3:hover {
        background: #222
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 h3.active {
        background: #222;
        color: #fff
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 h3.active i {
        color: #fff
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 .nav-tabs {
        background: #fff none repeat scroll 0 0;
        display: none;
        height: 111px;
        position: absolute;
        right: 0!important;
        width: 200px
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 .nav-tabs li {
        display: block;
        width: 100%
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 .nav-tabs li a {
        font-size: 14px;
        font-weight: 400;
        padding: 9px 20px 8px
    }
    .sns-products-detail1 #sns_mainm .description.test .nav-tabs {
        display: block
    }
    .sns-products-detail1 .sns-product-detail .product-img-box {
        margin-bottom: 20px
    }
    .sns-products-detail1 .sns-product-detail .item-info .actions .btn-cart {
        margin-top: 20px
    }
    .sns-products-detail1 .sns-product-detail .item-info .actions .add-to-links {
        margin-top: 20px!important
    }
    .sns-products-detail1 .sns-product-detail .item-info .actions .add-to-links li .link-compare {
        border-right: 1px solid #eaeaea!important
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 .detail-none {
        display: block
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 h3:hover {
        background: #222
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 h3.active {
        background: #222;
        color: #fff
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 h3.active i {
        color: #fff
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 .nav-tabs {
        background: #fff none repeat scroll 0 0;
        display: none;
        height: 111px;
        position: absolute;
        right: 0!important;
        width: 200px
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 .nav-tabs li {
        display: block;
        width: 100%
    }
    .sns-products-detail1 #sns_mainm .description .sns_producttaps_wraps1 .nav-tabs li a {
        font-size: 14px;
        font-weight: 400;
        padding: 9px 20px 8px
    }
    .sns-products-detail1 #sns_mainm .description.test .nav-tabs {
        display: block
    }
    .sns-products-detail1 .sns-product-detail .product-img-box {
        margin-bottom: 20px
    }
    .sns-products-detail1 .sns-product-detail .item-info .actions .add-to-links li .link-compare {
        border-right: 1px solid #eaeaea!important
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .sns-products-detail1 .sns-product-detail .item-info .actions .gfont {
        display: none
    }
    .sns-products-detail1 .sns-product-detail .item-info .actions .btn-cart {
        margin-right: 20px
    }
    .sns-products-detail1 .sns-product-detail .item-info .actions .add-to-links li .link-compare {
        border-right: 1px solid #eaeaea!important
    }
}
@media (min-width: 320px) and (max-width: 512px) {
    .products-list1 #sns_content .products-list .col-left .item-img {
        margin-right: 0;
        width: 100%
    }
}
@media (min-width: 513px) and (max-width: 767px) {
    .products-list1 #sns_content .products-list li.item.odd {
        margin-bottom: 30px
    }
    .products-list1 #sns_content .products-list .col-left {
        margin-bottom: 0;
        width: auto!important
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .products-list1 #sns_content .products-list li.item.odd {
        margin-bottom: 30px
    }
    .products-list1 #sns_content .products-list .col-left {
        margin-bottom: 0;
        width: auto!important
    }
}
@media (min-width: 320px) and (max-width: 991px) {
    .header-style2 #sns_content .sns_producttaps_wraps {
        margin-bottom: 40px;
        position: relative;
        margin-top: 0
    }
    .header-style2 #sns_content .sns_producttaps_wraps .precar {
        display: block!important;
        margin-top: 0;
        cursor: pointer;
        margin-bottom: 20px
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav {
        display: none;
        width: 172px;
        border: 1px solid #eaeaea!important;
        z-index: 99;
        position: absolute!important;
        top: 30px;
        left: 0;
        padding: 10px 0;
        height: auto;
        background: #fff
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav:before {
        display: none
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li {
        padding: 0;
        float: inherit!important
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li:last-child {
        padding-right: 0
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li.active {
        padding: 0!important;
        background: #eaeaea;
        background: #F1F1F1
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li.active a {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
        background: #F1F1F1;
        border-radius: 0
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li:hover {
        background: #F1F1F1
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li:after {
        display: none
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li a {
        font-size: 18px;
        padding: 0 22px
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li a:after,
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li a:before {
        display: none
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li a:hover {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1)
    }
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li a:hover:after,
    .header-style2 #sns_content .sns_producttaps_wraps ul.nav li a:hover:before {
        display: none
    }
    .header-style2 #sns_content .sns_producttaps_wraps.test ul.nav {
        display: block
    }
    .header-style2 #sns_menu #sns_mommenu .fa-align-left {
        position: relative;
        top: 0
    }
    .header-style2 #sns_left .deals_content .item .item-inner .click_count .deals-time {
        font-size: 11px;
        padding: 13px 10px
    }
    .header-style2 #sns_left .deals_content .item .item-inner .click_count a {
        padding: 12px 10px
    }
    .header-style2 #sns_left .block.bestsale {
        padding: 16.5px 15px
    }
    #sns_menu .btn2.btn-navbar.offcanvas i {
        font-size: 20px;
        color: #333;
        position: relative;
        top: 0
    }
    #sns_breadcrumbs {
        margin-bottom: 0
    }
    #sns_left {
        -webkit-transition: all .3s ease-in 0s;
        transition: all .3s ease-in 0s;
        width: 280px;
        background: #fff;
        height: 100%;
        left: -280px;
        opacity: 0;
        overflow: auto;
        padding: 20px;
        position: fixed;
        top: 0;
        z-index: 9999
    }
    #sns_left .bestsale {
        padding: 0 15px 5px
    }
    #sns_left .bestsale .title h3 {
        font-size: 17px
    }
    #sns_left .bestsale .owl-controls {
        top: 11px!important;
        right: 15px!important
    }
    #sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-img {
        float: left;
        margin-right: 10px
    }
    .blog-pagev1 #sns_menu .btn2.btn-navbar.leftsidebar,
    .products-grid1 #sns_menu .btn2.btn-navbar.leftsidebar,
    .products-list1 #sns_menu .btn2.btn-navbar.leftsidebar,
    .sns-404 #sns_menu .btn2.btn-navbar.leftsidebar,
    .sns-contact-us #sns_menu .btn2.btn-navbar.leftsidebar,
    .sns-products-detail1 #sns_menu .btn2.btn-navbar.leftsidebar,
    .sns-shopping-cart #sns_menu .btn2.btn-navbar.leftsidebar {
        display: inline-block!important
    }
    .blog-pagev1 #sns_menu .fa.fa-align-left,
    .products-grid1 #sns_menu .fa.fa-align-left,
    .products-list1 #sns_menu .fa.fa-align-left,
    .sns-404 #sns_menu .fa.fa-align-left,
    .sns-contact-us #sns_menu .fa.fa-align-left,
    .sns-products-detail1 #sns_menu .fa.fa-align-left,
    .sns-shopping-cart #sns_menu .fa.fa-align-left {
        color: #333;
        position: relative;
        top: 0
    }
    .show-sidebar #sns_content {
        position: static;
        z-index: 999
    }
    .show-sidebar #sns_left {
        opacity: 1;
        left: 0
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    #sns_header .sns_header_top {
        margin: 0
    }
    #header-slideshow {
        text-align: center
    }
    #header-slideshow .slideshows {
        margin-bottom: 20px
    }
    #header-slideshow .pdno {
        display: none
    }
    #sns_content .sns_producttaps_wraps {
        margin-bottom: 40px;
        position: relative;
        margin-top: 0
    }
    #sns_content .sns_producttaps_wraps .precar {
        display: block!important;
        margin-top: 0;
        cursor: pointer;
        margin-bottom: 20px
    }
    #sns_content .sns_producttaps_wraps ul.nav {
        display: none;
        width: 172px;
        border: 1px solid #eaeaea!important;
        z-index: 99;
        position: absolute!important;
        top: 30px;
        left: 0;
        padding: 10px 0;
        height: auto;
        background: #fff
    }
    #sns_content .sns_producttaps_wraps ul.nav:before {
        display: none
    }
    #sns_content .sns_producttaps_wraps ul.nav li {
        padding: 0;
        float: inherit!important
    }
    #sns_content .sns_producttaps_wraps ul.nav li:last-child {
        padding-right: 0!important
    }
    #sns_content .sns_producttaps_wraps ul.nav li.active {
        padding: 0!important;
        background: #eaeaea;
        background: #F1F1F1
    }
    #sns_content .sns_producttaps_wraps ul.nav li.active a {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
        background: #F1F1F1;
        border-radius: 0
    }
    #sns_content .sns_producttaps_wraps ul.nav li:hover {
        background: #F1F1F1
    }
    #sns_content .sns_producttaps_wraps ul.nav li:after {
        display: none
    }
    #sns_content .sns_producttaps_wraps ul.nav li a {
        font-size: 18px;
        padding: 0 22px
    }
    #sns_content .sns_producttaps_wraps ul.nav li a:after,
    #sns_content .sns_producttaps_wraps ul.nav li a:before {
        display: none
    }
    #sns_content .sns_producttaps_wraps ul.nav li a:hover {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1)
    }
    #sns_content .sns_producttaps_wraps ul.nav li a:hover:after,
    #sns_content .sns_producttaps_wraps ul.nav li a:hover:before {
        display: none
    }
    #sns_content .sns_producttaps_wraps.test ul.nav {
        display: block
    }
    #sns_content .sns_suggest .block-title .fa {
        display: block
    }
    #sns_content .sns_suggest .suggest-content {
        display: none
    }
    #sns_content .sns_suggest .suggest-content .suggest-item {
        margin-bottom: 20px;
        -webkit-transition: all .25s ease 0s;
        transition: all .25s ease 0s;
        width: 33.33%;
        border-right: 0
    }
    #sns_content .sns_suggest.test .suggest-content {
        display: inline-block
    }
    #sns_content .sns_banner1 {
        margin-bottom: 20px;
        margin-top: 0;
        text-align: center
    }
    #sns_content .sns_banner1 .banner-content {
        margin-bottom: 20px
    }
    #sns_content .sns-products-list {
        margin-bottom: 15px
    }
    #sns_content .sns-latestblog {
        margin-bottom: 10px
    }
    .footer_style #sns_footer_top {
        margin-bottom: 0;
        padding-bottom: 10px;
        padding-top: 22px
    }
    .footer_style #sns_footer_top h6 {
        margin-bottom: 10px
    }
    .footer_style #sns_footer_top .column {
        padding-bottom: 10px!important
    }
    .footer_style #sns_footer_bottom {
        padding: 5px 0 0
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .sns-shopping-cart #sns_content .shoppingcart .sptitle h4 {
        margin-top: 20px;
        position: relative;
        margin-bottom: 0
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.title {
        display: none
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid {
        border: 1px solid #eaeaea!important
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid li {
        border: medium none!important;
        display: inline-block;
        height: auto!important;
        line-height: 90px;
        margin-right: 25px;
        text-align: center;
        width: auto!important
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid li.image {
        float: left
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid .item-title {
        margin-top: 10px
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid .number a {
        border: 0
    }
    .sns-shopping-cart #sns_content .shoppingcart .form-right h3 .text3 {
        font-size: 18px;
        margin-right: 15px
    }
    .sns-shopping-cart #sns_content .shoppingcart .form-right h3 .text4 {
        font-size: 16px
    }
    .sns-404 #sns_content .content {
        padding: 0 50px!important
    }
    .sns-404 #sns_content .content #newsletter-validate .input-box .input_warp {
        display: inline-block!important;
        margin: 0 10px
    }
    .container {
        padding: 0 20px
    }
    .sns_header_top {
        margin-bottom: 0!important
    }
    .policy .policy_custom .policy-titile {
        font-size: 12px
    }
    #header-slideshow {
        margin-bottom: 10px
    }
    #header-slideshow .banner-right {
        padding-left: 0
    }
    #header-slideshow .banner-right .dbn {
        display: none
    }
    #header-slideshow .banner-right .pdno {
        margin: 0;
        padding-left: 5px!important
    }
    #header-slideshow .banner-right .pdno .banner6 {
        margin-bottom: 10px
    }
    #header-slideshow .banner-right .pdno .banner8 {
        padding-left: 0
    }
    #sns_content .sns_suggest .block-title .fa {
        display: block
    }
    #sns_content .sns_suggest .suggest-content {
        display: none
    }
    #sns_content .sns_suggest .suggest-content .suggest-item {
        margin-bottom: 20px;
        -webkit-transition: all .25s ease 0s;
        transition: all .25s ease 0s;
        width: 20%;
        border-right: 0
    }
    #sns_content .sns_suggest.test .suggest-content {
        display: inline-block
    }
    #sns_content .sns_banner1 {
        margin: 7px 0 42px
    }
    #sns_content .sns-latestblog {
        margin-bottom: 20px
    }
    .footer_style #sns_footer_top {
        padding-bottom: 5px;
        padding-top: 20px;
        margin: 0
    }
    .footer_style #sns_footer_bottom {
        margin-bottom: 0
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .sns-shopping-cart #sns_content .shoppingcart .sptitle h4 {
        margin-top: 20px;
        position: relative;
        margin-bottom: 0
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.title {
        display: none
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid {
        border: 1px solid #eaeaea!important
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid li {
        border: 0!important;
        width: auto!important;
        height: auto!important;
        line-height: inherit!important;
        display: block;
        text-align: center
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid li.image {
        float: left
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid .item-title {
        margin-top: 10px
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid .number a {
        border: 0
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-bot {
        padding: 10px 0;
        height: auto!important;
        line-height: 60px;
        text-align: center
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-bot li {
        display: block;
        margin: 0;
        float: inherit!important
    }
    .sns-shopping-cart #sns_content .shoppingcart .form-right h3 .text3 {
        font-size: 18px;
        margin-right: 15px
    }
    .sns-shopping-cart #sns_content .shoppingcart .form-right h3 .text4 {
        font-size: 16px
    }
    .sns-404 #sns_content .content {
        padding: 0 10px!important
    }
    .sns-404 #sns_content .content #newsletter-validate .input-box .input_warp {
        display: block!important;
        margin: 0 10px
    }
    .sns-404 #sns_content .content #newsletter-validate .input-box .input_warp input {
        width: 100%!important
    }
    .blog-pagev1 #sns_main .postWrapper .post-title a {
        color: #333;
        font-size: 20px;
        font-weight: 700
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .sns-shopping-cart #sns_content .shoppingcart ul.title {
        display: none
    }
    .sns-shopping-cart #sns_content .shoppingcart ul {
        border-bottom: 0
    }
    .sns-shopping-cart #sns_content .shoppingcart ul:last-child {
        border-bottom: 1px solid #eaeaea
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid {
        border: 1px solid #eaeaea!important
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid li {
        display: inline-block;
        height: auto!important;
        line-height: 120px;
        text-align: center;
        width: auto!important;
        padding: 0 36.9px
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid li.image {
        float: left;
        padding: 0 10px!important
    }
    .sns-shopping-cart #sns_content .shoppingcart ul.nav-mid .number a {
        border: 0
    }
    .sns-shopping-cart #sns_content .shoppingcart .form-right h3 .text3 {
        font-size: 18px;
        margin-right: 15px
    }
    .sns-shopping-cart #sns_content .shoppingcart .form-right h3 .text4 {
        font-size: 16px
    }
    .sns-404 #sns_content .content {
        padding: 0 200px!important
    }
    .sns-404 #sns_content .content #newsletter-validate .input-box .input_warp {
        display: inline-block!important;
        margin: 0 10px
    }
    .blog-pagev1 .block-blog-inner ul li a {
        width: 50px
    }
    .blog-pagev1 #sns_header {
        margin-bottom: 7px
    }
    .blog-pagev1 #sns_breadcrumbs {
        margin-bottom: 0
    }
    .blog-pagev1 .block-blog-inner .block-content>div {
        padding: 14px 8px 15px
    }
    .blog-pagev1 .block-latestblog-v3 {
        padding: 14px 12px 0!important
    }
    #sns_left .bestsale {
        padding: 0 15px 5px
    }
    #sns_left .bestsale .title h3 {
        font-size: 15px
    }
    #sns_left .bestsale .owl-controls {
        top: 9px!important;
        right: 15px!important
    }
    #sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-img {
        float: left;
        margin-right: 10px
    }
    #sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info {
        max-width: 79px
    }
    #sns_left .bestsale .content .products-slider12 .item .item-inner .prd .item-info .action-bot i {
        display: none!important
    }
    #sns_custommenu ul.mainnav li.level0>a {
        padding: 0 8px 0 9px
    }
}
@media (min-width: 536px) and (max-width: 585px) and (-webkit-min-device-pixel-ratio: 0) {
    #sns_content .sns_suggest .suggest-content .suggest-item {
        width: 30.33%!important
    }
}
@media (min-width: 586px) and (max-width: 800px) and (-webkit-min-device-pixel-ratio: 0) {
    #sns_content .sns_suggest .suggest-content .suggest-item {
        width: 24.33%!important
    }
}
@media (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 0) {
    .products-grid1 .products-grid.row.style_grid {
        margin: 0 -11px
    }
}
@media (min-width: 320px) and (max-width: 479px) {
    .sns-contact-us #googleMap {
        width: 100%!important
    }
    .blog-pagev1.detail .smart-green .one2 input {
        width: 100%!important;
        margin-bottom: 20px!important
    }
    .blog-pagev1.detail .smart-green .one2 input:last-child {
        margin-bottom: 0!important
    }
}
@media (min-width: 480px) and (max-width: 1199px) {
    .sns-contact-us #googleMap {
        width: 100%!important
    }
    .blog-pagev1.detail .smart-green .one2 input {
        margin-bottom: 20px!important
    }
    .blog-pagev1.detail .smart-green .one2 input:last-child {
        margin-bottom: 0!important
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .header-style2 #sns_content #header-slideshow {
        margin-bottom: 20px
    }
    .header-style2 #sns_content #sns_producttaps1 {
        margin-bottom: 10px
    }
    .header-style2 #sns_content .sns_banner_page2 {
        margin-bottom: 40px
    }
    .header-style2 #sns_content #sns_slider1_page2,
    .header-style2 #sns_content #sns_slider2_page2 {
        margin-bottom: 10px
    }
    .header-style2 #sns_content .sns_banner1,
    .header-style2 #sns_content .sns_banner2,
    .header-style2 #sns_content .sns_banner3 {
        text-align: center
    }
    .header-style2 #sns_content .sns_banner1 .style-banner2,
    .header-style2 #sns_content .sns_banner2 .style-banner2,
    .header-style2 #sns_content .sns_banner3 .style-banner2 {
        margin-top: 20px
    }
    .header-style2 #sns_content .sns_banner1 {
        margin-bottom: 30px
    }
    .header-style2 #sns_content .sns_banner2 {
        margin-bottom: 40px
    }
    .header-style2 #sns_content .sns_banner3 {
        margin-bottom: 40px!important
    }
    .header-style2 #sns_content #sns_slider3_page2 {
        margin-bottom: 10px
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .header-style2 #sns_content #header-slideshow {
        margin-bottom: 40px
    }
    .header-style2 #sns_content #header-slideshow .pdno .banner8 {
        margin-top: 0!important
    }
    .header-style2 #sns_content #header-slideshow .pdno .banner6 {
        margin: 0
    }
    .header-style2 #sns_content #header-slideshow .pdno .banner7 {
        margin-bottom: 15px
    }
    .header-style2 #sns_content #header-slideshow .slideshows {
        padding-right: 10px
    }
    .header-style2 #sns_content #sns_producttaps1 {
        margin-bottom: 16px
    }
    .header-style2 #sns_content .sns_banner_page2 {
        margin-bottom: 40px
    }
    .header-style2 #sns_content #sns_slider1_page2 {
        margin-bottom: 15px
    }
    .header-style2 #sns_content .sns_banner1 {
        margin: 0 0 40px
    }
    .header-style2 #sns_content #sns_slider2_page2 {
        margin-bottom: 15px
    }
    .header-style2 #sns_content .sns_banner2 {
        margin-bottom: 40px
    }
    .header-style2 #sns_content #sns_slider3_page2 {
        margin-bottom: 15px
    }
    .header-style2 #sns_content .sns_banner3 {
        margin-bottom: 40px!important
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    #header-slideshow .banner-right .pdno {
        margin-top: 20px
    }
    #header-slideshow .banner-right .pdno .banner6 {
        margin-bottom: 10px
    }
    #header-slideshow .banner-right .pdno .banner7 {
        padding-right: 7.5px
    }
    #header-slideshow .banner-right .pdno .banner8 {
        padding-left: 7.5px!important;
        padding-left: 0
    }
    #sns_left .block-blog-inner ul li {
        position: relative;
        padding-left: 39px;
        line-height: 24px
    }
    #sns_left .block-blog-inner ul li span {
        position: absolute;
        top: 0;
        left: 0
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .header-style3.show-sidebar1 #sns_menu {
        z-index: 999!important
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .header-style3 #sns_header #sns_header_full {
        background-image: url(../images/bg_header.jpg);
        margin-bottom: 20px
    }
}
@media (min-width: 320px) and (max-width: 479px) {
    .header-style3 #sns_header #sns_header_full {
        position: relative
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top {
        position: static!important;
        border-bottom: 1px solid #eaeaea;
        z-index: 0
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .tongle:before {
        background: #eaeaea
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .tongle:hover span,
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .tongle:hover:after {
        color: var(--secondaryColor)
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .content {
        top: 98%
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .content:before {
        border-width: 9px!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount:hover .tongle i,
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount:hover .tongle span {
        color: var(--secondaryColor)!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount:hover .tongle:after {
        color: var(--secondaryColor)
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .tongle i,
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .tongle span {
        color: #fff!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .tongle:after {
        color: #fff
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .content {
        top: 98%!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .content:before {
        border-width: 9px!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .content ul li a {
        color: #666
    }
    .header-style3 #sns_header #sns_header_full #sns_menu #sns_header_logo {
        text-align: center
    }
    .header-style3 #sns_header #sns_header_full #sns_menu #sns_header_logo .responsv {
        float: none!important
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu {
        text-align: left!important;
        margin-right: 0;
        padding: 0 15px
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav {
        float: left!important
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.leftsidebar {
        display: none;
        margin: 0
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.leftsidebar i {
        display: none
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.offcanvas i {
        color: #fff
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav #menu_offcanvas {
        z-index: 99999
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-search {
        top: 0!important;
        padding: 0 15px;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-search input {
        height: 48px
    }
    .header-style3 #sns_header #sns_slideshows3 #slishow_wrap12 .owl-controls .owl-dots {
        bottom: 10px
    }
    .header-style3 #sns_content {
        margin-top: 20px!important
    }
    .header-style3 #sns_content .policy-page3 {
        margin-bottom: 25px
    }
    .header-style3 #sns_content .policy-page3 ul li {
        margin-bottom: 15px;
        border: 0!important
    }
    .header-style3 #sns_content .sns-products-list {
        margin-bottom: 40px
    }
    .header-style3 #sns_content .sns-products-list .products-small .item-row {
        margin-bottom: 30px
    }
    .header-style3 #sns_content .sns-products-list .products-small .item-row:last-child {
        margin-bottom: 0
    }
    .header-style3 #sns_content #header-slideshow {
        margin-bottom: 40px
    }
    .header-style3 .footer_style #sns_footer_top .container_in .bot-social {
        margin-bottom: 0
    }
    .header-style3 .footer_style #sns_footer_top .container_in .bot-social .banner_home2 .module {
        padding: 5px 0
    }
}
@media (min-width: 480px) and (max-width: 767px) {
    .header-style3 #sns_header #sns_header_full {
        position: relative
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top {
        position: static!important;
        border-bottom: 1px solid #eaeaea;
        z-index: 0
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .tongle:before {
        background: #eaeaea
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .tongle:hover span,
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .tongle:hover:after {
        color: var(--secondaryColor)
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .content {
        top: 98%
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-setting .mysetting .content:before {
        border-width: 9px!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount:hover .tongle i,
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount:hover .tongle span {
        color: var(--secondaryColor)!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount:hover .tongle:after {
        color: var(--secondaryColor)
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .tongle i,
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .tongle span {
        color: #fff!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .tongle:after {
        color: #fff
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .content {
        top: 98%!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .content:before {
        border-width: 9px!important
    }
    .header-style3 #sns_header #sns_header_full .sns_header_top .sns_module .header-account .myaccount .content li a {
        color: #666
    }
    .header-style3 #sns_header #sns_header_full #sns_menu #sns_header_logo {
        text-align: center
    }
    .header-style3 #sns_header #sns_header_full #sns_menu #sns_header_logo .responsv {
        float: none!important
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu {
        text-align: left!important;
        margin-right: 0;
        padding: 0 15px
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav {
        float: left!important
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.leftsidebar {
        display: none;
        margin: 0
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.leftsidebar i {
        display: none
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.offcanvas i {
        color: #fff
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu #sns_mainnav #menu_offcanvas {
        z-index: 99999
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-cart .mycart .block-minicart .tongle .summary span {
        background: #eaeaea
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-search {
        top: 0!important;
        padding: 0 15px;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea
    }
    .header-style3 #sns_header #sns_header_full #sns_menu .sns_mainmenu .sns_menu_right .block_topsearch .top-search input {
        height: 48px
    }
    .header-style3 #sns_header #sns_slideshows3 #slishow_wrap12 .owl-controls .owl-dots {
        bottom: 10px
    }
    .header-style3 #sns_content {
        margin-top: 20px!important
    }
    .header-style3 #sns_content .policy-page3 {
        margin-bottom: 25px
    }
    .header-style3 #sns_content .policy-page3 ul li {
        border: 0!important;
        margin-bottom: 15px
    }
    .header-style3 #sns_content .sns-products-list {
        margin-bottom: 40px
    }
    .header-style3 #sns_content .sns-products-list .products-small .item-row {
        margin-bottom: 30px
    }
    .header-style3 #sns_content .sns-products-list .products-small .item-row:last-child {
        margin-bottom: 0
    }
    .header-style3 #sns_content .sns-products-list .products-small .item-row .item {
        width: 100%!important
    }
    .header-style3 #sns_content #header-slideshow {
        margin-bottom: 40px
    }
    .header-style3 .footer_style #sns_footer_top .container_in .bot-social {
        margin-bottom: 0
    }
    .header-style3 .footer_style #sns_footer_top .container_in .bot-social .banner_home2 .module {
        padding: 5px 0
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .header-style3 #sns_slideshows3 .owl-controls .owl-dots {
        bottom: 15px!important
    }
    .header-style3 #sns_menu #sns_header_logo {
        margin-top: 10px;
        text-align: center
    }
    .header-style3 #sns_menu .sns_mainmenu {
        text-align: left!important
    }
    .header-style3 #sns_menu .sns_mainmenu #sns_mainnav {
        float: left!important
    }
    .header-style3 #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.leftsidebar {
        display: none;
        margin: 0
    }
    .header-style3 #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.leftsidebar i {
        display: none
    }
    .header-style3 #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.offcanvas i {
        color: #fff
    }
    .header-style3 #sns_menu .sns_mainmenu #sns_mainnav #menu_offcanvas {
        z-index: 99999
    }
    .header-style3 #sns_content {
        margin-top: 30px!important;
        z-index: 0
    }
    .header-style3 #sns_content .policy-page3 {
        margin-bottom: 25px
    }
    .header-style3 #sns_content .policy-page3 .ca-menu {
        padding-bottom: 10px!important
    }
    .header-style3 #sns_content .policy-page3 .ca-menu .rsbd-no {
        border-right: 0!important
    }
    .header-style3 #sns_content .policy-page3 .ca-menu li {
        margin-bottom: 20px
    }
    .header-style3 #sns_content #sns_producttaps1 {
        margin-bottom: 50px
    }
    .header-style3 #sns_content .sns-products-list {
        margin-bottom: 10px
    }
    .header-style3 #sns_content .sns-products-list .item-row {
        margin-bottom: 30px
    }
    .header-style3 #sns_content #header-slideshow {
        margin-bottom: 35px
    }
    .header-style3 #sns_content #header-slideshow .banner-right .banner8 {
        padding-left: 0;
        margin-top: 0
    }
    .header-style3 .footer_style #sns_footer_top .container_in .bot-social {
        margin-bottom: 0
    }
    .header-style3 .footer_style #sns_footer_top .container_in .bot-social .banner_home2 .module {
        padding: 5px 0
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .header-style3 .sns-products-list .products-small .item-row {
        margin-bottom: 30px
    }
    .header-style3 .sns-products-list .products-small .item-row:last-child {
        margin-bottom: 0
    }
    .header-style3 .sns-products-list .products-small .item-row .item {
        width: 100%
    }
    .header-style3 #header-slideshow {
        margin-bottom: 35px
    }
    .header-style3 #header-slideshow .banner-right .banner7 {
        padding-left: 0;
        padding-right: 15px;
        margin-top: 0
    }
    .header-style3 .footer_style.vesion2 #sns_footer_bottom,
    .header-style3 .footer_style.vesion2 #sns_footer_top {
        margin-bottom: 0
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .products-grid .item .item-inner .item-img:before {
        background: transparent none repeat scroll 0 0;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: inline-block
    }
    .products-grid .item .item-inner .action-bot {
        z-index: 3
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .header-style4 #content_after .policy-page3 {
        margin-bottom: 25px
    }
    .header-style4 #content_after .policy-page3 ul li {
        margin-bottom: 15px;
        border: 0!important
    }
    .header-style4 #sns_header {
        margin-bottom: 0
    }
    .header-style4 #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.leftsidebar {
        display: none!important
    }
    .header-style4 #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.offcanvas {
        padding-left: 10px
    }
    .header-style4 #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.offcanvas i {
        color: #fff
    }
    .header-style4 .footer_style #sns_footer_top .container_in .bot-social {
        margin-bottom: 0
    }
    .header-style4 .footer_style #sns_footer_top .container_in .bot-social .banner_home2 .module {
        padding: 5px 0
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .header-style4 #header-slideshow {
        margin-bottom: 35px
    }
    .header-style4 #header-slideshow .banner-right .banner8 {
        padding-left: 0;
        margin-top: 0
    }
    .header-style4 #sns_header_logo {
        margin-bottom: 0
    }
    .header-style4 #sns_header_logo #logo {
        margin: 10px 0 0
    }
    .header-style4 #sns_header {
        margin-bottom: 10px
    }
    .header-style4 #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.leftsidebar {
        display: none!important
    }
    .header-style4 #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.offcanvas {
        padding-left: 10px
    }
    .header-style4 #sns_menu .sns_mainmenu #sns_mainnav .btn2.btn-navbar.offcanvas i {
        color: #fff
    }
    .header-style4 #sns_catrgories .sns-category .category .featured-slider .item span {
        left: 7%
    }
    .header-style4 #content_after .policy-page3 {
        margin-bottom: 25px
    }
    .header-style4 #content_after .policy-page3 .ca-menu {
        padding-bottom: 10px!important
    }
    .header-style4 #content_after .policy-page3 .ca-menu .rsbd-no {
        border-right: 0!important
    }
    .header-style4 #content_after .policy-page3 .ca-menu li {
        margin-bottom: 20px
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .header-style4 #sns_header {
        margin-bottom: 10px
    }
    .header-style4 #header-slideshow {
        margin-bottom: 35px
    }
    .header-style4 #header-slideshow .banner-right .banner7 {
        padding-left: 0;
        padding-right: 10px;
        margin-top: 0
    }
    .header-style4 #sns_menu #sns_custommenu ul.mainnav .level0.custom-item.rsnone {
        display: none
    }
}
@media (min-width: 320px) and (max-width: 1199px) {
    .header-prd.sns-contact-us #sns_breadcrumbs,
    .header-prd.sns-products-detail1 #sns_breadcrumbs {
        padding-top: 18px!important
    }
    .header-prd.sns-contact-us #sns_menu .btn2.btn-navbar.leftsidebar {
        display: none!important;
        margin-right: 10px!important
    }
    .header-prd.sns-contact-us #sns_menu .btn2.btn-navbar.leftsidebar i {
        color: #fff;
        display: none!important
    }
}
.toolbar {
    z-index: 9;
    margin-bottom: 24px;
    color: #666;
    border: 0;
    padding: 0;
    position: relative;
    text-align: right
}
.toolbar .view-mode {
    float: left;
    margin-top: 10px!important
}
.toolbar .limiter,
.toolbar .sort-by {
    text-align: left;
    margin: 0;
    padding: 10px;
    color: #666
}
.toolbar .limiter label,
.toolbar .sort-by label {
    color: #666
}
.toolbar .pager {
    border-top: 1px solid #eaeaea;
    padding-top: 12px
}
.toolbar .pager .pages {
    position: relative;
    bottom: 5px
}
.toolbar .limiter {
    float: right;
    padding-right: 0
}
.toolbar .toolbar-inner>* {
    display: inline-block;
    vertical-align: middle;
    margin: 0
}
.toolbar .view-mode label {
    display: none
}
.toolbar .view-mode a,
.toolbar .view-mode strong {
    text-align: center;
    position: relative;
    color: #666;
    border: 1px solid #eaeaea;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px
}
.toolbar .view-mode a:before,
.toolbar .view-mode strong:before {
    font-size: 16px
}
.toolbar .view-mode a {
    cursor: pointer;
    margin-left: 1px
}
.toolbar .view-mode a:hover {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor);
    color: #666
}
.toolbar .view-mode a:hover:before {
    color: #fff
}
.toolbar .view-mode strong {
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)
}
.toolbar .view-mode strong:before {
    color: #fff
}
.toolbar .view-mode .icon-grid:before {
    content: '\f00a';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 30px;
    text-align: center;
    width: 30px;
    line-height: 30px
}
.toolbar .view-mode .icon-list:before {
    content: '\f03a';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 30px;
    text-align: center;
    width: 30px;
    line-height: 30px
}
.toolbar .limiter label {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 0;
    font-weight: 400
}
.toolbar .limiter>span {
    display: none
}
.toolbar .limiter .select-new {
    display: inline-block;
    vertical-align: middle
}
.toolbar .limiter .select-new .select-inner {
    cursor: pointer
}
.toolbar .limiter .select-new .select-inner select {
    opacity: 0;
    filter: alpha(opacity=0)
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper {
    position: relative;
    -webkit-transition: all .2 ease-in;
    transition: all .2 ease-in;
    height: 30px;
    border: 1px solid #eaeaea;
    z-index: 1!important;
    background: 0 0;
    width: 90px!important;
    color: #666
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper:hover {
    background: #fff;
    border: 1px solid #ddd
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper:hover ul {
    display: block!important;
    list-style: none;
    background: #fff;
    z-index: 99;
    padding: 0;
    position: relative;
    top: -2px
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper:hover ul li a {
    display: block
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper:hover ul li:active a {
    background: #CCC
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper div {
    -webkit-transition: all .25s ease;
    transition: all .25s ease
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper div span {
    line-height: 30px;
    height: 30px;
    padding-left: 10px;
    color: #666;
    font-size: 100%;
    width: auto!important
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper div a.jqTransformSelectOpen {
    right: 0;
    position: absolute;
    height: 100%;
    width: 30px;
    text-align: center;
    border-left: 1px solid #eaeaea
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper div a.jqTransformSelectOpen:before {
    content: '\f107';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 22px;
    text-align: center;
    width: 15px;
    line-height: 30px;
    font-size: 12px
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper ul {
    top: 100%;
    width: 90px!important;
    left: -1px!important;
    border: 1px solid #ddd;
    bolder-top: 0
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper ul a {
    padding: 5px 10px;
    background: #fff;
    border-bottom: 1px solid #ddd
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper ul a.selected,
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper ul a:hover {
    color: var(--secondaryColor)
}
.toolbar .limiter .select-new .select-inner .jqTransformSelectWrapper ul li:last-child a {
    border: 0
}
.toolbar .limiter .select-new .select-inner select {
    cursor: pointer;
    width: 50px;
    border: 0;
    background: 0 0
}
.toolbar .sort-by {
    padding-right: 10px!important;
    float: right
}
.toolbar .sort-by label {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 0;
    font-weight: 400
}
.toolbar .sort-by>span {
    display: none
}
.toolbar .sort-by .select-new {
    display: inline-block;
    vertical-align: middle
}
.toolbar .sort-by .select-new .select-inner {
    cursor: pointer
}
.toolbar .sort-by .select-new .select-inner select {
    opacity: 0;
    filter: alpha(opacity=0)
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper {
    position: relative;
    -webkit-transition: all .2 ease-in;
    transition: all .2 ease-in;
    height: 30px;
    border: 1px solid #eaeaea;
    z-index: 1!important;
    background: 0 0;
    color: #666
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper:hover {
    background: #fff;
    border: 1px solid #ddd
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper:hover ul {
    display: block!important;
    list-style: none;
    background: #fff;
    z-index: 99;
    padding: 0;
    position: relative;
    top: -2px
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper:hover ul li a {
    display: block
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper:hover ul li:active a {
    background: #CCC
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper div {
    -webkit-transition: all .25s ease;
    transition: all .25s ease
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper div span {
    line-height: 30px;
    height: 30px;
    padding-left: 10px;
    color: #666;
    font-size: 100%;
    width: auto!important
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper div a.jqTransformSelectOpen {
    right: 0;
    position: absolute;
    height: 100%;
    width: 30px;
    text-align: center;
    border-left: 1px solid #eaeaea
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper div a.jqTransformSelectOpen:before {
    content: '\f107';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 22px;
    text-align: center;
    width: 15px;
    line-height: 30px;
    font-size: 12px
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper ul {
    top: 100%;
    left: -1px!important;
    border: 1px solid #ddd;
    bolder-top: 0
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper ul a {
    padding: 5px 10px;
    background: #fff;
    border-bottom: 1px solid #ddd
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper ul a.selected,
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper ul a:hover {
    color: var(--secondaryColor)
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper ul li:last-child a {
    border: 0
}
.toolbar .sort-by .select-new .select-inner select {
    cursor: pointer;
    width: 50px;
    border: 0;
    background: 0 0
}
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper,
.toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper ul {
    width: 120px!important
}
.toolbar .sort-by .set-asc,
.toolbar .sort-by .set-desc {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    width: 15px;
    position: relative;
    background: 0 0;
    margin: 0;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    text-align: center;
    color: #707070
}
.toolbar .sort-by .set-asc:before,
.toolbar .sort-by .set-desc:before {
    content: '\f176';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    width: 15px;
    -webkit-transition: all .25s ease;
    transition: all .25s ease
}
.toolbar .sort-by .set-asc:hover:before,
.toolbar .sort-by .set-desc:hover:before {
    color: var(--secondaryColor)
}
.toolbar .sort-by .set-desc {
    background: 0 0;
    height: 30px;
    width: 10px;
    margin-right: 10px
}
.toolbar .sort-by .set-desc:before {
    content: '\f175';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 30px;
    text-align: center;
    line-height: 30px;
    width: 15px;
    font-size: 14px
}
.toolbar .pager {
    float: right;
    width: 100%;
    height: 30px
}
.toolbar .pager p.amount {
    float: left
}
.toolbar .pager p.amount span {
    display: none
}
.toolbar .pager .pages {
    float: right
}
.toolbar .pager .pages>strong {
    font-weight: 400;
    padding-right: 5px
}
.toolbar .pager .pages>ol {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    list-style: none
}
.toolbar .pager .pages>ol li {
    float: left;
    width: 24px;
    height: 24px;
    font-size: 12px
}
.toolbar .pager .pages>ol li.current {
    line-height: 24px;
    text-align: center;
    color: var(--secondaryColor)
}
.toolbar .pager .pages>ol li{
    display: block;
    border: 0;
    padding: 0;
    background: 0 0;
    border-radius: 0;
    color: #666;
    line-height: 24px;
    cursor:pointer;
}
.toolbar .pager .pages>ol li span.next,
.toolbar .pager .pages>ol li span.previous {
    border: 0;
    padding: 0;
    height: 22px;
    width: 24px;
    overflow: hidden
}
.toolbar .pager .pages>ol li span.next:before {
    content: '\f0da';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 12px
}
.toolbar .pager .pages>ol li span.previous:before {
    content: '\f0d9';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 12px
}
.toolbar .pager .pages>ol li:hover{
    color: var(--secondaryColor)
}
.toolbar-bottom {
    margin: 10px 0 0
}
.toolbar-bottom .toolbar {
    margin-bottom: 15px
}
.toolbar-bottom .limiter,
.toolbar-bottom .sort-by,
.toolbar-bottom .view-mode {
    display: none
}
.safari .toolbar .view-mode a {
    margin-left: 0!important
}
.selectBox-label {
    display: none
}
.sns-shopping-cart #sns_header {
    margin-bottom: 8px
}
.sns-shopping-cart #sns_content {
    margin-bottom: 47px!important
}
.shoppingcart .sptitle h3 {
    margin-top: 0;
    margin-bottom: 15px
}
.shoppingcart .sptitle h4.style {
    color: #666;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    padding: 14px 18px 12px;
    position: absolute;
    right: 15px;
    text-transform: uppercase;
    top: -25px;
    border: 1px solid #eaeaea;
    cursor: pointer;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}
.shoppingcart .sptitle h4.style:hover {
    color: #fff;
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)
}
.shoppingcart ul {
    list-style: none;
    padding: 0;
    margin: 0
}
.shoppingcart ul li {
    display: inline-block
}
.shoppingcart ul.title {
    position: relative;
    height: 40px;
    line-height: 40px;
    border: 1px solid #eaeaea
}
.shoppingcart ul.title a {
    font-size: 12px;
    color: #333;
    font-weight: 700;
    text-transform: uppercase
}
.shoppingcart ul.title .text1 {
    padding-left: 135px;
    text-align: left;
    width: 62%
}
.shoppingcart ul.title .text2 {
    margin: 0 40px
}
.shoppingcart ul.nav-mid {
    color: #666;
    border: 1px solid #eaeaea;
    border-top: 0
}
.shoppingcart ul.nav-mid li {
    height: 120px;
    line-height: 120px;
    border-right: 1px solid #eaeaea
}
.shoppingcart ul.nav-mid li:last-child {
    border-right: 0
}
.shoppingcart ul.nav-mid img {
    padding: 10px
}
.shoppingcart ul.nav-mid .item-title {
    padding-left: 16px;
    width: 47%
}
.shoppingcart ul.nav-mid .icon1 {
    padding: 0 9px
}
.shoppingcart ul.nav-mid .icon1 i {
    position: relative;
    top: -1px;
    color: #666;
    cursor: pointer
}
.shoppingcart ul.nav-mid .price1 {
    width: 14%;
    text-align: center
}
.shoppingcart ul.nav-mid .price2 {
    width: 10%;
    text-align: right;
    padding-right: 10px
}
.shoppingcart ul.nav-mid .number {
    text-align: center;
    width: 10%
}
.shoppingcart ul.nav-mid .number a {
    left: -2px;
    padding: 6px 47px;
    position: relative;
    text-align: center;
    border: 1px solid #eaeaea
}
.shoppingcart ul.nav-mid .icon2 {
    padding: 0 10px
}
.shoppingcart ul.nav-mid .icon2 i {
    position: relative;
    top: -1px;
    cursor: pointer
}
.shoppingcart .sptitle h3 {
    font-size: 20px;
    color: #333;
    font-weight: 700
}
.shoppingcart ul.nav-bot {
    height: 80px;
    line-height: 80px;
    border: 1px solid #eaeaea;
    border-top: 0
}
.shoppingcart ul.nav-bot .continue {
    margin-left: 20px
}
.shoppingcart ul.nav-bot .clear,
.shoppingcart ul.nav-bot .update {
    float: right;
    margin-right: 20px
}
.shoppingcart ul.nav-bot li a {
    border: 1px solid #eaeaea;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: #666;
    padding: 10px 17px 8px;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}
.shoppingcart ul.nav-bot li a:hover {
    color: #fff;
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)
}
.shoppingcart .form-right {
    text-align: right
}
.shoppingcart .form-right .form-bd {
    padding: 27px 20px 16px;
    border: 3px solid var(--secondaryColor)
}
.shoppingcart .form-right .subtotal {
    text-transform: uppercase
}
.shoppingcart .form-right .subtotal .text1 {
    font-size: 16px;
    color: #333;
    margin-right: 25px
}
.shoppingcart .form-right .subtotal .text2 {
    font-size: 16px;
    color: #333
}
.shoppingcart .form-right h3 {
    padding-bottom: 27px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 44px!important
}
.shoppingcart .form-right h3 .text3 {
    font-size: 20px;
    color: #333;
    margin-right: 40px
}
.shoppingcart .form-right h3 .text4 {
    font-size: 20px;
    color: var(--secondaryColor)
}
.shoppingcart .form-right .checkout {
    margin-top: 28px;
    text-transform: uppercase
}
.shoppingcart form .form-bd {
    padding: 27px 20px 38px;
    border: 1px solid #eaeaea;
    margin-top: 30px
}
.shoppingcart form .form-bd h3 {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    color: #333;
    text-transform: uppercase
}
.shoppingcart form .form-bd .text1 {
    margin-top: 15px
}
.shoppingcart form .form-bd p,
.shoppingcart form .form-bd span {
    color: #666;
    font-size: 12px
}
.shoppingcart form .form-bd .color1 {
    color: red;
    margin-right: 3px
}
.shoppingcart form .form-bd .country {
    margin-bottom: 8px
}
.shoppingcart form .form-bd .zip {
    margin-bottom: 6px
}
.shoppingcart form .form-bd select {
    width: 100%;
    height: 30px;
    border: 1px solid #eaeaea;
    margin-bottom: 17px
}
.shoppingcart form .form-bd select option {
    color: #666
}
.shoppingcart form .form-bd input {
    height: 30px;
    width: 100%;
    border: 1px solid #eaeaea
}
.shoppingcart form .form-bd .style23 {
    margin-bottom: 42px
}
.shoppingcart form .form-bd .style-bd {
    padding: 9px 20px 7px;
    border: 1px solid #eaeaea;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}
.shoppingcart form .form-bd .style-bd:hover {
    color: #fff;
    background: var(--secondaryColor);
    border: 1px solid var(--secondaryColor)
}
.shoppingcart form .form-bd .formbd2 {
    margin: 13px 0 15px
}
.shoppingcart form .form-bd .styleip {
    margin-bottom: 42px
}
.sns-404 #sns_header {
    margin-bottom: 7px
}
.sns-404 #sns_content {
    text-align: center
}
.sns-404 #sns_content .content {
    padding: 70px 330px 0
}
.sns-404 #sns_content .content h1 {
    font-size: 100px;
    font-weight: 700;
    color: var(--secondaryColor);
    text-transform: uppercase;
    margin-bottom: 0
}
.sns-404 #sns_content .content h2 {
    position: relative;
    bottom: 9px;
    font-size: 20px;
    font-weight: 700;
    color: #666;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 15px
}
.sns-404 #sns_content .content p {
    margin-bottom: 50px;
    padding-bottom: 54px;
    font-size: 12px;
    color: #666;
    position: relative
}
.sns-404 #sns_content .content p:before {
    background-image: url(../images/border.png);
    background-repeat: repeat-x;
    bottom: 0;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%
}
.sns-404 #sns_content .content #newsletter-validate .input-box {
    text-align: center
}
.sns-404 #sns_content .content #newsletter-validate .input-box .input_warp {
    position: relative;
    display: inline-block
}
.sns-404 #sns_content .content #newsletter-validate .input-box .input_warp input {
    width: 385px;
    height: 50px;
    border: 1px solid #eaeaea;
    padding-left: 10px
}
.sns-404 #sns_content .content #newsletter-validate .input-box .input_warp::-webkit-input-placeholder {
    color: #ccc
}
.sns-404 #sns_content .content #newsletter-validate .input-box .input_warp:-moz-placeholder {
    color: #ccc
}
.sns-404 #sns_content .content #newsletter-validate .input-box .input_warp::-moz-placeholder {
    color: #ccc
}
.sns-404 #sns_content .content #newsletter-validate .input-box .input_warp:-ms-input-placeholder {
    color: #ccc
}
.sns-404 #sns_content .content #newsletter-validate .input-box .input_warp button {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    border-color: none;
    height: 50px;
    width: 50px;
    background: #222
}
.sns-404 #sns_content .content #newsletter-validate .input-box .input_warp button i {
    font-size: 16px;
    color: #fff;
    position: relative;
    top: 0
}
.sns-checkout #sns_header {
    margin-bottom: 7px
}
#check-accordion {
    margin-bottom: 30px
}
#check-accordion h5 {
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #4d4d4d;
    font: 700 16px Arial sans-serif;
    height: 64px;
    line-height: 64px;
    //background: #f0f0f0 url(../images/bottom-errow.png) 98% center no-repeat
}
#check-accordion h5 a {
    color: #4d4d4d;
    display: block
}
#check-accordion h5 small {
    margin-right: 25px;
    font: 700 16px Arial sans-serif;
    //background: url(../images/accordian-step.png) no-repeat;
    float: left;
    display: block;
    width: 123px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    color: #fff
}
#check-accordion h5.ui-accordion-header-active {
    margin-bottom: 0;
    background: #6a6a6a url(../images/top-errow.png) 98% center no-repeat
}
#check-accordion h5.ui-accordion-header-active a {
    color: #fff
}
#check-accordion h5.ui-accordion-header-active small {
    //background: url(images/accordion-hover.png) no-repeat
}
.cheakout {
    width: 539px
}
#check-accordion>div {
    background: #f0f0f0;
    margin-bottom: 15px;
    padding: 25px 0;
    height: auto!important;
    border: 1px solid #dfdfdf;
    border-top: 0
}
#check-accordion div>div {
    background: #fff;
    border: 1px solid #dfdfdf;
    border-top: 0
}
#check-accordion h6 {
    height: 56px;
    line-height: 56px;
    border-bottom: 1px solid #dfdfdf;
    padding-left: 17px
}
#check-accordion h6 span {
    color: #9d9d9d;
    font: 12px Arial sans-serif
}
#check-accordion form {
    padding: 25px
}
#check-accordion form input[type=radio] {
    margin: -2px 15px 15px 0
}
#check-accordion form label {
    font: 14px Arial sana-serif;
    color: #000;
    vertical-align: middle;
    display: inline-block;
    margin-top: -17px;
    width: 150px
}
#check-accordion form p {
    font: 14px Arial sans-serif;
    color: #8a8a8a;
    line-height: 22px;
    margin-bottom: 17px
}
#check-accordion form input[type=submit] {
    -webkit-transition: .5s;
    transition: .5s;
    background: #f71919;
    padding: 10px 15px;
    border: 0;
    color: #fff;
    font: 700 14px Arial sans-serif
}
#check-accordion form input[type=submit]:hover {
    background: #cc2828
}
#check-accordion .register form a {
    text-align: right;
    font: 13px arial sans-serif;
    display: block;
    text-decoration: underline;
    margin-bottom: 15px
}
#check-accordion .register input[type=submit] {
    background: #4d4d4d;
    float: right
}
#check-accordion .register input[type=submit]:hover {
    background: #262626
}
#check-accordion .register label {
    margin-top: -23px
}
#check-accordion input[type=text] {
    width: 213px;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    margin-bottom: 22px
}
.billing-form {
    padding: 0!important;
    width: 1125px;
    margin-left: 20px;
    background: 0 0;
    border: 0
}
.billing-form label {
    display: block!important;
    margin-bottom: 10px!important;
    margin-top: 0!important
}
.billing-form fieldset {
    width: 49.3%;
    float: left;
    margin-right: 15px
}
.billing-form fieldset.last {
    margin-right: 0
}
.billing-form fieldset.last input[type=text] {
    width: 97%!important
}
.billing-form fieldset input[type=text] {
    width: 97%!important;
    border: 1px solid #ddd
}
.billing-form input[type=text] {
    width: 98.5%!important;
    margin-bottom: 19px!important;
    height: 26px!important
}
.billing-form fieldset .selectBox.selectBox-dropdown {
    width: 100%!important;
    margin-bottom: 19px!important;
    height: 34px!important
}
.billing-form fieldset .selectBox-dropdown .selectBox-arrow {
    background: url(../images/big-errow.png) no-repeat!important;
    width: 38px!important
}
.billing-form input[type=checkbox] {
    margin: -1px 10px 0 0;
    vertical-align: middle
}
.billing-form p {
    display: inline-block;
    font-size: 12px!important;
    color: #000!important;
    margin: 0!important
}
.billing-form .red-button {
    background: #f71919;
    padding: 15px 15px!important;
    float: right;
    font-weight: 700;
    margin-bottom: 0!important;
    margin-right: 0!important
}
.billing-form .red-button:hover {
    background: #cc2828
}
.billing-form .selectBox-dropdown .selectBox-label {
    padding: 10px 26px 10px 11px!important
}
#check-accordion .billing {
    width: 1125px;
    margin-left: 20px;
    background: 0 0;
    border: 0
}
#check-accordion .billing p {
    margin-bottom: 18px;
    font: 13px Arial sans-serif;
    color: #000
}
.billing .title {
    background: #f71919
}
.billing .title li {
    color: #fff
}
.billing>ul {
    background: #fff;
    border-bottom: 1px solid #ddd
}
.billing>ul li {
    display: inline-block;
    width: 21.7%;
    font: 13px Arial sans-serif;
    line-height: 39px;
    color: #787878;
    margin-left: 10px
}
.billing>ul li.last {
    width: 7%
}
#check-accordion .billing .totle {
    margin-top: 22px;
    width: 346px;
    float: right;
    background: none!important;
    border: 0
}
.billing .totle ul {
    background: #fff;
    width: 100%
}
.billing .totle ul li span {
    color: #787878;
    float: right
}
.billing .totle ul li {
    padding: 0 10px;
    display: block;
    font: 700 15px Arial sans-serif;
    color: #4d4d4d;
    line-height: 39px;
    border-bottom: 1px solid #ddd
}
.billing .totle .red-button {
    background: #f71919;
    padding: 15px;
    float: right;
    font-weight: 700;
    margin-right: 0!important
}
.billing .totle .red-button:hover {
    background: #cc2828
}
#check-accordion .payment {
    width: 1125px;
    margin-left: 20px;
    background: none!important;
    border: 0
}
.payment p {
    margin-bottom: 18px;
    font: 13px Arial sans-serif;
    color: #000
}
.payment .radio-btn {
    background: #fff;
    height: 32px;
    line-height: 32px;
    border: 1px solid #ddd;
    margin-bottom: 15px
}
.payment .radio-btn input[type=radio] {
    margin: -.9px 10px 0 10px!important;
    vertical-align: middle
}
.payment .radio-btn label {
    margin-top: 0!important;
    width: 70%!important
}
.payment form .radio-btn span {
    line-height: 32px
}
.payment form {
    padding: 0!important
}
.payment form span {
    float: right;
    font: 13px Arial sans-serif;
    color: #787878;
    margin-right: 13px
}
.payment form>label {
    font-weight: 700!important;
    margin-top: 0!important;
    width: 100%!important;
    margin-bottom: 11px
}
.payment form textarea {
    width: 98%;
    resize: none;
    font: 13px Arial sans-serif;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    padding: 10px;
    height: 241px;
    border: 1px solid #ddd
}
.payment form .red-button {
    background: #f71919;
    padding: 15px 15px!important;
    float: right;
    font-weight: 700;
    margin-bottom: 0!important;
    margin-right: 0!important
}
.payment form .red-button:hover {
    background: #cc2828
}
.product-image-gallery {
    position: relative
}
.product-image-gallery .gallery-image {
    display: none
}
.product-image-gallery .gallery-image.visible {
    display: block
}
.product-image-gallery .gallery-image.visible.hidden {
    visibility: hidden
}
label,
span.label {
    font-size: 100%;
    font-weight: 400
}
label em,
p.required {
    color: #fe6d4c
}
.tooltip .in {
    opacity: 1;
    filter: alpha(opacity=100)
}
.tooltip .tooltip-inner {
    background: var(--secondaryColor);
    border-radius: 3px;
    color: #fff;
    border: 1px solid var(--secondaryColor)
}
.tooltip.top .tooltip-arrow {
    border-top-color: var(--secondaryColor)
}
.tooltip.right .tooltip-arrow {
    border-right-color: var(--secondaryColor)
}
.mrb30 {
    margin-bottom: 30px
}
.mrb40 {
    margin-bottom: 40px
}
.mrb45 {
    margin-bottom: 45px
}
.mrb50 {
    margin-bottom: 50px
}
.mrb10 {
    margin-bottom: 10px
}
.wrap {
    clear: both
}
.wrap:after,
.wrap:before {
    content: " ";
    display: table
}
.wrap:after {
    clear: both
}
.uneditable-input,
input,
textarea {
    width: 206px
}
input[type=button],
input[type=checkbox],
input[type=file],
input[type=image],
input[type=radio],
input[type=reset],
input[type=submit] {
    width: auto
}
[class*=col-]:hover {
    z-index: 1
}
[data-icon] {
    font-style: normal
}
#sns_sticky_left,
#sns_sticky_right {
    display: none
}
.sticky {
    background: #FFF;
    padding: 0;
    z-index: 998;
    border: 1px solid #cecece;
    border-right: 0;
    position: fixed;
    right: 0;
    top: 100px;
    border-radius: 0 0 0 4px;
    -webkit-box-shadow: 0 3px #cecece;
    box-shadow: 0 3px #cecece;
    border-bottom: 0
}
.sticky.style-show-content {
    width: 270px
}
.sticky.style-show-title {
    height: 50px;
    line-height: 50px;
    border-radius: 0
}
.sticky.style-show-title .sticky-content {
    padding: 0 20px;
    display: block
}
.sticky .sticky-button {
    position: absolute;
    top: -1px;
    left: -50px;
    height: 50px;
    width: 50px;
    font-size: 20px;
    cursor: pointer;
    line-height: 46px;
    background: #f5f5f5;
    text-align: center;
    border: 1px solid #cecece;
    border-bottom: 0;
    -webkit-box-shadow: 0 3px #cecece;
    box-shadow: 0 3px #cecece;
    border-radius: 4px 0 0 4px
}
.sticky .sticky-button span {
    display: none
}
.sticky .sticky-button i {
    text-align: center;
    vertical-align: middle
}
body.ie8 .sticky .sticky-button {
    border-bottom: 3px solid #cecece
}
.sticky .sticky-content {
    padding: 12px 20px
}
.sticky .sticky-content .block .block-title {
    padding: 0 0 10px;
    border-bottom: 1px solid #DDD
}
.sticky .sticky-content .block .block-content {
    padding: 10px 0 5px
}
.sticky .sticky-content .block.block-facebook .btn-like {
    top: 0;
    right: 0
}
.sticky .sticky-content .block.block-facebook .fanpage-list .item>.item-face {
    background: #efefef;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}
.sticky .sticky-content .block.block-facebook .fanpage-list .item>.item-face:hover {
    background: var(--secondaryColor)
}
#sns_sticky_left .sticky {
    border: 1px solid #cecece;
    border-left: 0;
    right: auto;
    left: 0;
    border-radius: 0 0 4px
}
body.ie8 #sns_sticky_left .sticky {
    border-bottom: 3px solid #cecece
}
#sns_sticky_left .sticky .sticky-button {
    right: -50px;
    left: auto;
    border: 1px solid #cecece;
    border-bottom: 0;
    border-radius: 0 4px 4px 0
}
body.ie8 #sns_sticky_left .sticky .sticky-button {
    border-bottom: 3px solid #cecece
}
#sns-totop {
    background: #aaa;
    border-radius: 100%;
    width: 45px;
    bottom: 5px;
    right: 5px;
    height: 45px;
    text-decoration: none;
    overflow: hidden;
    z-index: 40;
    position: fixed;
    display: block;
    text-align: center;
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}
#sns-totop.hidden-top {
    bottom: -100px
}
#sns-totop i {
    color: #FFF
}
i {
    font-size: 24px;
    color: #333;
    position: relative;
    top: 8px
}
#facebook_fan iframe[style] {
    width: 100%!important
}
.stickynote-top {
    background: #2d3538;
    color: #CCC;
    left: 0;
    top: -100%;
    z-index: 99;
    position: fixed;
    visibility: hidden
}
.stickynote-top .container {
    position: relative
}
.stickynote-top .container .block {
    padding: 25px 80px 25px 0
}
.stickynote-top .container .btn-close {
    position: absolute;
    height: 32px;
    width: 32px;
    right: 0;
    top: 100%;
    margin-top: 5px;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    text-indent: -999em;
    //background: url(../images/btn-close.png) no-repeat left top;
    cursor: pointer;
    -webkit-transform: scale(0.5) rotate(45deg);
    -ms-transform: scale(0.5) rotate(45deg);
    transform: scale(0.5) rotate(45deg)
}
.stickynote-top.loaded {
    visibility: visible
}
.block-latestblog .owl-item>*,
.sns-slider .owl-item>* {
    opacity: .3;
    filter: alpha(opacity=30);
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all .8s ease;
    transition: all .8s ease;
    -webkit-backface-visibility: hidden
}
.block-latestblog .owl-item.active>*,
.sns-slider .owl-item.active>* {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}
.tabdrop,
.tabdrop .dropdown-toggle {
    margin: 0
}
.tabdrop .dropdown-toggle * {
    display: none
}
.blog-toolbar .pager {
    margin: 0
}
.blog-toolbar .toolbar {
    margin: 30px 0 0
}
.blog-toolbar .toolbar .limiter {
    display: none
}
.blog-toolbar .toolbar .sort-by .select-new .select-inner select {
    width: 70px
}
.blog-toolbar .toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper,
.blog-toolbar .toolbar .sort-by .select-new .select-inner .jqTransformSelectWrapper ul {
    width: 110px!important
}
.blog-toolbar.post .toolbar {
    margin: 10px 0 20px
}
.price .text-box input[type=button] {
    background: #666;
    margin-left: 5px
}
.product-name {
    font-size: 100%
}
.product-name a {
    color: inherit
}
.search-form ul {
    list-style: none
}
.page-head {
    color: #333
}
.page-head h2,
.page-head h3,
.page-head h4,
.page-head h5 {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 10px
}
.page-title.title-buttons {
    margin-bottom: 20px
}
.page-title h1 {
    font-size: 160%;
    margin: 0 0 25px;
    text-transform: uppercase
}
.sub-title {
    font-size: 110%
}
.highlight,
a.readmore {
    color: var(--secondaryColor)
}
.height20 {
    margin: 0 0 20px;
    padding: 0;
    border: 0
}
.visible-phone {
    display: none
}
@media (max-width: 991px) {
    .height20 {
        margin-bottom: 30px
    }
}
@media (min-width: 992px) {
    .row20 {
        margin: 0 -10px
    }
    .row20 [class^=col-] {
        padding: 0 10px
    }
}
@media (max-width: 479px) {
    .visible-phone {
        display: block
    }
}
@media (min-width: 992px) {
    .col-md-2d4 {
        float: left;
        width: 20%
    }
}
.no-display {
    display: none
}
#fancybox-close,
.btn-remove {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle
}
#fancybox-close:before,
.btn-remove:before {
    content: '\f00d';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: #666
}
#fancybox-close:hover,
.btn-remove:hover {
    border-color: var(--secondaryColor)
}
#fancybox-close:hover:before,
.btn-remove:hover:before {
    color: var(--secondaryColor)
}
.btn-edit {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle
}
.btn-edit:before {
    content: '\f044';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: #666
}
.btn-edit:hover {
    border-color: var(--secondaryColor)
}
.btn-edit:hover:before {
    color: var(--secondaryColor)
}
#fancybox-close {
    top: 10px;
    right: 10px
}
#fancybox-wrap #fancybox-outer {
    border: 2px solid #686868;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, .3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, .3)
}
#fancybox-wrap .fancybox-bg {
    background: 0 0
}
#fancybox-overlay {
    background: #000!important;
    opacity: 20!important;
    filter: alpha(opacity=2000)!important
}
body .jqTransformSelectWrapper {
    height: 22px;
    background: #E2E2E2
}
body .jqTransformSelectWrapper div span {
    padding: 0;
    line-height: 22px;
    height: 22px
}
body .jqTransformSelectWrapper div a.jqTransformSelectOpen {
    height: 21px;
    width: 9px;
    background: 0 0
}
body .jqTransformSelectWrapper ul {
    top: 22px
}
body .jqTransformSelectWrapper ul a {
    padding: 0 5px
}
body .jqTransformSelectWrapper ul a.selected:hover,
body .jqTransformSelectWrapper ul a:hover {
    background: #999
}
body .jqTransformSelectWrapper .jqTransformHidden {
    display: none
}
body .jqTransformRadioWrapper {
    margin-top: 0
}
body .jqTransformRadioWrapper .jqTransformHidden {
    display: none
}
body .jqtransformdone {
    line-height: 20px
}
.banner-item {
    position: relative;
    display: block;
    text-align: center
}
.banner-item:before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-transition: all .2s ease-in 0s;
    transition: all .2s ease-in 0s;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    filter: alpha(opacity=0)
}
.banner-item:hover:before {
    opacity: 100;
    filter: alpha(opacity=10000)
}
.banner {
    position: relative;
    overflow: hidden;
    display: block;
    z-index: 0
}
.banner img {
    width: 100%;
    -webkit-transition: all .2s linear;
    transition: all .2s linear
}
.banner:before {
    position: absolute;
    content: '';
    display: block;
    left: 20px;
    top: 20px;
    right: 20px;
    bottom: 20px;
    -webkit-transition: all .35s ease-out;
    transition: all .35s ease-out;
    border: 3px solid #fff;
    z-index: 1
}
.banner:hover:before {
    border: 10px dashed rgba(255, 255, 255, 0);
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px
}
.banner:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05)
}
.banner2 {
    position: relative;
    overflow: hidden;
    display: block;
    z-index: 0
}
.banner2 img {
    width: 100%;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05)
}
.banner2:before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    border: 0 solid rgba(255, 255, 255, 0);
    z-index: 1
}
.banner2:hover:before {
    border: 10px solid rgba(255, 255, 255, .30000000000000004)
}
.banner2:hover img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}
.banner2.black:before {
    border: 0 solid rgba(0, 0, 0, 0)
}
.banner2.black:hover:before {
    border: 10px solid rgba(0, 0, 0, .09999999999999998)
}
.banner4 {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative
}
.banner4:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    -webkit-transition: all .2s ease-out .3s;
    transition: all .2s ease-out .3s
}
.banner4:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    right: 0;
    -webkit-transition: all .2s ease-out .3s;
    transition: all .2s ease-out .3s
}
.banner4:hover:before {
    right: 50%;
    background-color: #fff;
    opacity: .5;
    z-index: 1
}
.banner4:hover:after {
    left: 50%;
    background-color: #fff;
    opacity: .5
}
.banner4.black:hover:after,
.banner4.black:hover:before {
    background-color: #000;
    opacity: .1
}
.banner4 img {
    -webkit-transition: all .2s linear 0s;
    transition: all .2s linear 0s;
    width: 100%
}
.banner4:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05)
}
.banner5 {
    display: block;
    position: relative;
    overflow: hidden;
}
.banner5:after,
.banner5:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out
}
.banner5:hover:before {
    right: 50%;
    left: 50%;
    background: rgba(255, 255, 255, .2)
}
.banner5:hover:after {
    top: 50%;
    bottom: 50%;
    background: rgba(255, 255, 255, .2)
}
.banner5.black:hover:before {
    right: 50%;
    left: 50%;
    background: rgba(0, 0, 0, .1)
}
.banner5.black:hover:after {
    top: 50%;
    bottom: 50%;
    background: rgba(0, 0, 0, .1)
}
.sns-custom-tabs .custom-tab-head:after,
.sns-custom-tabs .custom-tab-head:before {
    content: " ";
    display: table
}
.sns-custom-tabs .custom-tab-head:after {
    clear: both
}
.sns-custom-tabs .custom-tab-head h3 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px
}
.sns-custom-tabs .custom-tab-head .nav-tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 0
}
.sns-custom-tabs .custom-tab-head .nav-tabs li {
    font-size: 100%;
    color: #aaa;
    text-transform: uppercase;
    margin: 5px 0
}
.sns-custom-tabs .custom-tab-head .nav-tabs li+li {
    margin-left: 10px
}
.sns-custom-tabs .custom-tab-head .nav-tabs li:hover a {
    color: var(--secondaryColor)
}
.sns-custom-tabs .custom-tab-head .nav-tabs li.active a {
    color: #fff;
    background: var(--secondaryColor)
}
.sns-custom-tabs .custom-tab-head .nav-tabs li.active a:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: var(--secondaryColor) transparent transparent;
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px
}
.sns-custom-tabs .custom-tab-head .nav-tabs li a {
    border: 0;
    color: #666;
    background: #f1f1f1;
    padding: 2px 8px;
    display: block;
    border-radius: 0;
    position: relative
}
.sns-custom-tabs .tab-content {
    margin-bottom: 15px
}
.sns-custom-tabs .tab-content .block-title-slider {
    position: static;
    margin: 0;
    padding: 0;
    height: 0
}
.sns-custom-tabs .tab-content .block-title-slider h3 {
    display: none
}
.sns-custom-tabs .tab-content .block-title-slider .navslider {
    position: static;
    margin: 0
}
.sns-custom-tabs .tab-content .block-title-slider .navslider a {
    position: absolute;
    bottom: 50%;
    z-index: 1
}
.sns-custom-tabs .tab-content .block-title-slider .navslider a.next {
    right: -18px;
    left: auto
}
.sns-custom-tabs .tab-content .block-title-slider .navslider a.prev {
    right: auto;
    left: -18px
}
.sns-custom-tabs .tab-content .block-title-slider .navslider a i {
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 34px;
    background: #fff;
    color: #ccc;
    border: 3px solid #ddd
}
.products-deals {
    display: none
}
#fancybox-loading {
    position: fixed;
    left: 50%;
    padding: 15px 0 10px;
    width: 180px;
    background: #000;
    margin: -15px 0 0 -90px;
    text-align: center;
    box-shadow: 0 0 5px #000;
    -webkit-box-shadow: 0 0 5px #000;
    -o-box-shadow: 0 0 5px #000;
    opacity: .7;
    filter: alpha(opacity=70)
}
#fancybox-loading:before {
    content: '';
    display: block;
    height: 30px;
    //background: url(../images/camera-loader.gif) no-repeat center center
}
#fancybox-loading div {
    margin-top: 8px;
    color: #FFF;
    background: 0 0;
    width: 100%;
    height: auto
}
#fancybox-loading div:before {
    content: 'Please wait...';
    text-align: center
}
.fa-ul li {
    padding-top: 0
}
.fa-ul li i {
    margin-top: 3px
}
.fright {
    right: auto!important;
    left: 100%!important
}
.head-policy {
    line-height: 1.3
}
.head-policy strong {
    color: #666
}
.head-policy [class*=col-]:hover .fa {
    color: var(--secondaryColor)
}
.banner-slider {
    display: none
}
.banner-slider .owl-controls .owl-dots {
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    text-align: center;
    height: 0
}
.banner-slider .owl-controls .owl-dots .owl-page {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin: 0 5px
}
.banner-slider .owl-controls .owl-dots .owl-page.active span:before,
.banner-slider .owl-controls .owl-dots .owl-page:hover span:before {
    border: 1px solid #fff
}
.banner-slider .owl-controls .owl-dots .owl-page span {
    display: block;
    width: 10px;
    height: 10px;
    background: #f2f2f2;
    position: relative
}
.banner-slider .owl-controls .owl-dots .owl-page span:before {
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    content: ''
}
@keyframes iconmap {
    0% {
        -webkit-transform: translate(-2px, -2px);
        -ms-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px)
    }
    100% {
        -webkit-transform: translate(3px, -3px);
        -ms-transform: translate(3px, -3px);
        transform: translate(3px, -3px)
    }
}
@-webkit-keyframes iconmap {
    0% {
        -webkit-transform: translate(-2px, 2px)
    }
    100% {
        -webkit-transform: translate(3px, -3px)
    }
}
.col-lg-2d4,
.col-md-2d4,
.col-sm-2d4,
.col-xs-2d4 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}
.col-xs-2d4 {
    float: left;
    width: 20%
}
.col-xs-pull-2d4 {
    right: 20%
}
.col-xs-push-2d4 {
    left: 20%
}
.col-xs-offset-2d4 {
    margin-left: 20%
}
@media (min-width: 768px) {
    .col-sm-2d4 {
        float: left;
        width: 20%
    }
    .col-sm-pull-2d4 {
        right: 20%
    }
    .col-sm-push-2d4 {
        left: 20%
    }
    .col-sm-offset-2d4 {
        margin-left: 20%
    }
}
@media (min-width: 992px) {
    .col-md-2d4 {
        float: left;
        width: 20%
    }
    .col-md-pull-2d4 {
        right: 20%
    }
    .col-md-push-2d4 {
        left: 20%
    }
    .col-md-offset-2d4 {
        margin-left: 20%
    }
}
@media (min-width: 1200px) {
    .col-lg-2d4 {
        float: left;
        width: 20%
    }
    .col-lg-pull-2d4 {
        right: 20%
    }
    .col-lg-push-2d4 {
        left: 20%
    }
    .col-lg-offset-2d4 {
        margin-left: 20%
    }
}
.advantages{
    margin: 100px 0 170px;
}
.advantages h3{
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #333333;
    text-transform: uppercase;
    margin-bottom: 56px;
}
.advantages-block-title{
    font-size: 20px;
    color:var(--mainColor);
    span{
        font-weight: 400;
        font-size: 72px;
    }
}
.advantages-wrapper{
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    text-align: center;
    &:not(:last-child){
        margin-right: 20px;
    }
}
.advantages-flex{
    display: flex;
    justify-content: space-between;
}
.advantages .text{
    line-height: 20px;
}
@media(max-width: 1024px){
    .advantages-flex{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px 0;
    }
}
@media(max-width: 767px){
    .advantages-flex{
        grid-template-columns: 1fr;
    }
    .advantages-wrapper:not(:last-child){
        margin-right: 0;
    }
}
.point-blocks{
    padding: 60px 0;
    h3{
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        color: #000000;
        text-transform: uppercase;
        margin-bottom: 50px;
    }
    .block-title{
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        display: flex;
        gap: 13px;
        align-items: center;
        span{
            font-size: 72px;
            line-height: 72px;
            color: var(--mainColor);
        }
    }

    .row{
        .col-md-4{
            margin-bottom: 50px;
        }
    }
    .col-main{
        padding: 0 50px;
    }
}
@media(max-width:767px){
    .point-blocks{
        .row{
            .col-md-4{
                margin-bottom: 20px;
            }
        }
    }
}
.mr-b-50{
    margin-bottom: 50px;
}
.banner-category{
    position: relative;
    margin-bottom: 60px;
    &-container{
        position: absolute;
        background: rgba(189, 134, 69, 0.9);
        padding: 48px 49px;
        color: #FFFFFF;
        font-size: 16px;
        line-height: 24px;
        bottom: 0;
        width: 50%;
        left: 0;
        h1{
            font-weight: 700;
            font-size: 36px;
            line-height: 109.5%;
            margin: 0 0 8px 0;
        }
    }

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
.product-shop{
    .product-item{
        .row{
            padding: 0 18px;
        }
        h5{
            margin: 0 0 4px;
            font-weight: 700;
            font-size: 12px;
            line-height: 13px;
        }
        p{
            font-size: 12px;
            line-height: 20px;
            color: #666666;
        }
    }

    .desc{
        display: flex;
        gap: 20px;
        padding: 15px 0 !important;
        .black{
            font-weight: 700;
            font-size: 14px;
            color: #FFFFFF;
            background: #222222;
            outline: none;
            height: 49px;
            padding: 0 25px;
            text-transform: uppercase;
            border: none;
            transition: .2s;
            cursor: pointer;

            &:hover{
                background: #e36049;
            }
        }
        .outline{
            background: #FFFFFF;
            border: 1px solid #EAEAEA;
            outline: none;
            height: 49px;
            padding: 0 25px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            color: #333333;
            transition: .2s;
            cursor: pointer;

            &:hover{
                color: #e36049;
            }
        }
    }
}
.header-logo{
    .red{
        height: 40px;
        background: var(--mainColor);
        padding: 0 23px;
        outline: none;
        border: none;
        font-weight: 700;
        font-size: 13px;
        color: #FFFFFF;
        text-transform: uppercase;
        transition: all .2s linear;
        cursor: pointer;

        &:hover{
            background-color: rgba(0, 0, 0, 0.85);
        }
    }
    .icon-header{
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 16px;
        color: #000000;
        line-height: 20px;
    }
    .header-phone{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 12px;
        line-height: 16px;
        color: #474747;
        
    }
    .header-flex{
        display: flex;
        justify-content: flex-end;
        gap: 26px;
        align-items: center;
        height: 100%;
    }
}
.modal-form{
    display: flex;
    flex-direction: column;
    gap: 19px;

    input{
        border: none;
        border-bottom: 1px solid #32333687;
        height: 44px;
        outline: none;
        width: 100%;
        font-size: 14px;
    }
}
.modal-success{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    transition: .3s;
    transform: translateX(100%);
    i{
        font-size: 60px;
        color: var(--mainColor);
    }
    h4{
        font-weight: 700;
        font-size: 20px;
        color: #333333;
    }
}
.modal-success-visible{
    transform: translateX(0);
}

.modal-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    text-transform: uppercase;
}

.modal-black-button{
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    height: 49px;
    border: none;
    outline: none;
    padding: 0 25px;
    background: #222222;
    text-transform: uppercase;
}
.modal-footer{
    .text{
        font-size: 14px;
        color: #323336;
        margin-right: 19px;
    }
}
.modal-dialog{
    max-width: 730px;
}
.modal-content{
    border-radius: 0;
    padding: 30px;
    overflow-x: hidden;
}
@media (max-width: 920px){
    .banner-category-container h3{
        font-size: 22px;
    }
    .banner-category-container{
        padding: 20px 25px;
        width: 57%;
        .text{
            font-size: 14px;
        }
    }
}
@media (max-width: 768px){
    .banner-category-container{
        position: initial;
        width: 100%;
    }
}
@media (max-width: 1024px){
    #sns_content .sns_banner .flex4{
        grid-template-columns: 1fr;
        gap: 20px;
        max-width: 400px;
        margin: 0 auto;
    }
    #sns_content .sns_banner{
        padding: 32px 20px;
    }
    #sns_content .sns_banner .style-title{
        font-size: 26px;
    }
    #sns_content .sns_banner .style-text5{
        font-size: 16px;
        line-height: 100%;
    }
    .advantages{
        margin: 50px 0 70px;
    }
}

.banner-video{
    position: relative;
    transition: .2s;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;

    &:hover {
        .span-active-none{
            opacity: 1;
        }
    }

    .video-text{
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        text-align: center;
        position: absolute;
        bottom: 30px;
        div{
            font-size: 26px;
            line-height: 35px;
        }
    }
    .video-text-none{
        opacity: 0;
        visibility: hidden;
    }
    .span-active{
        position: absolute;
        width: 68px;
        height: 68px;
        font-size: 38px;
        border-radius: 50%;
        border: 2px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        //opacity: 0;
        transition: .3s;
        pointer-events:none;

        i{
            position: relative;
            top: auto;
            color: #fff;
        }
        .fa-play{
            left: 5px;
        }
    }
    .span-active-none{
        opacity: 0;
    }
    video, iframe, img{
        height: 257px;
        width: 100%;
        border: 2px solid #fff;
        object-fit: cover;
    }

    .span-muted{
        position: absolute;
        bottom: 10px;
        right: 10px;
        
        i{
            font-size: 24px;
            color: #fff;
            top: auto;
        }
    }
}
.error-text{
    color: red;
    text-align: start;
}

@media (max-width: 991px){
    .main-offer{
        grid-template-columns: 1fr;
        .grid{
            display: none;
        }
        .item-title{
            font-size: 24px;
        }
        .item-text{
            bottom: 32px;
        }
    }
    .banner-video video, .banner-video iframe{
        height: auto;
    }
    .banner-video .video-text{
        font-size: 14px;
        bottom: 2px;
    }
    .banner-video .video-text div{
        font-size: 20px;
    }
    .advantages-block-title span{
        font-size: 48px;
    }
    .header-logo .red{
        font-size: 11px;
    }
}
.column4{
    overflow: hidden;
}
.footer-modal{
    position: absolute;
    top: 0;
    background-color: #F8F8F6;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(150%);
    transition: .3s;
    opacity: 0;

    i{
        color: var(--mainColor);
        font-size: 30px;
    }

    h4{
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
    }
}

.footer-modal-visible{
    opacity: 1;
    transform: translateX(0);
}
.modal-new .modal-dialog{
    max-width: 60vw;

    video, iframe{
        width: 100%;
    }
}   
.modal-new {
    .modal-body{
        padding: 0;
    }
    .modal-content{
        padding: 0;
        background: transparent;
        border: none;
        align-items: center;
    }
    .fa-close{
        font-size: 24px;
        position: absolute;
        color: #fff;
        right:10px;
        top: 10px;
        z-index: 999;
        cursor: pointer;

        &:hover{
            color: red;
        }
    }
}
@media (min-width: 768px){
    .modal-new .modal-dialog{
        max-width: 80vw;
    } 
}

@media (max-width: 767px){
    .banner-category{
        margin-bottom: 40px;
    }
    .modal-new {
        .modal-content{
            padding: 0;
        }
        .modal-dialog{
            max-width: 100vw;
        }
    } 
    .mob-menu-icon{
        text-align: end;
        i{
            text-align: end;
            font-size: 22px;
            color: #fff;

            &:hover{
                color: red;
            }
        }
    }
    .header-phone .text{
        display: none;
    }
}
.main-offer-slider {
    .item{
        img{
            min-height: 300px;
            object-fit: cover;
        }
    }
}

.header-socials{
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 12px;
    li i{
        color: #888;
        font-size: 16px;
        top: 4px;

        &:hover{
            color: var(--mainColor);
        }
    }
}

.footer-socials{
    margin-top: 20px;
    display: flex;
    align-items: center;
    span{
        font-size: 14px;
        margin-right: 10px;
    }

    .header-socials li i{
        top: 0;
    }
}

.wrap_dropdown{
    .col-sm-3:not(:last-child){
        margin-right: 10px;
    }
}